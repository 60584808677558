import React, { } from "react";

import { withRouter } from 'react-router-dom';

const errorPage = (props) => {
    const { history } = props;

    const irHome = () => {
        history.push("/");
    }
    return (
        <section className="error-area">
            <div className="d-table">
                <div className="d-table-cell">
                    <div className="container">
                        <div className="error-content">
                            <div className="notfound-404">
                                <h1>Oops!</h1>
                            </div>
                            <h3>404 - No se encontró la pagina</h3>
                            <p>
                                La página que está buscando podría haberse eliminado, cambiado su nombre o no está disponible temporalmente.</p>
                            <button type="button" onClick={()=>{irHome()}} className="btn btn-primary">Ir a inicio</button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default withRouter(errorPage);