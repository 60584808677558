import React, { useState } from "react";
import {
  ListaEndpoints,
  ERROR_REQUERIDO,
  REGEX_VALIDACION_EMAIL,
  ERROR_REGEX_EMAIL,
  ERROR_REGEX_TEL, MODAL_STYLES,ESTADOAPP, PRODUCCION
} from "../../config/Config";
import { useForm } from "react-hook-form";
import { useEffect } from "react";
import { PostWithToken, SweetAlert, existeToken, GetWithToken } from "../../services/ControlActions";
import ErrorForm from "../Formularios/ErrorForm";
import { getItem } from '../../services/UseLocalStorage';
import { Link, useHistory } from "react-router-dom";
import Spinner from "./Spinner";
import Modal from "react-modal";
import InfoTerminosYCondiciones from '../Formularios/InfoTerminosYCondiciones';
import InfoPrivacidad from '../Formularios/InfoPrivacidad';
import { UseCarritoState } from "../../hooks/UseCarritoState";
import { UseSignalR } from "../../hooks/UseSignalR";

const SeleccionPagoBN = () => {
  //#region states
  let history = useHistory();
  const { register, handleSubmit, errors } = useForm({ defaultValues: { tokenList: "" } });
  const [spinner, showSpinner, hideSpinner] = Spinner();
  //carrito
  const { carritoData } = UseCarritoState();
  const carrito = carritoData;
  //signalR
  const { conexionId, InicializaConexionSignalR } = UseSignalR();
  //usuario 
  const [muestraPagina] = useState(existeToken());
  const [usuario, setUsuario] = useState(null);
  const [cargando, setCargando] = useState(true)
  // state que guarda el callback del widget, no es utilizado si no se utiliza el widget
  //#region terminos y widget pago
  const [enviandoPago, setEnviandoPago] = useState(false);
  const [showModelTerminos, setShowModelTerminos] = useState(false);
  const [clickTerminos, setClickTerminos] = useState(false);//se usaba pero por una modificacion del banco se dejó de lado
  const [checkboxTerminos, setCheckboxTerminos] = useState(false);
  const [dataWidget, setDataWidget] = useState({});
  const [objetoUsuario, setObjetoUsuario] = useState({
    nombre: "",
    apellido: "",
    telefono: "",
    correo: "",
    idPedido: 0,
    userId: ""
  });
  const [errorObj, setErrorObj] = useState({ mostar: false, mensaje: "" });
  //#endregion
  MODAL_STYLES.content.maxWidth = "70%"
  //#endregion
  //#region signalR
  const ValidarConexion = () => {
    if (conexionId == null || conexionId === "") {
      if (InicializaConexionSignalR != null) {
        InicializaConexionSignalR();
      }
    }
  }
  useEffect(() => {   //valida la conexion con signalR
    if (muestraPagina) {
      ValidarConexion();
    }
  }, [conexionId, muestraPagina])

  //#endregion
  const getUsuario = () => {
    showSpinner();
    GetWithToken(ListaEndpoints.USUARIO, "GetUsuario").then(
      (res) => {
        //  console.log("datos", res);
        if (res.ok) {
          let respUsuario = res.data;
          if (respUsuario == null) {
            SweetAlert(3, "Acción interrumpida ", "" + res.msg, "", "Entendido", () => { history.push("/"); });
            // console.log("Ha ocurrido un error al mostrar el usuario");      
          } else {
            setUsuario(respUsuario);
          }
        } else {
          SweetAlert(3, "Acción Interrumpida", "" + res.msg, "", "Entendido", () => { history.push("/"); });
          //  console.log("Ha ocurrido un error al traer el usuario." + res.error, res.errorDescription);
        }
      }
    ).catch((error) => {
      console.error("Ha ocurrido un error al traer el usuario.", error);
    }).finally(() => { hideSpinner(); setCargando(false); });
  }
  // UseEffect inicial
  useEffect(() => {
    if (muestraPagina) {
      if (cargando && usuario == null) {
        getUsuario();
      }
      if (cargando === false && usuario != null) {//existe token
        let nombres = usuario.Nombre.split(" ");
        let nom = "", ape = "";
        let primero = true;
        for (let index = 0; index < nombres.length; index++) {
          if (primero) {
            nom += nombres[index];
            primero = false;
          } else {
            ape += nombres[index] + " ";
          }
        }
        setObjetoUsuario({
          nombre: nom,
          apellido: ape,
          telefono: usuario.Telefono,
          correo: usuario.Email,
          idPedido: 0,
          userId: "",
          userName: "",
        });
      }
    }
  }, [usuario, cargando, muestraPagina]);
  const onSubmit = async (data) => {
    //console.log("submit", data);
    //PARTE 1 DE WIDGET  datos variantes del usuario 

    calcularMonto().then((monto) => {
      if (monto != null && monto > 0) {
        procederPago(data, monto)
      }
    });


  };
  const procederPago = (data, monto) => {
    showSpinner();
    //Aceptar terminos Enviar ultimo registro
    let user = getItem('usuario');
    setEnviandoPago(true);
    if (user != null) {
      PostWithToken(ListaEndpoints.CARRITO, "AceptarTerminosCondiciones", "", carrito).then(
        (res) => {
          // setEnviado(true);
          //   console.log("res de temirnos y condiciones", res)
          if (res.data) {
            if (res.ok) {
              //MostrarWidget
              PostWithToken(ListaEndpoints.PAGO, "CargarWidget", "", monto).then(
                (res) => {
                  // setEnviado(true);
                  //   console.log("res de widget", res)
                  if (res.data) {
                    if (res.ok) {
                      //PARTE 2 DE WIDGET keys y datos fijos
                      setDataWidget(res.data);

                      //generar pedido
                      PostWithToken(ListaEndpoints.ACCION, "GenerarPedido", "", user).then(
                        (res) => {
                          //    console.log("res generar pedido", res)
                          if (res.ok && res.data != null) {
                            //PARTE 3 DE WIDGET  datos variantes del usuario logeado y del pedido
                            //console.log("data act", objetoUsuario);
                            setObjetoUsuario({
                              nombre: data.nombre,
                              apellido: data.apellido,
                              telefono: data.telefono,
                              correo: data.correo,
                              idPedido: res.data.id,
                              userId: user.userId,
                              userName: user.cta_id,// gmail
                            });

                            let form1 = document.getElementById("f1");

                            //  console.log("test form", form1)
                            form1.submit();


                          } else {
                            setErrorObj({ mostar: true, mensaje: res.error });
                          }
                        },
                        (error) => {
                          setErrorObj({ mostar: true, mensaje: error.message });
                        }
                      ); //fin generar pedido

                    } else {
                      SweetAlert(2, "Acción concluida", ` ${res.error !== '' || res.error != null ? res.error : res.msg}`,
                        "", "Entendido", () => { });
                    }
                  } else {//       
                    SweetAlert(3, "No se completó la acción",
                      ` ${res.msg ? res.msg : "No se pudo procesar la compra, inténtelo de nuevo más tarde o consulte a su proveedor"}.`,
                      "", "Entendido", () => { });
                  }
                  hideSpinner();
                },
                (error) => {
                  hideSpinner();
                  SweetAlert(
                    3,
                    "Acción Interrumpida",
                    ` ${error.message}`,
                    "Inténtelo de nuevo más tarde",
                    "Entendido",
                    () => { }
                  );
                  //   console.log("Ha ocurrido un error.", error);
                }
              ); // fin de MostrarWidget
            } else {
              SweetAlert(2, "Acción concluida", ` ${res.error !== '' || res.error != null ? res.error : res.msg}`,
                "", "Entendido", () => { });
            }
          } else {//       
            SweetAlert(3, "No se completó la acción",
              ` ${res.msg ? res.msg : "No se pudo procesar la compra, inténtelo de nuevo más tarde o consulte a su proveedor"}.`,
              "", "Entendido", () => { });
          }
          hideSpinner();
        },
        (error) => {
          hideSpinner();
          SweetAlert(
            3,
            "Acción Interrumpida",
            ` ${error.message}`,
            "Inténtelo de nuevo más tarde",
            "Entendido",
            () => { }
          );
          //   console.log("Ha ocurrido un error.", error);
        }
      );//fin  de terminos y condiciones
    } else {
      getUsuario();
    }
  }
  const calcularMonto = async () => {

    // console.log("dato carrito", carrito)
    let monto = null;
    if (carrito != null) {
      await PostWithToken(ListaEndpoints.ACCION, "CalcularMonto", "", carrito).then(
        (res) => {
          //console.log("resCalcular monto", res)
          if (res.ok && res.data != null) {

            monto = res.data.total;
          } else {
            setErrorObj({ mostar: true, mensaje: res.error });
          }
        }).catch((error) => {
          setErrorObj({ mostar: true, mensaje: error.message });
        })

    } else {
      setErrorObj({ mostar: true, mensaje: "No se logró obtener el carrito, no se puede continuar." });
    }
    return monto;
  }
  const mostrarTerminos = () => {
    setShowModelTerminos(true);
    setClickTerminos(true);

  }


  return (
    <>
      {spinner}
      {muestraPagina && cargando != null && usuario ? (
        <>
          {/*"id de conexion:" + conexionId*/}
          <div className="order-details">
            <h3 className="title">Información de formalización</h3>
            {/*false*/usuario.Identificacion.length > 8 && usuario.Direccion !== "" ? (
              <>
                <form onSubmit={handleSubmit(onSubmit)}>
                  {/**Nombre */}
                  <div className="form-group col-12">
                    <label>Nombre:</label>
                    <input
                      type="text"
                      className={
                        "form-control " +
                        (errors.nombre ? "input-error" : null)
                      }
                      name="nombre"
                      placeholder="Nombre"
                      defaultValue={objetoUsuario.nombre}
                      ref={register({ required: ERROR_REQUERIDO })}
                    />

                    {errors.nombre ? (
                      <ErrorForm mensaje={errors.nombre.message} />
                    ) : null}
                  </div>
                  {/**Apellido */}
                  <div className="form-group col-12">
                    <label>Apellidos:</label>
                    <input
                      type="text"
                      className={
                        "form-control " +
                        (errors.apellido ? "input-error" : null)
                      }
                      name="apellido"
                      placeholder="Apellidos"
                      defaultValue={objetoUsuario.apellido}
                      ref={register({ required: ERROR_REQUERIDO })}
                    />

                    {errors.apellido ? (
                      <ErrorForm mensaje={errors.apellido.message} />
                    ) : null}
                  </div>
                  {/**Correo */}
                  <div className="form-group col-12">
                    <label>Correo electrónico:</label>
                    <input
                      type="text"
                      className={
                        "form-control " +
                        (errors.correo ? "input-error" : null)
                      }
                      name="correo"
                      placeholder="test@gmail.com"
                      defaultValue={objetoUsuario.correo}
                      ref={register({
                        required: ERROR_REQUERIDO,
                        pattern: {
                          value: REGEX_VALIDACION_EMAIL,
                          message: ERROR_REGEX_EMAIL,
                        },
                      })}
                    />

                    {errors.correo ? (
                      <ErrorForm mensaje={errors.correo.message} />
                    ) : null}
                  </div>
                  {/**Telefono */}
                  <div className="form-group col-12">
                    <label>Teléfono:</label>
                    <input
                      type="text"
                      className={
                        "form-control " +
                        (errors.telefono ? "input-error" : null)
                      }
                      name="telefono"
                      placeholder="506 0000 8888"
                      defaultValue={objetoUsuario.telefono}
                      ref={register({
                        required: ERROR_REQUERIDO, pattern: {
                          value: /^[0-9]+$/i,
                          message: ERROR_REGEX_TEL,
                        },
                        maxLength: {
                          value: 20,
                          message: "Teléfono invalido",
                        }
                      })}
                    />

                    {errors.telefono ? (
                      <ErrorForm mensaje={errors.telefono.message} />
                    ) : null}
                  </div>
                  {/**detalle 
              <div className="form-group col-12 pl-0">
                <label>Detalle: </label>
                <p className="">
                  En caso de ser un distribuidor, por favor utilice este campo para ingresar el correo electrónico de la cuenta que se va a activar.
                  Utilice este campo para dar detalles sobre su compra.
                </p>
                <textarea
                  className={
                    "form-control " +
                    (errors.detalle ? "input-error" : null)
                  }
                  name="detalle"
                  placeholder="Detalle del pedido"
                  ref={null/*register({ required: "Campo requerido." })}
                  rows={3}
                  maxLength={500}
                />
                {errors.detalle ? (
                  <ErrorForm mensaje={errors.detalle.message} />
                ) : null}
              </div>*/}
                  {/**Terminos y cóndiciones */}
                  <div style={{ height: '300px', overflowY: 'scroll' }}>
                    <InfoTerminosYCondiciones></InfoTerminosYCondiciones>
                    <InfoPrivacidad></InfoPrivacidad>
                  </div>
                  <br />

                  <div className=" form-group col-12 ml-4">
                    {/** disabled={!clickTerminos} */}
                    <input
                      type="checkbox"
                      className="form-check-input puntero "
                      id="terminos"
                      name="terminos"
                      value={checkboxTerminos}

                      onChange={() => setCheckboxTerminos(!checkboxTerminos)}
                    />

                    {/*checkboxTerminos?"true": "false"}{enviandoPago?"true": "false"*/}
                    <label className="form-check-label puntero" htmlFor="terminos">
                      <span>
                        <a
                          onClick={() => mostrarTerminos(true)}
                        >
                          {`Acepto los términos y condiciones`}
                        </a>
                      </span>
                    </label>
                  </div>
                  {/*Submit*/}

                  <button
                    type="submit"
                    disabled={!checkboxTerminos || enviandoPago}
                    className="btn btn-primary order-btn  ml-3"

                  >
                    Realizar pago
                  </button>
                  {errorObj.mostar ? (
                    <ErrorForm mensaje={errorObj.mensaje} />
                  ) : null}
                  {errors.nombre || errors.apellido || errors.correo || errors.telefono ? (
                    <ErrorForm mensaje={"Porfavor verifique los campos"} />
                  ) : null}
                  {/*Mostrar widget
              <button
                type="button"
                className="btn btn-primary order-btn"
                onClick={() => cargarWidget(100)}
              >
                mostrar widget
              </button>*/}
                </form>
              </>
            ) : (
              <div className="alert alert-info text-center p-2 animated fadeIn faster mb-3">
                <p>
                  Porfavor actualice su Información personal en su perfil antes de continuar.


                </p>
                <span className="irPerfil">
                  <Link to="/perfil">{"Click para ir al Perfil "} </Link>
                </span>
              </div>
            )}
            <hr />
          </div>
          {/*Info widget BN*/}
          {/*  test "javascript: AlignetVPOS2.openModal('https://integracion.alignetsac.com/')"  */}
          {/*  prod  "javascript: AlignetVPOS2.openModal('',1)"  */}
          <div className="payment-method">
            <form name="f1" id="f1" action={ESTADOAPP == PRODUCCION ?
              "javascript: AlignetVPOS2.openModal('',1)" : "javascript: AlignetVPOS2.openModal('https://integracion.alignetsac.com/')"}
              method="post" className="alignet-form-vpos2">
              <input type='hidden' id="acquirerId" name="acquirerId" value={dataWidget != {} ? dataWidget.AcquirerID : ""} />
              <input type='hidden' id="idCommerce" name="idCommerce" value={dataWidget != {} ? dataWidget.CommerceID : ""} />
              <input type='hidden' id="purchaseOperationNumber" name="purchaseOperationNumber" value={dataWidget != {} ? dataWidget.NumeroOperacion : ""} />
              <input type='hidden' id="purchaseAmount" name="purchaseAmount" value={dataWidget != {} ? dataWidget.MontoVPos : ""} />
              <input type='hidden' id="purchaseCurrencyCode" name="purchaseCurrencyCode" value={dataWidget != {} ? dataWidget.Moneda : ""} />
              <input type='hidden' id="language" name="language" value="SP" />
              <input type='hidden' id="shippingFirstName" name="shippingFirstName" value={objetoUsuario.nombre} />{/*nombre pedirlos*/}
              <input type='hidden' id="shippingLastName" name="shippingLastName" value={objetoUsuario.apellido} />{/*apelido pedirlos*/}
              <input type='hidden' id="shippingEmail" name="shippingEmail" value={objetoUsuario.correo} />{/*correo pedirlos*/}
              <input type='hidden' id="shippingAddress" name="shippingAddress" value="Costa Rica" />
              <input type='hidden' id="shippingZIP" name="shippingZIP" value="00000" />
              <input type='hidden' id="shippingCity" name="shippingCity" value="Costa Rica" />
              <input type='hidden' id="shippingState" name="shippingState" value="Costa Rica" />
              <input type='hidden' id="shippingCountry" name="shippingCountry" value="CR" />
              <input type='hidden' id="shippingPhone" name="shippingPhone" value={objetoUsuario.telefono} />{/*numero pedirlos*/}
              <input type='hidden' id="descriptionProducts" name="descriptionProducts" value="Plan Facturación Electrónica" />
              <input type='hidden' id="programmingLanguage" name="programmingLanguage" value="VB .NET" />
              <input type='hidden' id="purchaseVerification" name="purchaseVerification" value={dataWidget !== {} ? dataWidget.PurchaseVerification : ""} />
              <input type='hidden' id="reserved6" name="reserved6" value={objetoUsuario.idPedido} />{/*Pedido id*/}
              <input type='hidden' id="reserved7" name="reserved7" value={objetoUsuario.userId} />{/*user id*/}
              <input type='hidden' id="reserved8" name="reserved8" value={objetoUsuario.userName} />{/*nombre de usuario*/}
              <input type='hidden' id="reserved9" name="reserved9" value={conexionId != null ? conexionId : ""} />{/*signalR id*/}
              <input type='hidden' id="reserved10" name="reserved10" value="" />{/*Sin uso*/}
              <input type='hidden' id="reserved11" name="reserved11" value="" />{/*Sin uso*/}
              <input type='hidden' id="reserved12" name="reserved12" value="" />{/*Sin uso*/}
              <input type='hidden' id="reserved13" name="reserved13" value="" />{/*Sin uso*/}
              <input type='hidden' id="billingAddress" name="billingAddress" value="" />
              <input type='hidden' id="billingEmail" name="billingEmail" value="" />
              {/*  <button
                    type="submit"
                    onClick="javascript: AlignetVPOS2.openModal('https://integracion.alignetsac.com/')"
                    onClick={()=>cargarWidget(100)}
                    className="btn btn-primary order-btn"
                    value={"Comprar"}
                  >
                    mostrar
                   </button>*/}
            </form>
          </div>
          <Modal
            isOpen={showModelTerminos}
            shouldCloseOnOverlayClick={true}
            onRequestClose={() => setShowModelTerminos(false)}
            style={MODAL_STYLES}
            className="modal-dialog"
          >
            <div className="modal-content">
              <div className="modal-header">
                <div className="modal-title">{ }</div>
                <button
                  type="button"
                  onClick={() => setShowModelTerminos(false)}
                  className="close"
                >
                  &times;
                </button>
              </div>
              <div className="modal-body">

                <InfoTerminosYCondiciones></InfoTerminosYCondiciones>
                <InfoPrivacidad></InfoPrivacidad>

                <div className="modal-footer justify-content-between">
                  <button
                    type="button"
                    onClick={() => setShowModelTerminos(false)}
                    className="btn btn-primary"
                  >
                    Aceptar
                  </button>
                </div>
              </div>
            </div>
          </Modal>
        </>) : <div className="billing-details"> {cargando ? <h3>Cargando...</h3> : <h3>No se puede mostrar esta vista, No hay Token</h3>}</div>}
    </>
  );
};

export default SeleccionPagoBN;
