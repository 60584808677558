import React from "react";
import NavBar from "./NavBar";
import Footer from "./Footer";
import "../../css/particles.css";
//import Particles from "react-particles-js";
//import json from "./particles.json";

const Layout = ({ datosServicio, cargandoServicios, children }) => {

  return (
    <div >
      <div id={"particles-js"}>
        {/*<Particles
          params={json} />*/}
      </div>
      <NavBar datos={datosServicio} cargando={cargandoServicios} />
      <div className="contenedor-min">{children}</div>
      <Footer />
    </div>
  );
};

export default Layout;
