import React, { useState, useEffect } from 'react';
import EncabezadoDescripcion from "../components/Visuales/EncabezadoDescripcion";
import { withRouter } from 'react-router-dom';
import { getItem } from '../services/UseLocalStorage';
import { ListaEndpoints, MODAL_STYLES, MODENA, SIGNO_MONEDA } from '../config/Config';
import { GetWithToken, SweetAlert, SweetAlert2, PostWithToken, SwalToast, UpdateWithToken, DeleteWithToken } from '../services/ControlActions';
import Spinner from '../components/Visuales/Spinner';
import $ from "jquery";
import 'datatables.net';
import 'datatables.net-bs4';
import AlertMessage from "../components/Errors/AlertMessage";
import Modal from "react-modal";
/*formulario */
import { Trash2, Edit } from "react-feather";
import { useForm } from "react-hook-form";
import ErrorForm from "../components/Formularios/ErrorForm.js";
import { img_default_cliente } from "../images/imagesURL.js";
import FormatMoney from '../services/FormatMoney';
import BtnBack from '../components/Formularios/BtnBack';

const place = "productos";
const AdmServicios = (props) => {
    const { history, match } = props;
    const ID = match.params.id
    const [objeto, setObjeto] = useState({
        id: 0,
        tipoObjeto: "",
        idObjeto: "",
        nombre: "",
        descripcion: "",
        descripcionCorta: "",
        precio: 0.0,
        urlImagen: "",
        idImagen: "",
        descuento: 0.0,
        estado: 0,
        idPadre: ID,
        fileImg: null,
        typeImg: null,
    });
    const [modalAddEdit, setModalAddEdit] = useState(false);
    const [edit, setEdit] = useState(false);
    const [titulo, setTitulo] = useState("Acción");
    const [spinner, showSpinner, hideSpinner] = Spinner();
    const [table, setTable] = useState(true);
    const [imagen, setImagen] = useState({ url: img_default_cliente, change: false });
    const [mensajeAlerta, setMensajeAlerta] = useState({ mensaje: "", error: false });

    //formulario
    const { register, handleSubmit, errors, reset } = useForm();
    const [mensajeError, setMensajeError] = useState({
        error: false,
        mensaje: "",
    });
    const [enviado, setEnviado] = useState(false);
    //#region datatables
    const TblID = "tblProductosAdm";
    const [list, setList] = useState(null);
    const [datatable, setDatatable] = useState(null)
    useEffect(() => {
        initData();
    }, []);
    const startDatatable = () => {
        //console.log("start")
        setDatatable($("#" + TblID).DataTable({
            scrollX: true,
            processing: true,
            destroy: true,
            language: {
                url: "//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json"
            },
            "ordering": true,
            "info": true,
            "colReorder": true,
            //   "searching": false,
            "bLengthChange": false,
            dom: 'Blfrtip',
            buttons: [
                {
                    extend: 'collection',
                    text: 'Exportar <i class="fas fa-list-ol"></i>',
                    className: "botonesSecundarios",
                    buttons: [
                        {
                            extend: 'csv',
                            text: 'CSV',
                            footer: true,
                            title: 'Listado de Facturas',
                            filename: 'Listado_Facturas_csv',
                            exportOptions: {
                                columns: ':visible'
                            }
                        },
                        {
                            extend: 'pdfHtml5',
                            text: 'PDF',
                            footer: true,
                            title: 'Listado de Facturas',
                            filename: 'Listado_Facturas_pdf',
                            exportOptions: {
                                columns: ':visible'
                            }
                        },
                        {
                            extend: 'excel',
                            text: 'Excel',
                            footer: true,
                            title: 'Listado de Facturas',
                            filename: 'Listado_Facturas_excel',
                            exportOptions: {
                                columns: ':visible'
                            }
                        },
                        {
                            extend: 'copy',
                            title: 'Listado de Facturas',
                            text: 'Copiar',
                            exportOptions: {
                                columns: ':visible'
                            }
                        },
                        {
                            extend: 'print',
                            title: 'Listado de Facturas',
                            text: 'Imprimir',
                            exportOptions: {
                                columns: ':visible'
                            }

                        }
                    ],
                }
            ],
            dropup: true,
            "order": [[0, "desc"]]
        }));
    }
    const destroyDatatable = () => {

        if (datatable != null) {
            // console.log("destroy");
            datatable.destroy();
            setDatatable(null)
        }
    }
    const recargarLista = async () => {
        destroyDatatable();
        listar().then((res) => {
            //console.log("res", res)
            if (res) {
                startDatatable();
            }
        })
    }
    const initData = () => {//solo se deberia ejecutar una vez
        if (getItem('usuario')) {
            if (getItem('usuario').uTipo != "tp") {
                history.goBack() ? history.goBack() : history.push("/")
            } else {
                try {
                    recargarLista();
                } catch (error) {
                    alert("Acción Interrumpida:", error.message);
                }
            }
        } else {
            history.goBack() ? history.goBack() : history.push("/")
        }
    }
    //#endregion

    const listar = async () => {
        showSpinner();
        let ok = false;
        await GetWithToken(ListaEndpoints.PRODUCTO, "GetProductos", ID).then((res) => {
            //console.log("res", res.data)
            if (res.data) {
                setList(res.data);
                ok = true;
            } else {
                if (res.Message) {
                    let err = res.Message;
                    SweetAlert(3, "Acción Interrumpida", err, "", "Entendido",
                        () => { history.goBack() ? history.goBack() : history.push("/") })
                    setList(null);
                } else {
                    let err = place + ":" + res.error;
                    SweetAlert(3, "Acción Interrumpida", err, "", "Entendido",
                        () => { history.goBack() ? history.goBack() : history.push("/") })
                    setList(null);
                }
            }
        }).catch((e) => {
            let err = place + ": No pudo conectar al servidor, inténtelo de nuevo más tarde. " + e.message
            setMensajeAlerta({ mensaje: err, error: true })
            setTimeout(() => {
                localStorage.clear("usuario");
                localStorage.clear("carrito");
                history.push("/login");
            }, 3000);
            setList(null);

        }).finally(() => {
            hideSpinner();
            // console.log("end")
        });
        return ok;

    }
    const GetColor = (i) => {
        switch (i) {
            case 0:
                return "rgb(226, 114, 114)";

            case 1:
                return "rgb(114, 162, 226)";

            case 2:
                return "rgb(118, 226, 114)";

            default:
                return "ffffff";
        }
    }
    const agregar = async (data) => {
        let bool = true;
        var promise = new Promise(getBuffer(data.fileImg[0]));
        await promise.then(function (d) {
            data.typeImg = data.fileImg[0].type;
            data.fileImg = d.toString();
        }).catch(function (err) {
            console.error(err.message, err);
            bool = false;
            let e = place + ": " + err.message;
            SweetAlert(3, "Acción Interrumpida", e, "", "Entendido",
                () => { })
        });
        if (bool) {
            showSpinner();
            setEnviado(false);

            let datos = {
                id: 0,
                tipoObjeto: "servicio",//max 10
                idObjeto: "",//max 10
                nombre: data.nombre,
                descripcion: data.descripcion,
                descripcionCorta: data.descripcionCorta,
                precio: data.precio,
                urlImagen: "",
                idImagen: "",
                descuento: data.descuento,
                estado: 1,
                idPadre: ID,
                fileImg: data.fileImg,
                typeImg: data.typeImg,
            };
            await PostWithToken(ListaEndpoints.PRODUCTO, "PostProducto", "", datos).then((res) => {
                if (res.data) {

                    reset();
                    SwalToast(2, res.msg, () => { }, 1000);
                    setMensajeError({ error: false, mensaje: "" });
                    setModalAddEdit(false)
                } else {
                    if (res.error) {
                        setMensajeError({ error: true, mensaje: res.error });
                        console.error(res.error, res.errorDescription)
                    }
                    if (res.Message) {
                        setMensajeError({ error: true, mensaje: res.Message });
                        console.error(res.error, res.Message)
                    }
                }

            }).catch((e) => {
                setMensajeError({ error: true, mensaje: e.message });
                console.error("Acción Interrumpida:", e.message)
            }).finally(() => {
                hideSpinner();
                recargarLista();
                setEnviado(true);
            });;
        }
    }
    const modificar = async (data) => {
        let bool = true;
        if (data.fileImg != null) {
            if (data.fileImg && data.fileImg.length) {
                var promise = new Promise(getBuffer(data.fileImg[0]));
                await promise.then(function (d) {
                    data.typeImg = data.fileImg[0].type;
                    data.fileImg = d.toString();

                }).catch(function (err) {
                    console.error(err.message, err);
                    bool = false;
                    let e = place + ": " + err.message;
                    SweetAlert(3, "Acción Interrumpida", e, "", "Entendido",
                        () => { })
                });
            }
        }

        if (bool) {
            showSpinner();
            setEnviado(false);

            //setObjeto(data);
            let datos = {
                id: edit ? objeto.id : data.id,
                tipoObjeto: objeto.tipoObjeto,
                idObjeto: data.idObjeto,
                nombre: data.nombre,
                descripcion: data.descripcion,
                descripcionCorta: data.descripcionCorta,
                precio: data.precio,
                urlImagen: objeto.urlImagen,
                idImagen: edit ? objeto.idImagen : data.idImagen,
                descuento: data.descuento,
                idPadre: ID,
                estado: edit ? objeto.estado : data.estado,
                //clases parciales
                fileImg: data.fileImg,
                typeImg: data.typeImg,

            };

            await UpdateWithToken(ListaEndpoints.PRODUCTO, "PutProducto", "", datos).then((res) => {
                if (res.data) {

                    reset();
                    SwalToast(2, res.msg, () => { }, 1000);
                    setMensajeError({ error: false, mensaje: "" });
                    setModalAddEdit(false);
                    setEdit(false);
                } else {
                    if (res.error) {
                        setMensajeError({ error: true, mensaje: res.error });
                        console.error(res.error, res.errorDescription)
                    }
                    if (res.Message) {
                        setMensajeError({ error: true, mensaje: res.Message });
                        console.error(res.error, res.Message)
                    }
                }

            }).catch((e) => {
                setMensajeError({ error: true, mensaje: e.message });
                console.error("Acción Interrumpida:", e);

            }).finally(() => {
                hideSpinner();
                recargarLista();
                setEnviado(true);
            });
        }
    }
    const eliminar = async (data) => {
        showSpinner();
        /*setEnviado(false);
        setCargando(true);*/
        await DeleteWithToken(ListaEndpoints.PRODUCTO, "DeleteProducto", "", data).then((res) => {
            if (res.data) {
                reset();
                SwalToast(2, res.msg, () => { }, 1000);
                setMensajeError({ error: false, mensaje: "" });
            } else {
                if (res.error) {
                    let err = place + ": " + res.error;
                    SweetAlert(3, "Acción Interrumpida", err, "", "Entendido",
                        () => { })
                    console.error(res.error, res.errorDescription)
                }
                if (res.Message) {
                    let err = place + ": " + res.Message;
                    SweetAlert(3, "Acción Interrumpida", err, "", "Entendido",
                        () => { })
                    console.error(res.Message)
                }
            }
        }).catch((e) => {
            let err = place + ": " + e.message;
            SweetAlert(3, "Acción Interrumpida", err, "", "Entendido",
                () => { })
            console.error("Acción Interrumpida:", e);

        }).finally(() => {
            hideSpinner();
            recargarLista();
            setEnviado(true);
        });

    }
    const showModalAdd = () => {
        setModalAddEdit(true)
        setEdit(false);
        setTitulo("Agregar Producto");
        setEnviado(false);
        let datos = {
            id: 0,
            tipoObjeto: "",
            idObjeto: "",
            nombre: "",
            descripcion: "",
            descripcionCorta: "",
            precio: 0.0,
            urlImagen: "",
            idImagen: "",
            descuento: 0.0,
            estado: 0,
            idPadre: ID,
            fileImg: null,
            typeImg: null,
        };
        let img = { url: img_default_cliente, change: false };
        setImagen(img);

        setObjeto(datos);
    };
    const showModalEdit = (data) => {
        setModalAddEdit(true)
        setEdit(true);
        setTitulo("Editar producto");
        setEnviado(false);
        let datos = {
            id: data.id,
            tipoObjeto: data.tipoObjeto,
            idObjeto: data.idObjeto,
            nombre: data.nombre,
            descripcion: data.descripcion,
            descripcionCorta: data.descripcionCorta,
            precio: data.precio,
            urlImagen: data.urlImagen,
            idImagen: data.idImagen,
            descuento: data.descuento,
            estado: data.estado,
            idPadre: ID,
            fileImg: null,
            typeImg: null,
        };
        let img = { url: data.urlImagen, change: false }
        setImagen(img);
        setObjeto(datos);
    };
    const showModalDelete = (data) => {
        let msg = `¿Está seguro que desea eliminar el producto: ${data.nombre}?`;
        SweetAlert2(1, "Eliminar producto", msg, "Este cambio no se puede revertir.",
            "Cancelar", () => { },
            "Aceptar", () => { eliminar(data) })
    };
    const showModalEditEstado = async (data) => {
        let datos = {
            id: data.id,
            tipoObjeto: data.tipoObjeto,
            idObjeto: data.idObjeto,
            nombre: data.nombre,
            descripcion: data.descripcion,
            descripcionCorta: data.descripcionCorta,
            precio: data.precio,
            urlImagen: data.urlImagen,
            idImagen: data.idImagen,
            descuento: data.descuento,
            estado: data.estado === 1 ? 0 : 1,
            idPadre: ID,
            fileImg: null,
            typeImg: null,
        };
        await setObjeto(datos);
        let msg = `¿Está seguro que desea ${data.estado === 1 ? "desactivar" : "activar"} el producto: ${data.nombre}?`;
        SweetAlert2(1, "Cambiar estado", msg, `El producto ${data.estado === 1 ? "NO" : ""} se mostrará en la página de inicio.`,
            "Cancelar", () => { },
            "Aceptar", () => { modificar(datos) })
    };
    const getBuffer = (fileData) => {
        return function (resolve) {
            var reader = new FileReader();
            reader.readAsDataURL(fileData);
            reader.onload = function () {
                var arrayBuffer = reader.result
                //console.log("array", fileData)
                arrayBuffer = arrayBuffer.replace("data:image/jpeg;base64,", "");
                arrayBuffer = arrayBuffer.replace("data:image/png;base64,", "");
                arrayBuffer = arrayBuffer.replace("data:image/jpg;base64,", "");
                //var bytes = new Uint8Array(arrayBuffer);
                resolve(arrayBuffer);
            }
        }
    }
    const onChangeInputImagen = () => {
        const input = document.getElementById("InputImg");
        let files = input.files;
        if (!files || !files.length) {
            let img = { url: edit ? objeto.urlImagen : img_default_cliente, change: false }
            setImagen(img);
        } else {

            let img = { url: URL.createObjectURL(files[0]), change: false }
            setImagen(img);
        }

    }
    return (
        <>
            {mensajeAlerta.error ? (
                <>
                    <AlertMessage
                        title={""}
                        message={mensajeAlerta.mensaje}
                        tipo={"alert-danger"}
                    />
                </>
            ) : null}
            <EncabezadoDescripcion titulo={"Servicio: " + match.params.id} id="AdmProductos" descripcion="Se realizan los mantenimientos de los productos que ofrece la página de sigma " />
            {spinner}
            <section className="cart-area mb-4">
                <div className="container">
                    <div className="row">
                    <BtnBack/>
                        <div className="col-lg-12 col-md-12">
                            <form>
                                <div className="cart-table table-responsive carritoCompras">
                                    <div style={{ display: "flow-root" }}>

                                        <button style={{ color: "#ffffff", float: "right", fontSize: "12px", padding: "10px" }} type="button" className="btn  right" onClick={() => showModalAdd()}>Agregar</button>
                                    </div>
                                    <table width="100%" id={TblID} className="table table-bordered  ">
                                        <thead>
                                            <tr>
                                                <th scope="col">Imagen</th>
                                                <th scope="col">Nombre</th>
                                                <th scope="col">Descripción</th>
                                                <th scope="col">Precio</th>
                                                <th scope="col">Estado</th>
                                                <th scope="col">Opciones</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {list == null ?
                                                (<tr> Cargando...</tr>) : (
                                                    list.map(pdato =>

                                                        <tr key={pdato.id} onClick={() => { /*alert(pdato.id)*/ }}>
                                                            <td className="product-thumbnail text-center">

                                                                <img src={pdato.urlImagen} style={{ maxHeight: "100px", borderRadius: "10px", backgroundColor: "rgb(238 238 238)", padding: "5px" }} alt="" />
                                                            </td>
                                                            <td className="product-name">
                                                                {pdato.nombre}
                                                            </td>
                                                            <td className="product-name">
                                                                {pdato.descripcionCorta}
                                                            </td>
                                                            <td className="product-rpice">
                                                                <span className="unit-amount">
                                                                    {FormatMoney(pdato.precio)}
                                                                </span>
                                                            </td>
                                                            <td className="product-name text-center">
                                                                <abbr title="Cambiar Estado">
                                                                    <a href="#" style={{ backgroundColor: GetColor(pdato.estado), color: "white", borderRadius: "20px", padding: "5px 20px", cursor: "pointer" }}
                                                                        onClick={() => {
                                                                            showModalEditEstado(pdato)
                                                                        }}>
                                                                        {pdato.estado === 1 ? "Activo" : "inactivo"}
                                                                    </a>
                                                                </abbr>
                                                            </td>
                                                            <td className="product-name text-center">
                                                                <button style={{ padding: "5px", margin: "5px" }} type="button" className="btn btn-primary" onClick={() => {
                                                                    showModalEdit(pdato)
                                                                }}><abbr title="Editar"><Edit /></abbr></button>
                                                                <button style={{ padding: "5px", margin: "5px" }} type="button" className="btn btn-primary" onClick={() => {
                                                                    showModalDelete(pdato)
                                                                }}><abbr title="Eliminar"><Trash2 /></abbr></button>

                                                            </td>
                                                        </tr>


                                                    )
                                                )
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
            {
                <Modal
                    isOpen={modalAddEdit}
                    shouldCloseOnOverlayClick={true}
                    onRequestClose={() => setModalAddEdit(false)}
                    style={MODAL_STYLES}
                    className="modal-dialog"
                >
                    <div className="modal-content">
                        <div className="modal-header">
                            <div className="modal-title">{titulo}</div>
                            <button
                                type="button"
                                onClick={() => setModalAddEdit(false)}
                                className="close"
                            >
                                &times;
                            </button>
                        </div>
                        <div className="modal-body">
                            <form onSubmit={handleSubmit((edit ? modificar : agregar))} className="col-12">
                                <fieldset>
                                    <div className="form-row">
                                        <div className="form-group col-md-6">
                                            {/**nombre */}
                                            <div className="form-group col-12">
                                                <label>Nombre:</label>
                                                <input
                                                    type="text"
                                                    className={
                                                        "form-control " +
                                                        (errors.nombre ? "input-error" : null)
                                                    }
                                                    name="nombre"
                                                    placeholder="Nombre"
                                                    defaultValue={objeto.nombre}
                                                    ref={register({ required: "Campo requerido." })}
                                                    height="500px"
                                                />

                                                {errors.nombre ? (
                                                    <ErrorForm mensaje={errors.nombre.message} />
                                                ) : null}
                                            </div>
                                            {/**precio */}
                                            <div className="form-group col-12">
                                                <label>{`precio (Sin IVA) (${SIGNO_MONEDA}):`}</label>
                                                <input
                                                    type="number"
                                                    className={
                                                        "form-control " +
                                                        (errors.precio ? "input-error" : null)
                                                    }
                                                    step="0.01"
                                                    name="precio"
                                                    placeholder={`precio en ${MODENA}`}
                                                    defaultValue={objeto.precio}
                                                    ref={register({ required: "Valor inválido.", min: 0.01 })}
                                                />
                                                {errors.precio ? (
                                                    <ErrorForm mensaje={errors.precio.message} />
                                                ) : null}
                                            </div>
                                            {/**codigo */}
                                            <div className="form-group col-12">
                                                <label>Código Plan o Cantidad de documentos:</label>
                                                <input
                                                    type="number"
                                                    className={
                                                        "form-control " +
                                                        (errors.idObjeto ? "input-error" : null)
                                                    }
                                                    name="idObjeto"
                                                    placeholder="ej:03"
                                                    defaultValue={objeto.idObjeto}
                                                    ref={register()}
                                                />
                                                {errors.idObjeto ? (
                                                    <ErrorForm mensaje={errors.idObjeto.message} />
                                                ) : null}
                                            </div>

                                            {/**descripcion */}
                                            <div className="form-group col-12">
                                                <label>Descripción: </label>
                                                <textarea
                                                    className={
                                                        "form-control " +
                                                        (errors.descripcion ? "input-error" : null)
                                                    }
                                                    name="descripcion"
                                                    placeholder="Descripción"
                                                    defaultValue={objeto.descripcion}
                                                    ref={register({ required: "Campo requerido." })}
                                                    rows={5}
                                                    maxLength={500}
                                                />
                                                {errors.descripcion ? (
                                                    <ErrorForm mensaje={errors.descripcion.message} />
                                                ) : null}
                                            </div>

                                        </div>

                                        <div className="form-group col-md-6">
                                            {/**descripcion Corta */}
                                            <div className="form-group col-12">
                                                <label>Descripción corta: </label>
                                                <textarea
                                                    className={
                                                        "form-control " +
                                                        (errors.descripcionCorta ? "input-error" : null)
                                                    }
                                                    name="descripcionCorta"
                                                    placeholder="Descripción Corta"
                                                    defaultValue={objeto.descripcionCorta}
                                                    ref={register({ required: "Campo requerido." })}
                                                    rows={3}
                                                    maxLength={200}
                                                />
                                                {errors.descripcionCorta ? (
                                                    <ErrorForm mensaje={errors.descripcionCorta.message} />
                                                ) : null}
                                            </div>

                                            {/**imaegen */}
                                            <div className="form-group col-12 text-center" >
                                                <img alt='' src={imagen.url} style={{ maxHeight: "172px", borderRadius: "10px", backgroundColor: "rgb(238 238 238)", padding: "20px" }} />
                                            </div>
                                            {/**imaegen file*/}
                                            <div className="form-group col-12">
                                                <label>Imagen:</label>
                                                <input
                                                    id="InputImg"
                                                    type="file"
                                                    className={
                                                        "form-control " +
                                                        (errors.fileImg ? "input-error" : null)
                                                    }
                                                    name="fileImg"
                                                    accept="image/png, image/jpeg"
                                                    placeholder="fileImg"
                                                    ref={edit === false ? register({ required: "Campo requerido." }) : register()}
                                                    onChange={() => { onChangeInputImagen() }}
                                                />
                                                {errors.fileImg ? (
                                                    <ErrorForm mensaje={errors.fileImg.message} />
                                                ) : null}
                                            </div>
                                        </div>
                                        {/**error */}
                                        <div className="col-md-12 text-center">
                                            {enviado ? (
                                                mensajeError.error ? (
                                                    <div className="alert alert-danger  p-2 animated fadeInUp faster mb-3">
                                                        {mensajeError.mensaje}
                                                    </div>
                                                ) : (
                                                    <div className="alert alert-success p-2 animated fadeInUp faster mb-3">
                                                        Registrado con éxito
                                                    </div>
                                                )
                                            ) : null}
                                        </div>
                                    </div>
                                </fieldset>
                                <div className="modal-footer justify-content-between">
                                    <button
                                        type="button"
                                        onClick={() => setModalAddEdit(false)}
                                        className="btn btn-primary"
                                    >
                                        Cancelar
                                    </button>
                                    {edit ?
                                        <>
                                            <button type="submit" className="btn btn-primary">
                                                Modificar
                                            </button>
                                        </>
                                        :
                                        <>
                                            <button type="submit" className="btn btn-primary">
                                                Agregar
                                            </button>
                                        </>
                                    }
                                </div>
                            </form>
                        </div>
                    </div>
                </Modal>
            }
        </>
    )
}
export default withRouter(AdmServicios);