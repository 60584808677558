import React, { useState } from "react";
import EncabezadoDescripcion from "../components/Visuales/EncabezadoDescripcion";
import FrmDesactivarNotificacion from "../components/Formularios/FrmDesactivarNotificacion";

const DesactivarNotificacionPage = (props) => {
    const { match } = props;
    const plan = match.params.plan;
    const cuenta = match.params.cuenta;

    return (
        <>
            <section className="about-area my-5">
                <div className="container">
                    <EncabezadoDescripcion id="perfil" titulo="Desactivar Notificaciones" />
                    <div className="row align-items-center">
                        <div className="col-lg-3 col-md-12">
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="calendario">
                                {<FrmDesactivarNotificacion pln={plan} cta={cuenta} />}
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-12">
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default DesactivarNotificacionPage;
