import React, { useState, useEffect } from 'react';
import EncabezadoFiguras from '../components/Visuales/EncabezadoFiguras';
import { withRouter } from 'react-router-dom';
import { getItem } from '../services/UseLocalStorage';
import { ListaEndpoints, IMPUESTO_VENTA } from '../config/Config';
import FormatMoney from "../services/FormatMoney";
import { GetWithToken, SweetAlert } from '../services/ControlActions';
import Spinner from '../components/Visuales/Spinner';
import $ from "jquery";
import 'datatables.net';
import 'datatables.net-bs4';
import moment from 'moment';
import AlertMessage from "../components/Errors/AlertMessage";
import { useForm } from "react-hook-form";
import "moment"
import { UsePagination } from '../hooks/UsePagination';
import ErrorForm from "../components/Formularios/ErrorForm.js";
import { Search } from 'react-feather';
const place = "Pedidos";
const HistorialPedidos = (props) => {
    const { history } = props;

    const [spinner, showSpinner, hideSpinner] = Spinner();
    const [mensajeAlerta, setMensajeAlerta] = useState({ mensaje: "", error: false });
    const [listEstados, setListEstados] = useState({ data: {}, cargando: true });
    //#region datatables
    const TblID = "tblPedidosAdm";
    const [list, setList] = useState(null);
    const [datatable, setDatatable] = useState(null)
    //filtros
    const { register, handleSubmit, errors, reset } = useForm();
    const [fechaI, setFechaI] = useState(moment().subtract(1, 'months').format("YYYY-MM-DD"));
    const [fechaF, setFechaF] = useState(moment().format("YYYY-MM-DD"));
    const [estadoSelected, setEstadoSelected] = useState(0);//0 = todos
    const [ignorarF, setIgnorarF] = useState(false);//ignorar las fechas en el filtro
    //pagination 
    const { Pagination, setTotalItems, setCountItems, goFirstPage, countItems, currentPage } = UsePagination();
    //const [cargando, setCargando] = useState(false);
    //const [edit, setEdit] = useState(false);

    useEffect(() => {
        initData();
    }, []);
    useEffect(() => {
        try {
            // el validador de los campos de los filtros
            recargarLista();
        } catch (e) {
            console.error("error", e)
        }
    }, [currentPage])
    const startDatatable = () => {
        // console.log("start")
        setDatatable($("#" + TblID).DataTable({
            scrollX: true,
            processing: true,
            destroy: true,
            language: {
                url: "//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json"
            },
            "paging": false,//paginacion
            "ordering": false,
            "info": false,
            "colReorder": false,//ordenamiento des o asc
            "searching": true,//buscador
            "bLengthChange": false,//cantidad de registro por page
            "order": [[0, "desc"]]
        }));
    }
    const destroyDatatable = () => {
        if (datatable != null) {
            datatable.destroy();
            setDatatable(null)
        }
    }
    const recargarLista = async () => {
        destroyDatatable();
        listar().then((res) => {
            //  console.log("res", res)
            if (res) {
                startDatatable();
            }
        })
    }
    const onChangeFechaI = (val) => {
        // console.log("Fecha i", val)
        setFechaI(val);
    }

    const onChangeFechaF = (val) => {
        //console.log("Fecha f", val)
        setFechaF(val);
    }

    const buscar = (data) => {

        setEstadoSelected(data.estadoF);
        setCountItems(parseInt(data.cantidad));
        //console.log("data", data)
        setTimeout(() => {
            goFirstPage();
        }, 500)

    }
    //#endregion

    const initData = () => {//solo se deberia ejecutar una vez
        if (getItem('usuario')) {

            try {
                listarEstados();
            } catch (error) {
                alert("Acción Interrumpida:", error.message);
            }

        } else {
            history.goBack() ? history.goBack() : history.push("/")
        }
    }
    const GetColor = (i) => {
        switch (i) {
            case 1: return "rgb(226, 114, 114)";//rojo

            case -1: return "rgb(226, 114, 114)";//rojo

            case 2:
                return "rgb(118, 226, 114)";//verde
            case 12:
                return "rgb(118, 226, 114)";//verde
            case 11:
                return "rgb(118, 226, 114)";//verde
            default:
                return "rgb(114, 162, 226)";//azul

        }
    }
    const listar = async () => {
        showSpinner();
        /* console.log("data", `GetBuscarPedidos?fechaI=${fechaI}&fechaF=${fechaF}&idEstado=${estadoSelected}
         &ignorarFecha=${ignorarF}&cantidad=${cantidad}&pagina=${paginaActual}`);
 */
        let ok = false;
        await GetWithToken(ListaEndpoints.PEDIDO, `GetBuscarPedidosByIdUser?fechaI=${fechaI}&fechaF=${fechaF}&idEstado=${estadoSelected}
        &ignorarFecha=${ignorarF}&cantidad=${countItems}&pagina=${currentPage.skip}&idUser=${getItem('usuario').userId}`).then((res) => {
            // console.log("res pedidos", res.data)
            if (res.data) {
                setList(res.data);
                setTotalItems(res.data.total);
                setCountItems(res.data.count);
                ok = true;
            } else {
                if (res.Message) {
                    let err = res.Message;
                    SweetAlert(3, "Acción Interrumpida", err, "", "Entendido",
                        () => { history.goBack() ? history.goBack() : history.push("/") })
                    setList(null);
                } else {
                    let err = place + ":" + res.error;
                    SweetAlert(3, "Acción Interrumpida", err, "", "Entendido",
                        () => { history.goBack() ? history.goBack() : history.push("/") })
                    setList(null);
                }
            }
        }).catch((e) => {
            let err = place + ": No pudo conectar al servidor, inténtelo de nuevo más tarde. " + e.message
            SweetAlert(3, "Acción Interrumpida", err, "", "Entendido",
                () => { history.goBack() ? history.goBack() : history.push("/") })
            setList(null);

        }).finally(() => {
            hideSpinner();
            // console.log("end")
        });
        return ok;

    }
    const listarEstados = async () => {
        // showSpinner();
        await GetWithToken(ListaEndpoints.ESTADO_PEDIDO, "GetEstadosPedido").then((res) => {
            //  console.log("Listar estados ", res)
            if (res.ok) {
                setListEstados({ data: res.data, cargando: false });
            } else {
                let err = "Estados del pedido: " + res.error;
                if (res.Message) {
                    err = res.Message;
                    SweetAlert(3, "Acción Interrumpida", err, "", "Entendido",
                        () => { })
                    setListEstados({ data: null, cargando: false });
                    setTimeout(() => {
                        localStorage.clear("usuario");
                        localStorage.clear("carrito");
                        history.push("/login");
                    }, 3000)
                } else {
                    SweetAlert(3, "Acción Interrumpida", err, "", "Entendido",
                        () => { })
                    setListEstados({ data: null, cargando: false });
                }
            }
        }).catch((e) => {
            let err = "Estados del pedido: No pudo conectar al servidor, inténtelo de nuevo más tarde. " + e.message
            setMensajeAlerta({ mensaje: err, error: true })
            setListEstados({ data: null, cargando: false });
            setTimeout(() => {
                localStorage.clear("usuario");
                localStorage.clear("carrito");
                history.push("/login");
            }, 3000);
        }).finally(() => {
            hideSpinner();
            // console.log("end")
        });

    }
    const calculatTotal = (data) => {
        let subTotalDescuento = data.precioTotal - (data.precioTotal * data.descuento);
        return subTotalDescuento + (subTotalDescuento * IMPUESTO_VENTA);
    }
    const irPedido = (data) => {
        history.push("/pedido/" + data.id)
    }
    return (
        <>
            {mensajeAlerta.error ? (
                <>
                    <AlertMessage title={""} message={mensajeAlerta.mensaje} tipo={"alert-danger"} />
                </>
            ) : null}
            <EncabezadoFiguras titulo="Historial" />
            {spinner}
            <section className="cart-area ptb-80">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12">

                            <div className=" carritoCompras mb-2">
                                <form onSubmit={handleSubmit(buscar)} className="row col-12">
                                    <div className="dataTables_length col-2">

                                        <label>Mostrar
                                            <select
                                                name="cantidad"
                                                className={
                                                    "custom-select custom-select-sm form-control form-control-sm " +
                                                    (errors.estado ? "input-error" : null)
                                                }
                                                defaultValue={10}
                                                ref={register({ required: "Campo requerido." })}
                                            >
                                                <option value="5">5</option>
                                                <option value="10">10</option>
                                                <option value="25">25</option>
                                                <option value="50">50</option>
                                                <option value="100">100</option>
                                            </select>
                                            registros</label>
                                        {errors.cantidad ? (
                                            <ErrorForm mensaje={errors.cantidad.message} />
                                        ) : null}
                                    </div>
                                    <div className="form-group col-1">
                                        <label>Ignorar Fechas:</label>

                                        <input
                                            type="checkbox"
                                            className={
                                                "form-control " +
                                                (errors.estado ? "input-error" : null)
                                            }
                                            name="ignorarF"
                                            checked={ignorarF}
                                            onChange={() => setIgnorarF(!ignorarF)}
                                            ref={register()}
                                            style={{ width: "20px", display: "inline-block", height: "20px", marginLeft: "10px", marginRight: "20px" }}
                                        />
                                    </div>
                                    {/**Fecha inicial */}
                                    <div className="form-group col-3">
                                        <label>Fecha Inicial: </label>
                                        <input
                                            type="date"
                                            className={
                                                "form-control " +
                                                (errors.fechaI ? "input-error" : null)
                                            }
                                            name="fechaI"
                                            placeholder="Fecha inicio"
                                            defaultValue={moment().subtract(1, 'months').format("YYYY-MM-DD")}
                                            onChange={(e) => onChangeFechaI(e.target.value)}
                                            ref={register({
                                                validate: {
                                                    value: (val) => {

                                                        // console.log(moment(val), moment(moment().format("YYYY-MM-DD")))
                                                        //console.log(moment(val) > moment(moment().format("YYYY-MM-DD")))
                                                        if (val) {
                                                            if (moment(val) > moment(moment().format("YYYY-MM-DD"))) {

                                                                return "La fecha inicial no puede ser posterior a la fecha actual"
                                                            } else {
                                                                if (moment(val) > moment(moment(fechaF))) {
                                                                    return "La fecha inicial no puede ser posterior a la fecha final"
                                                                } else {
                                                                    return true;
                                                                }
                                                            }
                                                        } else {
                                                            return "Fecha Inválida"
                                                        }
                                                        // return true // solo para pruebas
                                                    },
                                                },
                                            })}
                                        />
                                        {errors.fechaI ? (

                                            <p style={{ position: "absolute" }} className="text-danger">{errors.fechaI.message}</p>
                                        ) : null}
                                    </div>
                                    {/**Fecha final */}
                                    <div className="form-group col-3">
                                        <label>Fecha Final:</label>
                                        <input
                                            type="date"
                                            className={
                                                "form-control " +
                                                (errors.fechaF ? "input-error" : null)
                                            }
                                            name="fechaF"
                                            placeholder="Fecha Final"
                                            defaultValue={moment().format("YYYY-MM-DD")}
                                            onChange={(e) => onChangeFechaF(e.target.value)}
                                            ref={register({
                                                validate: {
                                                    value: (val) => {
                                                        // console.log(moment(val), moment(moment().format("YYYY-MM-DD")))
                                                        //   console.log(moment(val) < moment(moment().format("YYYY-MM-DD")))
                                                        if (val) {
                                                            if (moment(val) > moment(moment().format("YYYY-MM-DD"))) {

                                                                return "La fecha final no puede ser posterior a la fecha actual"
                                                            } else {
                                                                if (moment(val) < moment(moment(fechaI))) {
                                                                    return "La fecha final no puede ser anterior a la fecha inicial"
                                                                } else {
                                                                    return true;
                                                                }
                                                            }
                                                        } else {
                                                            return "Fecha Inválida"
                                                        }
                                                        // return true // solo para pruebas
                                                    },
                                                },
                                            })}
                                        />
                                        {errors.fechaF ? (
                                            <p style={{ position: "absolute" }} className="text-danger">{errors.fechaF.message}</p>

                                        ) : null}
                                    </div>
                                    {/**estado filtro */}
                                    <div className="form-group col-2">
                                        <label>Estado:</label>
                                        {listEstados.cargando == false ? listEstados.data != null ?

                                            <select name="estadoF"
                                                className={
                                                    "form-control " +
                                                    (errors.estadoF ? "input-error" : null)
                                                }
                                                defaultValue={0}
                                                ref={register({ required: "Campo requerido." })}
                                            >
                                                <option key={0} value={0}>{"Todos"}</option>
                                                {
                                                    listEstados.data.map((item) =>
                                                        <option key={item.id} value={item.id}>{item.nombre}</option>
                                                    )
                                                }
                                            </select>
                                            : "Sin Información" : "Cargando..."
                                        }
                                        {errors.estadoF ? (
                                            <ErrorForm mensaje={errors.estadoF.message} />
                                        ) : null}

                                    </div>

                                    <div className="form-group col-1">
                                        <label>Filtrar:</label>
                                        <button style={{ padding: "5px", margin: "5px" }} type="submit" className="btn btn-primary"
                                            onClick={() => { }}><abbr title="Detalle"><Search /></abbr></button>
                                    </div>
                                </form>
                            </div>

                            <div className="cart-table table-responsive carritoCompras">

                                <table width="100%" id={"tblHistorial"} className="table table-bordered  ">
                                    <thead>
                                        <tr>
                                            <th scope="col">Factura</th>
                                            <th scope="col">Cliente</th>
                                            <th scope="col">Fecha</th>
                                            <th scope="col">Precio Total</th>
                                            <th scope="col">Estado</th>

                                        </tr>
                                    </thead>
                                    <tbody>


                                        {list == null ?
                                            (<tr> Cargando...</tr>) : (
                                                list.data.map(pdato =>

                                                    <tr key={pdato.id} onClick={() => { irPedido(pdato) }}>
                                                        <td className="product-thumbnail">

                                                            {pdato.referenciaFacel}
                                                        </td>

                                                        <td className="product-name">
                                                            {pdato.nombre}
                                                        </td>
                                                        <td className="product-price text-center">
                                                            <span className="unit-amount">
                                                                {pdato.fecha ? moment(pdato.fecha).format('YYYY/MM/DD, hh:mm a') : null}
                                                            </span>
                                                        </td>
                                                        <td className="product-price text-right">
                                                            <span className="unit-amount">
                                                                {FormatMoney(calculatTotal(pdato))}
                                                            </span>
                                                        </td>



                                                        <td className="product-price text-center">

                                                            <div style={{ backgroundColor: GetColor(pdato.estado), color: "white", borderRadius: "20px", width: "" }}> {pdato.estadoString}</div>

                                                        </td>

                                                    </tr>

                                                )
                                            )
                                        }

                                    </tbody>
                                </table>
                                <div className="dataTables_paginate paging_simple_numbers" id="tblPedidosAdm_paginate">
                                    {/**aqui va el use pagination */}
                                    <Pagination />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default withRouter(HistorialPedidos);
