import React from "react";
import FormatMoney from "../services/FormatMoney";
import { Link } from "react-router-dom";

const FilaCarrito = ({ data, descuento, impuesto }) => {

  let productoData = data;

  let subTotal = productoData.precio /** productoData.cantidad*/;
    let montoDescuento = subTotal * descuento;
    let SubTotalDescuento = (subTotal - montoDescuento);
    let totalImpuesto = (SubTotalDescuento * impuesto);

  return (
    <>

      <tr key={productoData.id}>

        <td className="product-name">
          <Link to="/servicios">{productoData.nombrePro}</Link>
        </td>
        <td className="product-name product-subtotal ">
          <span>{productoData.correoCuenta !== "" ? productoData.correoCuenta: "Sin cuenta a aplicar (Agregar)"}</span>
       
        </td>
        <td className="product-price text-right">
          <span className="unit-amount">
            {FormatMoney(SubTotalDescuento)}
          </span>
        </td>

        <td className="product-subtotal text-right">
          <span className="subtotal-amount">{FormatMoney(SubTotalDescuento + totalImpuesto)}</span>
         
        </td>
      </tr>
    </>
  );
};

export default FilaCarrito;
