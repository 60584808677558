import { useState, useEffect } from 'react'
import useInterval from '../services/useInterval';
import { getItem, setItem } from '../services/UseLocalStorage';
import Spinner from '../components/Visuales/Spinner';
//test de pago
import { SweetAlert, Retrieve } from "../services/ControlActions";
import { ListaEndpoints, IMPUESTO_VENTA } from "../config/Config";

export const UseCarritoState = () => {

    const [carritoData, setCarritoData] = useState({});
    const [recargar, setRecargar] = useState(true);
    const [spinner, showSpinner, hideSpinner] = Spinner();
    const [promocion, setPromocion] = useState(null);


    useInterval(() => {
        if (getItem("carrito")) {
            let carritoTem = getItem("carrito");
            /*deme el carrito del sesion y valida si es diferente al del componente 
            y son diferentes lo recarga el componente*/
            if (carritoData.fechaModificacion < carritoTem.fechaModificacion) {
                setRecargar(true);

            }
        }
    }, 2000);
    const ListarCarrito = () => {
        showSpinner();
        if (getItem("usuario")) {
            //no es por user id sino por el id de la cuenta
            Retrieve(ListaEndpoints.CARRITO, "GetCarritoByUserId", getItem("usuario").userId).then((res) => {
                //  console.log("carrito", res)
                if (res.data) {

                    /*  if (res.data.CodigoAplicable != null) {
                          res.data.CodigoAplicable.Pedido = null;
                          res.data.CodigoAplicable.Carrito = null;
                          res.data.CodigoAplicable.RegistroCodigoAplicable = null;
                      }*/
                    setItem("carrito", res.data);
                    setCarritoData(res.data);
                    setRecargar(false);
                } else {
                    if (res.error) {
                        SweetAlert(3, "Acción Interrumpida", res.error, "", "Entendido", () => { });
                        console.error(res.error, res.errorDescription)
                    }
                }
                hideSpinner();
            },//fin de retrieve
                (e) => {

                    hideSpinner();
                    let err =
                        "Carrito: No pudo conectar al servidor, inténtelo de nuevo más tarde.  " +
                        e.message;
                    //   console.log(e.message, e)
                    SweetAlert(3, "Acción Interrumpida", err, "", "Entendido", () => { });
                }
            );
        }

    }
    const CambiarPromo = (promo) => {
        showSpinner();
        //console.log("obj", promo);
        Retrieve(ListaEndpoints.CARRITO, "GetAplicarCodigoCarrito" + `?idCarrito=${getItem("carrito").id}&idCodigo=${promo != null ? promo.id : 0}`, "")
            .then((res) => {
                //console.log("Cambiar promocion", res);
                if (res.data) {
                    setPromocion(promo);
                    setItem("carrito", res.data);
                    setCarritoData(res.data);
                    setRecargar(false);
                } else {
                    if (res.error) {
                        SweetAlert(3, "Acción Interrumpida", res.error, "", "Entendido", () => { });
                        console.error(res.error, res.errorDescription)
                    }
                    setPromocion(null);
                }

                hideSpinner();
            })
            .catch((e) => {
                hideSpinner();
                setRecargar(false);
                setPromocion(null);
                let err =
                    "Carrito: No pudo conectar al servidor, inténtelo de nuevo más tarde.  " +
                    e.message;
                console.log(e.message, e)
                SweetAlert(3, "Acción Interrumpida", err, "", "Entendido", () => { });

            });
    }
    useEffect(() => {
        if (getItem("carrito")) {
            if (recargar) {
                ListarCarrito();
            }
        }
    }, [recargar]);
    return (
        {
            CambiarPromo,
            ListarCarrito,
            carritoData,
            setCarritoData,
            recargarCarrito: recargar,
            spinnerCarrito: spinner,
            promocion,
            impuesto: IMPUESTO_VENTA,
            userId: getItem("usuario").userId
        }
    )
}
