import React from "react";
import EncabezadoDescripcion from "../components/Visuales/EncabezadoDescripcion";
import InfoTerminosYCondiciones from "../components/Formularios/InfoTerminosYCondiciones";
const TerminosYCondiciones = () => {
    return (
        <>
            <section className="about-area">
                <div className=" mb-5">
                    <div className="row justify-content-center">
                        <div className="col-10 text-justify text-white">
                            <EncabezadoDescripcion titulo="Términos & condiciones:" />
                            <div className="about-content">

                                <h5 className="mb-2" >Bienvenido a Sigmacr.net </h5>
                                <p>Sigma le ofrece funciones de sitio web y otros productos y servicios cuando visita o compra en www.sigmacr.net (el "sitio web"), utiliza los productos o servicios de Sigma, usa las aplicaciones de Sigma para dispositivos móviles o emplea software puesto a disposición por Sigma en relación con lo anterior (los "Servicios de Sigma" e individuamente, cada uno de ellos , el "Servicio de Sigma"). Sigma le presta los Servicios bajo las condiciones establecidas en esta página. </p>
                            </div>
                            <InfoTerminosYCondiciones />
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default TerminosYCondiciones;
