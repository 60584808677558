import React, { useState, useEffect } from 'react';
import EncabezadoDescripcion from "../components/Visuales/EncabezadoDescripcion";
import { withRouter } from 'react-router-dom';
import { getItem } from '../services/UseLocalStorage';
import { ListaEndpoints, MODAL_STYLES } from '../config/Config';
import { SweetAlert, SweetAlert2, SwalToast, GetWithToken, UpdateWithToken, DeleteWithToken, PostWithToken } from '../services/ControlActions';
import Spinner from '../components/Visuales/Spinner';
import $ from "jquery";
import 'datatables.net';
import 'datatables.net-bs4';
import AlertMessage from "../components/Errors/AlertMessage";
import Modal from "react-modal";
/*formulario */
import { Trash2, Edit, PlusCircle } from "react-feather";
import { useForm } from "react-hook-form";
import ErrorForm from "../components/Formularios/ErrorForm.js";
import { img_default_cliente } from "../images/imagesURL.js";
import BtnBack from '../components/Formularios/BtnBack';

const place = "Servicios";
const AdmServicios = (props) => {
    const { history } = props;

    const [objeto, setObjeto] = useState({
        id: "",
        duenno: "",
        tipo: "",
        titulo: "",
        descripcion: "",
        codigoPlanes: "",
        urlImagen: "",
        idImagen: "",
        urlImagenBlanca: "",
        idImagenBlanca: "",
        otroDetalle: 0,
        otroTitulo: "",
        otroDescripcion: "",
        telefono: "",
        correo: "",
        estado: 0,
        fileImg: null,
        typeImg: null,
        fileImg2: null,
        typeImg2: null
    });
    const [modalAddEdit, setModalAddEdit] = useState(false);
    const [edit, setEdit] = useState(false);
    const [titulo, setTitulo] = useState("Acción");
    const [spinner, showSpinner, hideSpinner] = Spinner();
    const [detalle, setDetalle] = useState(false);
    const [imagen, setImagen] = useState({ url: img_default_cliente, change: false });
    const [imagen2, setImagen2] = useState({ url: img_default_cliente, change: false });
    const [mensajeAlerta, setMensajeAlerta] = useState({ mensaje: "", error: false });

    const CheckID = "checkBoxOtroDetalle";
    //formulario
    const { register, handleSubmit, errors, reset } = useForm();
    const [mensajeError, setMensajeError] = useState({
        error: false,
        mensaje: "",
    });

    const [enviado, setEnviado] = useState(false);
    //#region datatables
    const TblID = "tblServiciosAdm";
    const [list, setList] = useState(null);
    const [datatable, setDatatable] = useState(null)
    useEffect(() => {
        initData();
    }, []);
    const startDatatable = () => {
        //console.log("start")
        setDatatable($("#" + TblID).DataTable({
            processing: true,
            language: {
                url: "//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json"
            },
            scrollX: true,
            order: [[2, "desc"]]
        }));
    }
    const destroyDatatable = () => {

        if (datatable != null) {
            // console.log("destroy");
            datatable.destroy();
            setDatatable(null)
        }
    }
    const recargarLista = async () => {
        destroyDatatable();
        listar().then((res) => {
            //console.log("res", res)
            if (res) {
                startDatatable();
            }
        })
    }
    const initData = () => {//solo se deberia ejecutar una vez
        if (getItem('usuario')) {
            if (getItem('usuario').uTipo != "tp") {
                history.goBack() ? history.goBack() : history.push("/")
            } else {
                try {
                    recargarLista();
                } catch (error) {
                    alert("Acción Interrumpida:", error.message);
                }
            }
        } else {
            history.goBack() ? history.goBack() : history.push("/")
        }
    }
    //#endregion

    const listar = async () => {
        showSpinner();
        let ok = false;
        await GetWithToken(ListaEndpoints.SERVICIO, "GetServicios").then((res) => {
            //console.log("res", res.data)
            if (res.data) {
                setList(res.data);
                ok = true;
            } else {
                if (res.Message) {
                    let err = res.Message;
                    SweetAlert(3, "Acción Interrumpida", err, "", "Entendido",
                        () => { history.goBack() ? history.goBack() : history.push("/") })
                    setList(null);
                } else {
                    let err = place + ":" + res.error;
                    SweetAlert(3, "Acción Interrumpida", err, "", "Entendido",
                        () => { history.goBack() ? history.goBack() : history.push("/") })
                    setList(null);
                }
            }
        }).catch((e) => {
            let err = place + ": No pudo conectar al servidor, inténtelo de nuevo más tarde. " + e.message
            setMensajeAlerta({ mensaje: err, error: true })
            setTimeout(() => {
                localStorage.clear("usuario");
                localStorage.clear("carrito");
                history.push("/login");
            }, 3000);
            setList(null);

        }).finally(() => {
            hideSpinner();
            // console.log("end")
        });
        return ok;

    }
    const GetColor = (i) => {
        switch (i) {
            case 0:
                return "rgb(226, 114, 114)";

            case 1:
                return "rgb(114, 162, 226)";

            case 2:
                return "rgb(118, 226, 114)";

            default:
                return "ffffff";
        }
    }
    const agregar = async (data) => {
        let bool = true;
        var promise = new Promise(getBuffer(data.fileImg[0]));
        await promise.then(function (d) {
            data.typeImg = data.fileImg[0].type;
            data.fileImg = d.toString();
        }).catch(function (err) {
            console.error(err.message, err);
            bool = false;
            let e = place + ": " + err.message;
            SweetAlert(3, "Acción Interrumpida", e, "", "Entendido",
                () => { })
        });
        var promise2 = new Promise(getBuffer(data.fileImg2[0]));
        await promise2.then(function (d) {
            data.typeImg2 = data.fileImg2[0].type;
            data.fileImg2 = d.toString();
        }).catch(function (err) {
            console.error(err.message, err);
            bool = false;
            let e = place + ": " + err.message;
            SweetAlert(3, "Acción Interrumpida", e, "", "Entendido",
                () => { })
        });
        if (bool) {
            showSpinner();
            setEnviado(false);

            let datos = {
                id: data.id,
                duenno: "Sigma",
                tipo: data.tipo,
                titulo: data.titulo,
                descripcion: data.descripcion,
                codigoPlanes: data.codigoPlanes,
                urlImagen: "",
                idImagen: "",
                urlImagenBlanca: "",
                idImagenBlanca: "",
                otroDetalle: detalle ? 1 : 0,
                otroTitulo: detalle ? data.otroTitulo : "",
                otroDescripcion: detalle ? data.otroDescripcion : "",
                telefono: detalle ? data.telefono : "",
                correo: detalle ? data.correo : "",
                estado: 1,
                //clases parciales
                fileImg: data.fileImg,
                typeImg: data.typeImg,
                fileImg2: data.fileImg2,
                typeImg2: data.typeImg2,
            };
            await PostWithToken(ListaEndpoints.SERVICIO, "PostServicio", "", datos).then((res) => {
                if (res.data) {

                    reset();
                    SwalToast(2, res.msg, () => { }, 1000);
                    setMensajeError({ error: false, mensaje: "" });
                    setModalAddEdit(false)
                } else {
                    if (res.error) {
                        setMensajeError({ error: true, mensaje: res.error });
                        console.error(res.error, res.errorDescription)
                    }
                    if (res.Message) {
                        setMensajeError({ error: true, mensaje: res.Message });
                        console.error(res.error, res.Message)
                    }
                }

            }).catch((e) => {
                setMensajeError({ error: true, mensaje: e.message });
                console.error("Acción Interrumpida:", e.message)
            }).finally(() => {
                hideSpinner();
                recargarLista();
                setEnviado(true);
            });;
        }
    }
    const modificar = async (data) => {
        // console.log("data edit", data)
        let bool = true;
        if (data.fileImg != null) {
            if (data.fileImg && data.fileImg.length) {
                var promise = new Promise(getBuffer(data.fileImg[0]));
                await promise.then(function (d) {
                    data.typeImg = data.fileImg[0].type;
                    data.fileImg = d.toString();

                }).catch(function (err) {
                    console.error(err.message, err);
                    bool = false;
                    let e = place + ": " + err.message;
                    SweetAlert(3, "Acción Interrumpida", e, "", "Entendido",
                        () => { })
                });
            }
        }
        if (data.fileImg2 != null) {
            if (data.fileImg2 && data.fileImg2.length) {
                var promise2 = new Promise(getBuffer(data.fileImg2[0]));
                await promise2.then(function (d) {
                    data.typeImg2 = data.fileImg2[0].type;
                    data.fileImg2 = d.toString();

                }).catch(function (err) {
                    console.error(err.message, err);
                    bool = false;
                    let e = place + ": " + err.message;
                    SweetAlert(3, "Acción Interrumpida", e, "", "Entendido",
                        () => { })
                });
            }
        }
        if (bool) {
            showSpinner();
            setEnviado(false);

            //setObjeto(data);
            let datos = {
                id: edit ? objeto.id : data.id,
                duenno: data.duenno,
                tipo: data.tipo,
                titulo: data.titulo,
                descripcion: data.descripcion,
                codigoPlanes: data.codigoPlanes,
                urlImagen: data.urlImagen,
                idImagen: edit ? objeto.idImagen : data.idImagen,
                urlImagenBlanca: data.urlImagenBlanca,
                idImagenBlanca: edit ? objeto.idImagenBlanca : data.idImagenBlanca,
                otroDetalle: edit ? (detalle ? 1 : 0) : data.otroDetalle,
                otroTitulo: data.otroTitulo,
                otroDescripcion: data.otroDescripcion,
                telefono: data.telefono,
                correo: data.correo,
                estado: edit ? objeto.estado : data.estado,
                //clases parciales
                fileImg: data.fileImg,
                typeImg: data.typeImg,
                fileImg2: data.fileImg2,
                typeImg2: data.typeImg2,
            };
            await UpdateWithToken(ListaEndpoints.SERVICIO, "PutServicio", "", datos).then((res) => {
                if (res.data) {

                    reset();
                    SwalToast(2, res.msg, () => { }, 1000);
                    setMensajeError({ error: false, mensaje: "" });
                    setModalAddEdit(false);
                    setEdit(false);
                } else {
                    if (res.error) {
                        setMensajeError({ error: true, mensaje: res.error });
                        console.error(res.error, res.errorDescription)
                    }
                    if (res.Message) {
                        setMensajeError({ error: true, mensaje: res.Message });
                        console.error(res.error, res.Message)
                    }
                }

            }).catch((e) => {
                setMensajeError({ error: true, mensaje: e.message });
                console.error("Acción Interrumpida:", e);

            }).finally(() => {
                hideSpinner();
                recargarLista();
                setEnviado(true);
            });
        }
    }
    const eliminar = async (data) => {
        showSpinner();
        /*setEnviado(false);
        setCargando(true);*/

        await DeleteWithToken(ListaEndpoints.SERVICIO, "DeleteServicio", "", data).then((res) => {
            if (res.data) {
                reset();
                SwalToast(2, res.msg, () => { }, 1000);
                setMensajeError({ error: false, mensaje: "" });
            } else {
                if (res.error) {
                    let err = place + ": " + res.error;
                    SweetAlert(3, "Acción Interrumpida", err, "", "Entendido",
                        () => { })
                    console.error(res.error, res.errorDescription)
                }
                if (res.Message) {
                    let err = place + ": " + res.Message;
                    SweetAlert(3, "Acción Interrumpida", err, "", "Entendido",
                        () => { })
                    console.error(res.Message)
                }
            }
        }).catch((e) => {
            let err = place + ": " + e.message;
            SweetAlert(3, "Acción Interrumpida", err, "", "Entendido",
                () => { })
            console.error("Acción Interrumpida:", e);

        }).finally(() => {
            hideSpinner();
            recargarLista();
            setEnviado(true);
        });

    }
    const showModalAdd = () => {
        setModalAddEdit(true)
        setEdit(false);
        setTitulo("Agregar Servicio");
        setEnviado(false);
        let datos = {
            id: "",
            duenno: "",
            tipo: "",
            titulo: "",
            descripcion: "",
            codigoPlanes: "",
            urlImagen: "",
            idImagen: "",
            urlImagenBlanca: "",
            idImagenBlanca: "",
            otroDetalle: 0,
            otroTitulo: "",
            otroDescripcion: "",
            telefono: "",
            correo: "",
            estado: 0,
            fileImg: null,
            typeImg: null,
            fileImg2: null,
            typeImg2: null
        };
        let img = { url: img_default_cliente, change: false };
        setImagen(img);
        setImagen2(img);
        setDetalle(false)

        setObjeto(datos);
    };
    const showModalEdit = (data) => {
        setModalAddEdit(true)
        setEdit(true);
        setTitulo("Editar Servicio");
        setEnviado(false);
        let datos = {
            id: data.id,
            duenno: data.duenno,
            tipo: data.tipo,
            titulo: data.titulo,
            descripcion: data.descripcion,
            codigoPlanes: data.codigoPlanes,
            urlImagen: data.urlImagen,
            idImagen: data.idImagen,
            urlImagenBlanca: data.urlImagenBlanca,
            idImagenBlanca: data.idImagenBlanca,
            otroDetalle: data.otroDetalle,
            otroTitulo: data.otroTitulo,
            otroDescripcion: data.otroDescripcion,
            telefono: data.telefono,
            correo: data.correo,
            estado: data.estado,
            //clases parciales
            fileImg: data.fileImg,
            typeImg: data.typeImg,
            fileImg2: data.fileImg2,
            typeImg2: data.typeImg2,
        };
        let img = { url: data.urlImagen, change: false }
        let img2 = { url: data.urlImagenBlanca, change: false }
        if (data.otroDetalle == 1) {
            setDetalle(true)
        } else {
            setDetalle(false)
        }
        setImagen(img);
        setImagen2(img2);
        setObjeto(datos);
    };
    const showModalDelete = (data) => {
        let msg = `¿Está seguro que desea eliminar al cliente: ${data.titulo}?`;
        SweetAlert2(1, "Eliminar cliente", msg, "Este cambio no se puede revertir.",
            "Cancelar", () => { },
            "Aceptar", () => { eliminar(data) })

    };
    const closeModal = () => {

        let datos = {
            id: "",
            duenno: "",
            tipo: "",
            titulo: "",
            descripcion: "",
            codigoPlanes: "",
            urlImagen: "",
            idImagen: "",
            urlImagenBlanca: "",
            idImagenBlanca: "",
            otroDetalle: 0,
            otroTitulo: "",
            otroDescripcion: "",
            telefono: "",
            correo: "",
            estado: 0,
            fileImg: null,
            typeImg: null,
            fileImg2: null,
            typeImg2: null
        };
        setObjeto(datos);
        setModalAddEdit(false)
    }
    const showModalEditEstado = async (data) => {
        let datos = {
            id: data.id,
            duenno: data.duenno,
            tipo: data.tipo,
            titulo: data.titulo,
            descripcion: data.descripcion,
            codigoPlanes: data.codigoPlanes,
            urlImagen: data.urlImagen,
            idImagen: data.idImagen,
            urlImagenBlanca: data.urlImagenBlanca,
            idImagenBlanca: data.idImagenBlanca,
            otroDetalle: data.otroDetalle,
            otroTitulo: data.otroTitulo,
            otroDescripcion: data.otroDescripcion,
            telefono: data.telefono,
            correo: data.correo,
            estado: data.estado == 1 ? 0 : 1,
            //clases parciales
            fileImg: null,
            typeImg: null,
            fileImg2: null,
            typeImg2: null
            ,

        };
        await setObjeto(datos);
        let msg = `¿Está seguro que desea ${data.estado == 1 ? "desactivar" : "activar"} el servicio: ${data.titulo}?`;
        SweetAlert2(1, "Cambiar estado", msg, `El servicio ${data.estado == 1 ? "NO" : ""} se mostrará en la página de inicio.`,
            "Cancelar", () => { },
            "Aceptar", () => { modificar(datos) })
    };
    const getBuffer = (fileData) => {
        return function (resolve) {
            var reader = new FileReader();
            reader.readAsDataURL(fileData);
            reader.onload = function () {
                var arrayBuffer = reader.result
               // console.log("array", fileData)
                arrayBuffer = arrayBuffer.replace("data:image/jpeg;base64,", "");
                arrayBuffer = arrayBuffer.replace("data:image/png;base64,", "");
                arrayBuffer = arrayBuffer.replace("data:image/jpg;base64,", "");
                //var bytes = new Uint8Array(arrayBuffer);
                resolve(arrayBuffer);
            }
        }
    }
    const onChangeInputImagen = () => {
        const input = document.getElementById("InputImg");
        let files = input.files;
        if (!files || !files.length) {
            let img = { url: edit ? objeto.urlImagen : img_default_cliente, change: false }
            setImagen(img);
        } else {

            let img = { url: URL.createObjectURL(files[0]), change: false }
            setImagen(img);
        }

    }
    const onChangeInputImagen2 = () => {
        const input = document.getElementById("InputImg2");
        let files = input.files;
        if (!files || !files.length) {
            let img = { url: edit ? objeto.urlImagenBlanca : img_default_cliente, change: false }
            setImagen2(img);
        } else {

            let img = { url: URL.createObjectURL(files[0]), change: false }
            setImagen2(img);
        }

    }
    const goProductos = (data) => {
        history.push("/adm/servicios/productos/" + data.id)
    }
    return (
        <>
            {mensajeAlerta.error ? (
                <>
                    <AlertMessage
                        title={""}
                        message={mensajeAlerta.mensaje}
                        tipo={"alert-danger"}
                    />
                </>
            ) : null}
            <EncabezadoDescripcion titulo="Servicios" id="AdmServicios" descripcion="Se realizan los mantenimientos de los servicios que ofrece la página de sigma
            
             " />
            {spinner}
            <section className="cart-area mb-4">
                <div className="container">
                    <div className="row">
                    <BtnBack/>
                        <div className="col-lg-12 col-md-12">
                            <form>
                                <div className="cart-table table-responsive carritoCompras">
                                    <div style={{ display: "flow-root" }}>

                                        <button style={{ color: "#ffffff", float: "right", fontSize: "12px", padding: "10px" }} type="button" className="btn  right" onClick={() => showModalAdd()}>Agregar</button>
                                    </div>
                                    <table width="100%" id={TblID} className="table table-bordered  ">
                                        <thead>
                                            <tr>
                                                <th scope="col">Logo claro</th>
                                                <th scope="col">Logo oscuro</th>
                                                <th scope="col">Tipo</th>
                                                <th scope="col">Título</th>
                                                <th scope="col">Estado</th>
                                                <th scope="col">Opciones</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {list == null ?
                                                (<tr> Cargando...</tr>) : (
                                                    list.map(pdato =>

                                                        <tr key={pdato.id} onClick={() => { /*alert(pdato.id)*/ }}>
                                                            <td className="product-thumbnail text-center">

                                                                <img src={pdato.urlImagen} style={{ maxHeight: "100px", borderRadius: "10px", backgroundColor: "rgb(238 238 238)", padding: "5px" }} />
                                                            </td>
                                                            <td className="product-thumbnail text-center">

                                                                <img src={pdato.urlImagenBlanca} style={{ maxHeight: "100px", borderRadius: "10px", backgroundColor: "#282c34", padding: "5px" }} />
                                                            </td>
                                                            <td className="product-name">
                                                                {pdato.tipo}
                                                            </td>
                                                            <td className="product-name">
                                                                <span className="unit-amount">
                                                                    {pdato.titulo}
                                                                </span>
                                                            </td>
                                                            <td className="product-name text-center">
                                                                <abbr title="Cambiar Estado">
                                                                    <a style={{ backgroundColor: GetColor(pdato.estado), color: "white", borderRadius: "20px", padding: "5px 20px", cursor: "pointer" }}
                                                                        onClick={() => {
                                                                            showModalEditEstado(pdato)
                                                                        }}>
                                                                        {pdato.estado == 1 ? "Activo" : "inactivo"}
                                                                    </a>
                                                                </abbr>
                                                            </td>
                                                            <td className="product-name text-center">
                                                                <button style={{ padding: "5px", margin: "5px" }} type="button" className="btn btn-primary" onClick={() => {
                                                                    showModalEdit(pdato)
                                                                }}><abbr title="Editar"><Edit /></abbr></button>
                                                                <button style={{ padding: "5px", margin: "5px" }} type="button" className="btn btn-primary" onClick={() => {
                                                                    showModalDelete(pdato)
                                                                }}><abbr title="Eliminar"><Trash2 /></abbr></button>
                                                                <button title="Productos" style={{ padding: "5px", margin: "5px" }} type="button" className="btn btn-primary" onClick={() => {
                                                                    goProductos(pdato)
                                                                }} ><abbr title="Productos"><PlusCircle /></abbr></button>
                                                            </td>
                                                        </tr>

                                                    )
                                                )
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
            {
                <Modal
                    isOpen={modalAddEdit}
                    shouldCloseOnOverlayClick={true}
                    onRequestClose={() => closeModal()}
                    style={MODAL_STYLES}
                    className="modal-dialog"
                >
                    <div className="modal-content">
                        <div className="modal-header">
                            <div className="modal-title">{titulo}</div>
                            <button
                                type="button"
                                onClick={() => closeModal()}
                                className="close"
                            >
                                &times;
                            </button>
                        </div>
                        <div className="modal-body">
                            <form onSubmit={handleSubmit((edit ? modificar : agregar))} className="col-12">
                                <fieldset>
                                    <div className="form-row">
                                        <div className="form-group col-md-6">
                                            {/**id */}
                                            <div className="form-group col-12">
                                                <label>Id de referencia:</label>
                                                <input
                                                    type="text"
                                                    className={
                                                        "form-control " +
                                                        (errors.id ? "input-error" : null)
                                                    }
                                                    name="id"
                                                    placeholder="Id"
                                                    defaultValue={objeto.id}

                                                    ref={register({ required: "Campo requerido." })}
                                                />
                                                {errors.id ? (
                                                    <ErrorForm mensaje={errors.id.message} />
                                                ) : null}
                                            </div>
                                            {/**tipo */}
                                            <div className="form-group col-12">
                                                <label>Tipo:</label>

                                                <select name="tipo"
                                                    className={
                                                        "form-control " +
                                                        (errors.tipo ? "input-error" : null)
                                                    }
                                                    defaultValue={objeto.tipo}
                                                    ref={register({ required: "Campo requerido." })}
                                                >
                                                    <option value="carrito">Carrito</option>

                                                    <option value="prox">Próximamente</option>
                                                </select>

                                                {errors.tipo ? (
                                                    <ErrorForm mensaje={errors.tipo.message} />
                                                ) : null}

                                            </div>
                                            {/**código */}
                                            <div className="form-group col-12">
                                                <label>Código asociado a planes:</label>
                                                <input
                                                    type="text"
                                                    className={
                                                        "form-control " +
                                                        (errors.codigoPlanes ? "input-error" : null)
                                                    }
                                                    name="codigoPlanes"
                                                    placeholder="Ej:018"
                                                    defaultValue={objeto.codigoPlanes}
                                                    ref={register({ required: "Campo requerido." })}
                                                />
                                                {errors.codigoPlanes ? (
                                                    <ErrorForm mensaje={errors.codigoPlanes.message} />
                                                ) : null}
                                            </div>
                                        </div>
                                        <div className="form-group col-md-6">
                                            {/**titulo */}
                                            <div className="form-group col-12">
                                                <label>Título:</label>
                                                <input
                                                    type="text"
                                                    className={
                                                        "form-control " +
                                                        (errors.titulo ? "input-error" : null)
                                                    }
                                                    name="titulo"
                                                    placeholder="Título"
                                                    defaultValue={objeto.titulo}
                                                    ref={register({ required: "Campo requerido." })}
                                                    height="500px"
                                                />

                                                {errors.titulo ? (
                                                    <ErrorForm mensaje={errors.titulo.message} />
                                                ) : null}
                                            </div>
                                            {/**descripcion */}
                                            <div className="form-group col-12">
                                                <label>Descripción: </label>
                                                <textarea
                                                    className={
                                                        "form-control " +
                                                        (errors.descripcion ? "input-error" : null)
                                                    }
                                                    name="descripcion"
                                                    placeholder="Descripción"
                                                    defaultValue={objeto.descripcion}
                                                    ref={register({ required: "Campo requerido." })}
                                                    rows={5}
                                                    maxLength={500}
                                                />
                                                {errors.descripcion ? (
                                                    <ErrorForm mensaje={errors.descripcion.message} />
                                                ) : null}
                                            </div>
                                        </div>
                                        <div className="form-group col-md-6">
                                            {/**imaegen */}
                                            <div className="form-group col-12 text-center" >
                                                <img src={imagen.url} style={{ maxHeight: "172px", borderRadius: "10px", backgroundColor: "rgb(238 238 238)", padding: "20px" }} />
                                            </div>
                                            {/**imaegen file*/}
                                            <div className="form-group col-12">
                                                <label>Logo Oscuro:</label>
                                                <input
                                                    id="InputImg"
                                                    type="file"
                                                    className={
                                                        "form-control " +
                                                        (errors.fileImg ? "input-error" : null)
                                                    }
                                                    name="fileImg"
                                                    accept="image/png, image/jpeg"
                                                    placeholder="fileImg"
                                                    ref={edit == false ? register({ required: "Campo requerido." }) : register()}
                                                    onChange={() => { onChangeInputImagen() }}
                                                />
                                                {errors.fileImg ? (
                                                    <ErrorForm mensaje={errors.fileImg.message} />
                                                ) : null}
                                            </div>
                                        </div>
                                        <div className="form-group col-md-6">

                                            {/**imagen */}
                                            <div className="form-group col-12 text-center " >
                                                <img src={imagen2.url} style={{ maxHeight: "172px", borderRadius: "10px", backgroundColor: "#282c34", padding: "20px" }} />
                                            </div>
                                            {/**imaegen file*/}
                                            <div className="form-group col-12 ">
                                                <label>Logo Claro:</label>
                                                <input
                                                    id="InputImg2"
                                                    type="file"
                                                    className={
                                                        "form-control " +
                                                        (errors.fileImg2 ? "input-error" : null)
                                                    }
                                                    name="fileImg2"
                                                    accept="image/png, image/jpeg"
                                                    placeholder="fileImg2"
                                                    ref={edit == false ? register({ required: "Campo requerido." }) : register()}
                                                    onChange={() => { onChangeInputImagen2() }}
                                                />
                                                {errors.fileImg2 ? (
                                                    <ErrorForm mensaje={errors.fileImg2.message} />
                                                ) : null}
                                            </div>
                                        </div>
                                        <div className="form-group col-12">
                                            {/**otroDetalle */}
                                            <div className="form-group col-12 ">
                                                <label>¿Otro Detalle?</label>
                                                <input
                                                    type="checkbox"
                                                    id={CheckID}
                                                    className={"form-control"}
                                                    name="otroDetalle"
                                                    checked={detalle}
                                                    onChange={() => setDetalle(!detalle)}
                                                    style={{ width: "20px", display: "inline-block", height: "20px", marginLeft: "10px", marginRight: "20px" }}
                                                />
                                                <label>Es información extra o detallada de algún producto asociado al servicio</label>
                                                {errors.otroDetalle ? (
                                                    <ErrorForm mensaje={errors.otroDetalle.message} />
                                                ) : null}
                                            </div>
                                        </div>
                                        {detalle ?
                                            <>
                                                <div className="form-group col-md-6">
                                                    {/**otro titulo */}
                                                    <div className="form-group col-12">
                                                        <label>Título extra</label>
                                                        <input
                                                            type="text"
                                                            className={
                                                                "form-control " +
                                                                (errors.servicio ? "input-error" : null)
                                                            }
                                                            name="otroTitulo"
                                                            placeholder="Título"
                                                            defaultValue={objeto.otroTitulo}
                                                            ref={register({ required: "Campo requerido." })}

                                                        />
                                                        {errors.otroTitulo ? (
                                                            <ErrorForm mensaje={errors.otroTitulo.message} />
                                                        ) : null}
                                                    </div>

                                                    {/**telefono */}
                                                    <div className="form-group col-12">
                                                        <label>Teléfono:</label>
                                                        <input
                                                            type="text"
                                                            className={
                                                                "form-control " +
                                                                (errors.telefono ? "input-error" : null)
                                                            }
                                                            name="telefono"
                                                            placeholder="Teléfono"
                                                            defaultValue={objeto.telefono}
                                                            ref={register({ required: "Campo requerido." })}

                                                        />
                                                        {errors.telefono ? (
                                                            <ErrorForm mensaje={errors.telefono.message} />
                                                        ) : null}
                                                    </div>
                                                    {/**correo */}
                                                    <div className="form-group col-12">
                                                        <label>Correo:</label>
                                                        <input
                                                            type="text"
                                                            className={
                                                                "form-control " +
                                                                (errors.correo ? "input-error" : null)
                                                            }
                                                            name="correo"
                                                            placeholder="Correo"
                                                            defaultValue={objeto.correo}
                                                            ref={register({ required: "Campo requerido." })}

                                                        />
                                                        {errors.correo ? (
                                                            <ErrorForm mensaje={errors.correo.message} />
                                                        ) : null}
                                                    </div>
                                                </div>
                                                <div className="form-group col-md-6">
                                                    {/**descriopcion*/}
                                                    <div className="form-group col-12">
                                                        <label>Descripción extra: </label>
                                                        <textarea
                                                            className={
                                                                "form-control " +
                                                                (errors.servicio ? "input-error" : null)
                                                            }
                                                            name="otroDescripcion"
                                                            placeholder="Descripción"
                                                            defaultValue={objeto.otroDescripcion}
                                                            ref={register({ required: "Campo requerido." })}
                                                            rows="8"
                                                        />
                                                        {errors.otroDescripcion ? (
                                                            <ErrorForm mensaje={errors.otroDescripcion.message} />
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </> : null}
                                        {/**error */}
                                        <div className="col-md-12 text-center">
                                            {enviado ? (
                                                mensajeError.error ? (
                                                    <div className="alert alert-danger  p-2 animated fadeInUp faster mb-3">
                                                        {mensajeError.mensaje}
                                                    </div>
                                                ) : (
                                                    <div className="alert alert-success p-2 animated fadeInUp faster mb-3">
                                                        Registrado con éxito
                                                    </div>
                                                )
                                            ) : null}
                                        </div>
                                    </div>
                                </fieldset>
                                <div className="modal-footer justify-content-between">
                                    <button
                                        type="button"
                                        onClick={() => closeModal()}
                                        className="btn btn-primary"
                                    >
                                        Cancelar
                                    </button>
                                    {edit ?
                                        <>
                                            <button type="submit" className="btn btn-primary">
                                                Modificar
                                            </button>
                                        </>
                                        :
                                        <>
                                            <button type="submit" className="btn btn-primary">
                                                Agregar
                                            </button>
                                        </>
                                    }
                                </div>
                            </form>
                        </div>
                    </div>
                </Modal>
            }
        </>
    )
}
export default withRouter(AdmServicios);