import React, { useEffect } from "react";
import FilaCarrito from "../FilaCarrito";
import CardTotal from "../Visuales/CardTotal";
import { SweetAlert } from "../../services/ControlActions";
import { UseCarritoState } from "../../hooks/UseCarritoState"
import { useHistory } from "react-router-dom";
const ProductosPago = () => {
  let history = useHistory();
  const { spinnerCarrito, recargarCarrito, carritoData, promocion, impuesto } = UseCarritoState();

  useEffect(() => {//valida que haya carrito
    //console.log("carrito data", carritoData)

    if (!recargarCarrito) {//mientras carga no soltar errores
      if (carritoData != null && carritoData !== '') {//si ya cargó y no encontró carrito
        if (carritoData?.ProductoCarrito.length > 0) {
          //todo bien
        } else {
          SweetAlert(3, "No puede acceder", "No tienes productos ni servicios en el carrito.", "", "Entendido", () => { history.push("/carritoPage"); });
          setTimeout(() => {
            history.push("/carritoPage");
          }, 2000)
        }
      } else {
        SweetAlert(3, "No puede acceder", "No se encontró el carrito, intentalo de nuevo más tarde.", "", "Entendido", () => { history.push("/"); });
        setTimeout(() => {
          history.push("/");
        }, 2000)
      }
    }
  }, [carritoData, recargarCarrito,history]);

  /*useEffect(() => {
    if (getItem("carrito")) {
      if (recargar) {
        showSpinner();
        getPromo(getItem("carrito").idCodigoAplicable)
        setCarritoData(getItem("carrito"));
        setRecargar(false);
        hideSpinner();
      }
    }
  }, [recargar]);



  const getPromo = (idCodigo) => {
    if (idCodigo > 0 && idCodigo != null) {
      //falta la logica para buscar
      showSpinner();
      Retrieve(ListaEndpoints.CODIGO_APLICABLE, "GetCodigoAplicableById",idCodigo)
        .then((res) => {
          // console.log(res);
          if (res) {

            if (res.data) {//si está disponible                
              setPromocion(res.data)
              SwalToast(2, "Promoción Encontrada", () => { }, 3000);

            } else {
              setPromocion(null)
              let err = "Promociones:" + res.error;
              SweetAlert(3, "Lo sentimos", err, "", "Entendido", () => { });

            }

          } else {
            setPromocion(null)
            let err = "Promociones:" + res.error;
            SweetAlert(3, "Acción interrumpida", err, "", "Entendido", () => { });
             //console.log(res);
          }
          hideSpinner();
        })
        .catch((e) => {
          hideSpinner();
          let err =
            "Promociones: No se pudo conectar al servidor, inténtelo de nuevo más tarde. " +
            e.message;
          SweetAlert(3, "Acción Interrumpida", err, "", "Entendido", () => { });
        });

    }
  }*/

  return (
    <>
      {spinnerCarrito}
      <div className="billing-details">
        <h3 className="title">Su Orden</h3>
        {recargarCarrito === false ? (
          carritoData.ProductoCarrito.length === 0 ? (
            <p>Actualmente no cuenta con productos</p>
          ) : (
            <>
              <div className="order-table table-responsive">
                <table className="table table-bordered">
                  <thead>
                    <tr>

                      <th scope="col">Nombre del producto</th>
                      <th scope="col">Correo de la cuenta</th>
                      <th scope="col">Precio</th>
                      <th scope="col">Subtotal</th>

                    </tr>
                  </thead>

                  <tbody>
                    {carritoData.ProductoCarrito.map((productoData) => (
                      <FilaCarrito key={productoData.id} data={productoData}
                        descuento={promocion ? promocion.descuento : 0} impuesto={impuesto} />
                    ))}
                  </tbody>
                </table>
              </div>
              <div className=" mt-3 sinbtn">

                {carritoData.ProductoCarrito.length === 0
                  ? null
                  : <CardTotal data={carritoData} />}

              </div>
            </>
          )
        ) : (
          <p>Cargando</p>
        )}
      </div>
    </>
  );
};

export default ProductosPago;
