import React, { useEffect, useState } from 'react';
import EncabezadoFiguras from '../components/Visuales/EncabezadoFiguras';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { getItem } from '../services/UseLocalStorage';
import { GetWithToken, SweetAlert, SwalToast } from '../services/ControlActions';
import { ListaEndpoints, IMPUESTO_VENTA } from '../config/Config';
import FormatMoney from "../services/FormatMoney";
import Spinner from '../components/Visuales/Spinner';
import moment from 'moment';
import $, { cssNumber } from "jquery";
import 'datatables.net';
import 'datatables.net-bs4';
import AlertMessage from "../components/Errors/AlertMessage";
import { img_default_servicio } from '../images/imagesURL'

const Pedido = (props) => {
    const { history, match } = props;
    const [dataPedido, setDataPedido] = useState({ data: null, cargando: true });
    const [table, setTable] = useState(true);
    const [spinner, showSpinner, hideSpinner] = Spinner();
    const [mensajeAlerta, setMensajeAlerta] = useState({ mensaje: "", error: false });
    const [promocion, setPromocion] = useState(null);
    const IMPUESTO = IMPUESTO_VENTA;
    const ID = match.params.id;
    const TBLID = "tblPedido" + ID;

    useEffect(() => {

        if (!getItem('usuario')) {

            history.goBack() ? history.goBack() : history.push("/")
        } else {
            if (dataPedido.cargando == true) {

                showSpinner()
                let usuario = getItem("usuario");
                GetWithToken(ListaEndpoints.PEDIDO, "GetPedidoById", ID).then((res) => {
                    if (res.data) {

                        if (usuario.uTipo != "tp"&&usuario.uTipo != "sc") {
                            if (res.data.idUsuario + "" == usuario.userId) { //el pedido no es de este usuario  
                                setDataPedido({ data: res, cargando: false });

                            } else {
                                setDataPedido({ data: null, cargando: false });
                                let err = "Pedido: No puedes visualizar este pedido";
                                SweetAlert(3, "Acción Interrumpida", err, "", "Entendido",
                                    () => { history.goBack() ? history.goBack() : history.push("/") })

                            }


                        } else {
                            setDataPedido({ data: res, cargando: false });

                        }
                    } else {
                        setDataPedido({ data: null, cargando: false });
                        let err = "Pedido: " + res.error;
                        SweetAlert(3, "Acción Interrumpida", err, "", "Entendido",
                            () => { history.goBack() ? history.goBack() : history.push("/") })
                    }
                    hideSpinner();
                }).catch((e) => {
                    let err = "Pedidos: No pudo conectar al servidor, inténtelo de nuevo más tarde. " + e.message

                    setMensajeAlerta({ mensaje: err, error: true })
                    setDataPedido({ data: null, cargando: false });
                    hideSpinner();
                });

            } else {
                try {
                    //console.log("tabla", table)
                    if (table) {
                        setTable(false);
                        $("#" + TBLID).DataTable({                       
                            scrollX: true,               
                            processing: true,
                            destroy: true,
                            language: {
                                url: "//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json"
                            },
                            "ordering": true,
                            "info": true,
                            "colReorder": true,
                         //   "searching": false,
                            "bLengthChange": false,
                            dom: 'Blfrtip',
                            buttons: [
                                {
                                    extend: 'collection',
                                    text: 'Exportar <i class="fas fa-list-ol"></i>',
                                    className: "botonesSecundarios",
                                    buttons: [
                                        {
                                            extend: 'csv',
                                            text: 'CSV',
                                            footer: true,
                                            title: 'Listado de Facturas',
                                            filename: 'Listado_Facturas_csv',
                                            exportOptions: {
                                                columns: ':visible'
                                            }
                                        },
                                        {
                                            extend: 'pdfHtml5',
                                            text: 'PDF',
                                            footer: true,
                                            title: 'Listado de Facturas',
                                            filename: 'Listado_Facturas_pdf',
                                            exportOptions: {
                                                columns: ':visible'
                                            }
                                        },
                                        {
                                            extend: 'excel',
                                            text: 'Excel',
                                            footer: true,
                                            title: 'Listado de Facturas',
                                            filename: 'Listado_Facturas_excel',
                                            exportOptions: {
                                                columns: ':visible'
                                            }
                                        },
                                        {
                                            extend: 'copy',
                                            title: 'Listado de Facturas',
                                            text: 'Copiar',
                                            exportOptions: {
                                                columns: ':visible'
                                            }
                                        },
                                        {
                                            extend: 'print',
                                            title: 'Listado de Facturas',
                                            text: 'Imprimir',
                                            exportOptions: {
                                                columns: ':visible'
                                            }
            
                                        }
                                    ],
                                }
                            ],
                            dropup: true,
                            "order": [[0, "desc"]]
                        });

                    }
                } catch (error) {
                    //console.log("error de datatables", error)
                    let err = "-Table: Acción Interrumpida.\n " + error;
                    SweetAlert(3, "Acción Interrumpida", err, "", "Entendido",
                        () => { history.goBack() ? history.goBack() : history.push("/") })

                }

            }

        }

    }, [hideSpinner, history, dataPedido.cargando, showSpinner, table]);





    const irAtras = () => {
        history.goBack() ? history.goBack() : history.push("/")
    }
    const calcularTotal = (data) => {
        let subTotalDescuento = data.precioTotal - (data.precioTotal * data.descuento);
        return subTotalDescuento + (subTotalDescuento * IMPUESTO_VENTA);
    }
    const calcularDescuento = (data) => {

        return (data.precioTotal * data.descuento);
    }
    const calcularImpuesto = (data) => {
        let subTotalDescuento = data.precioTotal - (data.precioTotal * data.descuento);
        return (subTotalDescuento * IMPUESTO_VENTA);
    }
    return (
        <>
            {mensajeAlerta.error ? (
                <>
                    <AlertMessage
                        title={""}
                        message={mensajeAlerta.mensaje}
                        tipo={"alert-danger"}
                    />
                </>
            ) : null}
            <EncabezadoFiguras titulo="Detalle del pedido" />
            {spinner}
            {dataPedido.data === null ? (


                <section className="cart-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 col-md-12">
                                <div className="about-content">
                                    <div className="section-title">
                                        <h2>No hay nada que mostrar
                                            <button onClick={() => { irAtras() }} className="btn btn-primary float-right">
                                                Volver
                                            </button></h2>

                                        <div className="bar"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            ) : (
                <section className="cart-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 col-md-12">
                                <div className="about-content">
                                    <div className="section-title">
                                        <h2>Detalle del pedido
                                            <button onClick={() => { irAtras() }} className="btn btn-primary float-right">
                                                Volver
                                            </button>
                                        </h2>

                                        <div className="bar"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-lg-12 col-md-12">
                                <form>
                                    <div className="cart-table table-responsive carritoCompras">
                                        <table width="100%" id={"TblCuerpo"} className="table table-bordered  ">
                                            <tbody>
                                                {dataPedido.cargando === false ? (

                                                    dataPedido.data === null  /*dataPedido.data.data.DetallePedido.length === 0 ? */ ?
                                                        (null) : (
                                                            <>
                                                                <tr >
                                                                    <td className="product-thumbnail"> Cliente  </td>
                                                                    <td className="product-name">
                                                                        {dataPedido.data.data.nombre}
                                                                    </td>
                                                                </tr>
                                                                <tr >
                                                                    <td className="product-thumbnail"> Teléfono  </td>
                                                                    <td className="product-name">
                                                                        {dataPedido.data.data.telefono}
                                                                    </td>
                                                                </tr>
                                                                <tr >
                                                                    <td className="product-thumbnail"> Fecha de registro </td>
                                                                    <td className="product-name">
                                                                        {dataPedido.data.data.fecha ?
                                                                            moment(dataPedido.data.data.fecha).format('YYYY/MM/DD, hh:mm a') : null}
                                                                    </td>
                                                                </tr>
                                                                <tr >
                                                                    <td className="product-thumbnail"> Pais  </td>
                                                                    <td className="product-name">
                                                                        {dataPedido.data.data.pais_nombre}
                                                                    </td>
                                                                </tr>
                                                                <tr >
                                                                    <td className="product-thumbnail">   Comprobante  </td>
                                                                    <td className="product-name">
                                                                        {dataPedido.data.data.referenciaFacel}
                                                                    </td>
                                                                </tr>
                                                                <tr >
                                                                    <td className="product-thumbnail">  Estado </td>
                                                                    <td className="product-name"> {dataPedido.data.data.estadoString}
                                                                    </td>
                                                                </tr>
                                                                <tr className="barraSeparadora">

                                                                </tr>
                                                                {dataPedido.data.data.idCodigoAplicable > 0 ? <tr >
                                                                    <td className="product-thumbnail"> {"Descuento (" + dataPedido.data.data.descuento * 100 + "%)"}</td>
                                                                    <td className="product-name"> {FormatMoney(calcularDescuento(dataPedido.data.data))}
                                                                    </td>
                                                                </tr> : null}
                                                                <tr >
                                                                    <td className="product-thumbnail"> {"Impuesto (" + IMPUESTO * 100 + "%)"}</td>
                                                                    <td className="product-name"> {FormatMoney(calcularImpuesto(dataPedido.data.data))}
                                                                    </td>
                                                                </tr>
                                                                <tr >
                                                                    <td className="product-thumbnail"> {"Total "}</td>
                                                                    <td className="product-name"> {FormatMoney(calcularTotal(dataPedido.data.data))}
                                                                    </td>
                                                                </tr>
                                                            </>
                                                        )
                                                ) : (
                                                    <h6>cargando</h6>
                                                )
                                                }

                                            </tbody>
                                        </table>
                                    </div>

                                </form>

                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-lg-12 col-md-12">

                                <form>

                                    <div className="cart-table table-responsive carritoCompras">
                                        <table width="100%" id={TBLID} className="table table-bordered  ">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Servicio</th>
                                                    <th scope="col">Producto</th>
                                                    <th scope="col">Cuenta</th>
                                                    <th scope="col">Precio Unidad</th>

                                                </tr>
                                            </thead>
                                            <tbody>

                                                {dataPedido.cargando === false ? (

                                                    dataPedido.data === null  /*dataPedido.data.data.DetallePedido.length === 0 ? */ ?
                                                        (null) : (
                                                            dataPedido.data.data.DetallePedido.map(pdato =>

                                                                <tr key={pdato.id}>
                                                                    <td className="product-thumbnail">

                                                                        <Link to="/servicios">
                                                                            {pdato.Producto.urlImagen ? <img src={pdato.Producto.urlImagen } alt="item" />
                                                                                : <img src={img_default_servicio} alt="item" style={{ width: 50 }} />}
                                                                        </Link>
                                                                    </td>

                                                                    <td className="product-name">
                                                                        {pdato.nombreProducto}
                                                                    </td>
                                                                    <td className="product-name">
                                                                        {pdato.correoCuenta!= "" ? pdato.correoCuenta: "Sin cuenta aplicada"}
                                                                    </td>
                                                                    <td className="product-price text-right">
                                                                        <span className="unit-amount">
                                                                            {FormatMoney(pdato.precioUnidad)}
                                                                        </span>
                                                                    </td>

                                                                </tr>

                                                            )
                                                        )
                                                ) : (
                                                    <h6>cargando</h6>
                                                )
                                                }

                                            </tbody>
                                        </table>
                                    </div>

                                </form>

                            </div>
                        </div>
                    </div>
                </section>
            )}
        </>
    );
};

export default withRouter(Pedido);
