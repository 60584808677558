import React from "react";
import EncabezadoDescripcion from './EncabezadoDescripcion';
import CardProducto from "./CardProducto.js"
const ServicioTipoCarrito = ({ cargando, datos }) => {

    let { objetosList } = datos;
    return (
        <>
            {cargando ? (
                <section className="pricing-area ">
                    <div className="container">
                        <div className="section-title">
                            <h2>Servicios</h2>
                            <div className="bar"></div>
                            <p>Lista de servicios</p>
                        </div>
                        <h1 className="text-center">Cargando...</h1>
                    </div>
                </section>
            ) : (
                    <>
                        <section className="pricing-area ">
                            <EncabezadoDescripcion
                                id={datos.id}
                                titulo={datos.titulo}
                                descripcion={datos.descripcion}
                            />
                            <div className="col-lg-12 ">
                                <div className="col-lg-6 m-auto">
                                    <img className="imagen" src={datos.urlImagenBlanca} alt="url" />
                                </div>
                            </div>
                            <div className="container">
                               

                                <div className="tab pricing-tab bg-color">
                                    <div className="tab_content pb-5 pt-5">
                                        <div className="tabs_item">
                                            <div className="row">

                                                {objetosList.map(cardDato => (
                                                    <CardProducto key={cardDato.id} dato={cardDato} />
                                                ))}

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* */}
                            </div>
                        </section>
                        { datos.otroDetalle === 1 ? (<section className="about-area ptb-80">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12 col-md-12">
                                        <div className="about-content">
                                            <div className="section-title">
                                                <h2>{datos.otroTitulo}</h2>
                                                <div className="bar"></div>
                                                <p>
                                                    {datos.otroDescripcion}
                                                </p>
                                                <p>
                                                    Más información
                                        </p>
                                                <p>
                                                    Telefono: {" " + datos.telefono}
                                                </p>
                                                <p>
                                                    Correo: {" " + datos.correo}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </section>
                        ) : null}
                    </>
                )}
        </>
    )

};

export default ServicioTipoCarrito;
