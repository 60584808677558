import React, { useState, useEffect } from 'react';
import FormatMoney from '../../services/FormatMoney';
import { withRouter } from 'react-router-dom';
import { PostWithToken } from "../../services/ControlActions";
import { ListaEndpoints } from "../../config/Config";

const CardTotal = (props) => {
    const { history } = props;
    let { data } = props;
    const [monto, setMonto] = useState({
        total: 0.0,
        impuesto: 0.0,
        subTotal: 0.0,
        descuento: 0.0,
        totalDecuento: 0.0,
        totalImpuesto: 0.0
    });
    const [mensaje, setMensaje] = useState("");

    const IrPagoPage = () => {
        // console.log(history);
        history.push("/pago");
    };
    const calcularMonto = () => {

        PostWithToken(ListaEndpoints.ACCION, "CalcularMonto", "", data).then(
            (res) => {
                //console.log("res", res)
                if (res.ok) {
                    setMonto(res.data)
                } else {
                    setMensaje(res.error)
                }
            },
            (error) => {
                // console.log(error)
                setMensaje("no se pudo calcular")
            }
        );
    }
    useEffect(() => {
        // console.log("entró",data)
        calcularMonto();
    }, [props]);
    return (
        <div className="cart-totals ">
            <h3>Cargo Total</h3>

            {(mensaje === null || mensaje === "") && monto != null ? <ul>
                <li>
                    Subtotal <span>{FormatMoney(monto.subTotal)}</span>
                </li>
                {monto.totalDecuento > 0 ? 
                <li > Descuento ({monto.descuento * 100 + "%"})
                <span className="verde">-  {FormatMoney(monto.totalDecuento)}</span>
                </li> : null}
                <li>
                    I.V.A ({monto.impuesto * 100 + "%"})
                    <span className="">+ {FormatMoney(monto.totalImpuesto)}</span>
                </li>
                <li>
                    Total{" "}
                    <span>
                        <b>{FormatMoney(monto.total)}</b>
                    </span>
                </li>

            </ul> : <p>{mensaje}</p>}

            <button
                type="button"
                onClick={() => IrPagoPage()}
                className="btn btn-primary"
                disabled={mensaje != null && mensaje !== "" && monto == null}
            >
                Pagar
            </button>
        </div>
    );
};
export default withRouter(CardTotal);