import React, { useState } from "react";
import { useForm } from "react-hook-form";
import ErrorForm from "./ErrorForm";
import { Create, Retrieve, SwalToast, SweetAlert } from "../../services/ControlActions";
import { withRouter } from "react-router-dom";
import { setItem } from "../../services/UseLocalStorage";
import Spinner from '../Visuales/Spinner';

const FormLogin = (props) => {
  const { history, accion } = props;
  const { register, handleSubmit, errors, reset } = useForm();
  const [cargando, setCargando] = useState(false);
  const [enviado, setEnviado] = useState(false);
  const [spinner, showSpinner, hideSpinner] = Spinner();

  const [mensajeError, setMensajeError] = useState({
    error: false,
    mensaje: "",
  });

  const onSubmit = (data) => {
    consultarAPI(data);
  };

  const consultarAPI = async (data) => {
    showSpinner();
    setEnviado(false);
    setCargando(true);
    localStorage.clear("usuario");
    localStorage.clear("carrito");
      Create("usuario", "login", "", data).then((res) => {
          //console.log(res);
      if (res.data) {
        Retrieve("carrito", "GetCarritoByUserId", res.data.userId).then((resC) => {
         // console.log(resC)
          if (resC.data != null) {
            setEnviado(true);
            setCargando(false);

            //ninguno de estos datos se deben guardar
           /* if (resC.data.CodigoAplicable != null) {
              resC.data.CodigoAplicable.Pedido = null;
              resC.data.CodigoAplicable.Carrito = null;
              resC.data.CodigoAplicable.RegistroCodigoAplicable = null;
            }*/


            setItem("usuario", res.data);
            setItem("carrito", resC.data);
            reset();
            hideSpinner();
            SwalToast(2, "Bienvenido", () => { history.push("/"); }, 1000);
            setMensajeError({ error: false, mensaje: "" });

          } if (resC.Message) {
            hideSpinner();
            setEnviado(true);
            setCargando(false);
            setMensajeError({ error: true, mensaje: resC.Message });
          } else {

            hideSpinner(); 
            setCargando(false);
            setEnviado(true);
            if (resC.error) {
              setMensajeError({ error: true, mensaje: resC.error });
              console.error(resC.error, resC.errorDescription)
            }
          }

        }).catch((e) => {
          //console.log("error login", e)
          hideSpinner();
          setEnviado(true);
          setCargando(false);
          setMensajeError({ error: true, mensaje: e.message });
          let err =
            "Login: No pudo conectar al servidor, inténtelo de nuevo más tarde. " +
            e.message;
          SweetAlert(3, "Acción Interrumpida", err, "", "Entendido", () => { });
        });

      } else {
        hideSpinner();
        setCargando(false);
          setEnviado(true);
          
        if (res.error) {
          setMensajeError({ error: true, mensaje: res.error });
          console.error(res.error, res.errorDescription)
        }

      }
    },
      (error) => {
        hideSpinner();
        setEnviado(true);
        setCargando(false);
        if (error.message == "Failed to fetch") {
          setMensajeError({ error: true, mensaje: "No se pudo conectar, inténtelo de nuevo más tarde." });
        } else {
          setMensajeError({ error: true, mensaje: error.message });
        }
        // console.log("hola", error)
        // console.error("Acción Interrumpida:", error.message)

      }
    );
  };

  return (
    <>
      <section>
        {spinner}
        <div className="container-fluid  justify-content-center align-items-center mt-2 mb-5">
          <div className="row h-100 justify-content-center align-items-center">
            <form onSubmit={handleSubmit(onSubmit)} className="col-md-12">
              <fieldset>
                <div className="form-row">
                  <div className="form-group col-md-12">
                    <label>Correo Electrónico:</label>
                    <input
                      type="text"
                      className={
                        "form-control " +
                        (errors.usuario ? "input-error" : null)
                      }
                      name="username"
                      placeholder="Correo electrónico"
                      ref={register({ required: "Campo requerido." })}
                    />

                    {errors.username ? (
                      <ErrorForm mensaje={errors.username.message} />
                    ) : null}
                  </div>

                  <div className="form-group col-md-12">
                    <label>Contraseña: </label>

                    <input
                      type="password"
                      className={
                        "form-control " +
                        (errors.usuario ? "input-error" : null)
                      }
                      name="password"
                      placeholder="Contraseña"
                      ref={register({ required: "Campo requerido." })}
                    />

                    {errors.password ? (
                      <ErrorForm mensaje={errors.password.message} />
                    ) : null}
                  </div>
                  <div className="form-group col-md-12">
                    <label className="link-simple" onClick={accion} >¿Olvidó su contraseña?</label>
                  </div>
                  <div className="col-md-12 text-center">
                    {enviado ? (
                      mensajeError.error ? (
                        <div className="alert alert-danger  p-2 animated fadeInUp faster mb-3">
                          {mensajeError.mensaje}
                        </div>
                      ) : (
                        <div className="alert alert-success p-2 animated fadeInUp faster mb-3">
                          Bienvenido
                        </div>
                      )
                    ) : null}
                    <button
                      type="submit"
                      className="d-inline btn btn-primary "
                      disabled={cargando}
                    >
                      Enviar
                    </button>
                  </div>
                </div>
              </fieldset>
            </form>
          </div>
        </div>
      </section>

      <div className="bg-info">
        <div className="container">
          <div className="row"></div>
        </div>
      </div>
    </>
  );
};

export default withRouter(FormLogin);
