import React from "react";
//import Whatsapp from "../../images/icon_whatsapp.svg"
import {
  MapPin,
  Mail,
  PhoneCall,
  Facebook,
  Instagram,
  Linkedin

} from "react-feather";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
const Footer = () => {

 
  return (
    <footer className="footer-area">
      <div className="container">
        <div className="row">
          <div className="col-lg-3 col-md-6 col-sm-6">
            <div className="single-footer-widget">
              <div className="logo">
                <HashLink
                  smooth
                  to="/#carousel"

                >
                    <img src="https://res.cloudinary.com/du4k7xyix/image/upload/v1703712865/Sigma/Logos/Sigma_Logo_j92a1y.png" alt="logo" />
                </HashLink>
              </div>
              <p className="whiteSigma h6">
                Horario de atención:
                </p>
              <p className="whiteSigma">
                Lunes a Viernes <br /> 8:00 am a 5:00 pm
              </p>

            </div>
          </div>

          <div className="col-lg-3 col-md-6 col-sm-6">
            <div className="single-footer-widget pl-5">
              <h3>Empresa</h3>
              <ul className="list">
                <li>
                  <Link to="/nosotros">
                    Nosotros
                </Link>
                </li>
                <li>
                  <Link to="/servicios">
                    Servicios
                </Link>
                </li>
                <li>
                  <Link to="/Distribuidores">
                    Distribuidores
                </Link>
                </li>
                <li>
                  <HashLink
                    smooth
                    to="/#carousel"

                  >
                    Publicidad
                  </HashLink>
                </li>
              </ul>
            </div>
          </div>

          <div className="col-lg-3 col-md-6 col-sm-6">
            <div className="single-footer-widget">
              <h3>Soporte</h3>
              <ul className="list">
                {/*<li>
                  <Link to="/contacto">Preguntas frecuentes</Link>
                </li>*/}
                <li>
                  <Link to="/privacidad">Políticas de privacidad</Link>
                </li>
                <li>
                  <Link to="/terminos">Términos y condiciones</Link>
                </li>

                <li>
                  <Link to="/contacto">Contáctenos</Link>
                </li>
              </ul>
            </div>
          </div>

          <div className="col-lg-3 col-md-6 col-sm-6">
            <div className="single-footer-widget">
              <h3>Ubicación</h3>

              <ul className="footer-contact-info">
                <li>
                  <MapPin />
                  San Pedro de Montes de Oca, Barrio la Granja, de la
                  embajada de Panamá 25m al este.
                </li>
                <li>
                  <Mail /> Email: <a href="mailto:info@sigmacr.net">info@sigmacr.net</a>
                </li>
                <li>
                  <PhoneCall /> Teléfono:{" "}<a href="tel:+50622917170">+(506) 2291 7170</a>
                </li>
              </ul>
              <ul className="social-links">
                <li>
                  <Link to="/facebook"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="facebook mr-1">
                    <Facebook />
                  </Link>
                </li>
                <li>
                  <Link to="/instagram"
                    target="_blank"
                    rel="noopener noreferrer" className="instagram mr-1">
                    <Instagram />
                  </Link>
                </li>
                <li>
                  <Link to="/linkedin"
                    target="_blank"
                    rel="noopener noreferrer" className="linkedin mr-1">
                    <Linkedin />
                  </Link>
                </li>
                <li>
                  <Link to="/whatsapp"
                    target="_blank"
                    rel="noopener noreferrer" className="linkedin mr-1">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                      <path id="whatsapp_icon" d="M.057 24l1.687-6.163c-1.041-1.804-1.588-3.849-1.587-5.946.003-6.556 5.338-11.891 11.893-11.891 3.181.001 6.167 1.24 8.413 3.488 2.245 2.248 3.481 5.236 3.48 8.414-.003 6.557-5.338 11.892-11.893 11.892-1.99-.001-3.951-.5-5.688-1.448l-6.305 1.654zm6.597-3.807c1.676.995 3.276 1.591 5.392 1.592 5.448 0 9.886-4.434 9.889-9.885.002-5.462-4.415-9.89-9.881-9.892-5.452 0-9.887 4.434-9.889 9.884-.001 2.225.651 3.891 1.746 5.634l-.999 3.648 3.742-.981zm11.387-5.464c-.074-.124-.272-.198-.57-.347-.297-.149-1.758-.868-2.031-.967-.272-.099-.47-.149-.669.149-.198.297-.768.967-.941 1.165-.173.198-.347.223-.644.074-.297-.149-1.255-.462-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.297-.347.446-.521.151-.172.2-.296.3-.495.099-.198.05-.372-.025-.521-.075-.148-.669-1.611-.916-2.206-.242-.579-.487-.501-.669-.51l-.57-.01c-.198 0-.52.074-.792.372s-1.04 1.016-1.04 2.479 1.065 2.876 1.213 3.074c.149.198 2.095 3.2 5.076 4.487.709.306 1.263.489 1.694.626.712.226 1.36.194 1.872.118.571-.085 1.758-.719 2.006-1.413.248-.695.248-1.29.173-1.414z" /></svg>
                  </Link>
                </li>
              </ul>
            </div>
          </div>

          <div className="col-lg-12 col-md-12">
            <div className="copyright-area">
              <p className="whiteSigma">Copyright @2024 Corporación Millenium. Todos los derechos reservados.</p>
            </div>
          </div>
        </div>
      </div>

    </footer>
  );
};
export default Footer;
