import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Trash2, Edit } from "react-feather";
import ErrorForm from "./../Formularios/ErrorForm.js";
import {
    Create,
    Delete,
    SwalToast,
    SweetAlert,
} from "../../services/ControlActions";
import Modal from "react-modal";
import {
    ListaEndpoints, 
    REGEX_VALIDACION_EMAIL,
    ERROR_REGEX_EMAIL,  MODAL_STYLES
} from "../../config/Config";

import { setItem } from "../../services/UseLocalStorage";
import FormatMoney from "../../services/FormatMoney";
import Spinner from "./Spinner";
import IconPromo from "./IconPromo";
import { Link } from "react-router-dom";
import { img_default_servicio } from '../../images/imagesURL'

const CardProductoCarrito = ({ data, descuento }) => {
    const [modalOpen, setModalOpen] = useState(false);
    const { register, handleSubmit, errors } = useForm();
    const [spinner, showSpinner, hideSpinner] = Spinner();
    const productoData = data;


    //para modificar la cantidad
    const modificarProducto = (pda) => {

        if (document.getElementById("btnModificar" + pda.id).disabled === false) {
            showSpinner();
            document.getElementById("btnModificar" + pda.id).disabled = true;
            let input = document.querySelector("#npt" + pda.id);
            let valor = input.value;



            let data = {
                id: pda.id,
                correoCuenta: valor,//valor del input
                idProducto: pda.idProducto,
                idPadre: pda.idPadre,
                idCarrito: pda.idCarrito,
                impuesto: pda.impuesto,
                observacion: pda.observacion,
                precio: pda.precio,
                cantidad: pda.cantidad,
                descuento: pda.descuento,
                idCuenta: 1

            }

            // console.log("dato", pda);
            Create(ListaEndpoints.CARRITO, "UpdateProductoCarrito", "", data)
                .then((res) => {
                    if (res.data) {
                        setItem("carrito", res.data);
                        setModalOpen(false);

                        SwalToast(2, "Carrito de compras actualizado", () => { }, 3000);
                    } else {
                        let err = "Modificar producto: " + res.error;
                        SweetAlert(3, "Acción Interrumpida", err, "", "Entendido", () => { });
                        console.error(res);
                    }
                    hideSpinner();
                    document.getElementById("btnModificar" + pda.id).disabled = false;
                })
                .catch((e) => {
                    hideSpinner();
                    let err =
                        "Modificar producto: No pudo conectar al servidor, inténtelo de nuevo más tarde. " +
                        e.message;
                    SweetAlert(3, "Acción Interrumpida", err, "", "Entendido", () => { });
                    document.getElementById("btnModificar" + pda.id).disabled = false;
                });
        }
    };

    const eliminarProducto = (pda) => {
        if (document.getElementById("btnEliminar" + pda.id).disabled === false) {
            showSpinner();
            document.getElementById("btnEliminar" + pda.id).disabled = true;

            Delete(ListaEndpoints.CARRITO, "DeleteProductoCarrito", "", pda)
                .then((res) => {
                    if (res.data) {
                        setItem("carrito", res.data);
                        SwalToast(2, "Carrito de compras actualizado", () => { }, 3000);
                    } else {
                        let err = "Eliminar producto: Error: " + res.error;
                        SweetAlert(3, "Acción Interrumpida", err, "", "Entendido", () => { });
                        console.error("error:" + err);
                    }
                    hideSpinner();
                })
                .catch((e) => {
                    hideSpinner();
                    let err =
                        "Eliminar producto: No pudo conectar al servidor, inténtelo de nuevo más tarde. " +
                        e.message;
                    SweetAlert(3, "Acción Interrumpida", err, "", "Entendido", () => { });
                });
        }
    };
    const showModal = (pdatos) => {
        setModalOpen(true)
        setTimeout(() => {
            let input = document.getElementById("npt" + pdatos.id);
          //  console.log(input)
            input.value = pdatos.correoCuenta;
        }, 500);

    }; //fin modalModificar

    const modalModificar = (pdatos) => {

        let titulo = <h4>¿Desea cambiar la cuenta a aplicar?</h4>;
        let contenido = (
            <>
                <div className="form-row">
                    <div className="form-group col-12">
                        <label>Correo Electrónico</label>

                        <input
                            id={"npt" + pdatos.id}
                            type="text"
                            className={
                                "form-control " + (errors.email ? "input-error" : null)
                            }
                            name="email"
                            placeholder="Correo electrónico"
                            ref={register({

                                pattern: {
                                    value: REGEX_VALIDACION_EMAIL,
                                    message: ERROR_REGEX_EMAIL,
                                },
                            })}
                        />
                        {errors.email ? (
                            <ErrorForm mensaje={errors.email.message} />
                        ) : null}
                    </div>
                </div>
            </>
        );
        let botones = (
            <>
                <button
                    type="button"
                    id={"btnModificar" + pdatos.id}
                    onClick={handleSubmit(() => modificarProducto(pdatos))}
                    className="btn btn-primary "
                >
                    Modificar
                </button>
                <button
                    type="button"
                    onClick={() => setModalOpen(false)}
                    className="btn btn-primary "
                >
                    {" "}
                    Cancelar{" "}
                </button>
            </>
        );

        let style = MODAL_STYLES;
        style.content.maxWidth = "50%"

        return (
            <Modal
                isOpen={modalOpen}
                shouldCloseOnOverlayClick={true}
                onRequestClose={() => setModalOpen(false)}
                style={style}
                className="modal-dialog"
            >
                <div className="modal-content">
                    <div className="modal-header">
                        <div className="modal-title">{titulo}</div>
                        <button
                            type="button"
                            onClick={() => setModalOpen(false)}
                            className="close"
                        >
                            &times;
                        </button>
                    </div>
                    <div className="modal-body">{contenido}</div>
                    <div className="modal-footer">{botones}</div>
                </div>
            </Modal>
        );
    }; //fin modalModificar
    let subTotal = productoData.precio /** productoData.cantidad*/;
    let montoDescuento = subTotal * descuento;
    let SubTotalDescuento = (subTotal - montoDescuento);
  //  let total = (SubTotalDescuento * impuesto);
    return (
        <>
            {modalModificar(productoData)}
            {spinner}
            <div className="promoIcon">
                {descuento > 0 ? <IconPromo word={`-${descuento * 100}%`} alto={"60px"} ancho={"60px"} /> : null}

            </div>
            <div className=" row cardProductoCarrito ">

                <div className="col-md-3  " key={productoData.id}>
                    <div className="products-details-image  " style={{
                        height: "100%", display: "flex",
                        alignItems: "center"
                    }}>
                        <Link to="/servicios" >
                            {productoData.urlLogoServicio ? <img src={productoData.urlLogoServicio} alt="item" />
                                : <img src={img_default_servicio} alt="item" />}
                        </Link>
                    </div>
                </div>
                <div className="col-md-8">
                    <div className="products-details mt-3">


                        <div className="product-name">
                            <Link to="/servicios" >{productoData.nombrePro}</Link>
                        </div>

                        <div className="price">
                            {descuento > 0 ? <span className="tachado"> {FormatMoney(subTotal)}</span> : null}  {FormatMoney(SubTotalDescuento)}

                        </div>
                        <p>{productoData.descripcionPro}</p>
                        <span className="cuenta" onClick={() => showModal(productoData)}  >Cuenta: {productoData.correoCuenta !== "" ? productoData.correoCuenta : "Sin cuenta a aplicar (Agregar)"}</span>

                        <div className="availability">
                            Disponibilidad: <span>En Stock</span>
                        </div>



                    </div>
                </div>
                <div className="col-md-1 p-0">
                    <div className="mt-3">
                        <button
                            type="button"
                            className="btnIconCarrito"
                            id={"btnEliminar" + productoData.id}
                            onClick={() => eliminarProducto(productoData)}
                        >
                            <Trash2 />
                        </button>
                        <button
                            type="button"
                            className="btnIconCarrito"
                            id={"btnModificar" + productoData.id}
                            onClick={() => showModal(productoData)}
                        >
                            <Edit />
                        </button>

                    </div>
                </div>
            </div>
        </>
    );
};

export default CardProductoCarrito;
