import React, { useState } from "react";
import { useForm } from "react-hook-form";
import ErrorForm from "./ErrorForm";
import { Create, SweetAlert } from "../../services/ControlActions";
import Spinner from "../Visuales/Spinner";
import {
  ListaEndpoints,
  ERROR_REQUERIDO,
  REGEX_VALIDACION_EMAIL,
  ERROR_REGEX_EMAIL,
} from "../../config/Config";

const FormSolicitudInfo = ({ titulo, asunto }) => {
  const { register, handleSubmit, errors, reset } = useForm();

  const [cargando, setCargando] = useState(false);
  const [enviado, setEnviado] = useState(false);
  const [spinner, showSpinner, hideSpinner] = Spinner();

  const onSubmit = (data) => {
    consultarAPI(data);
  };

  const consultarAPI = async (data) => {
    showSpinner();

    setEnviado(false);
    setCargando(true);

    let cData = {};
    cData.correo = data.email;
    cData.contenido = data.mensaje;
    cData.nombre = data.nombre;
    cData.telefono = data.telefono;
    cData.asunto = asunto;

    Create(ListaEndpoints.CORREO, "EnviarConsultaUsuario", "", cData)
      .then((res) => {
        if (res.data) {

          setCargando(false);
          setEnviado(true);
          reset();
        } else {
          let err = "Consulta: " + res.error;
          SweetAlert(
            3,
            "Acción Interrumpida, Intentelo de nuevo más tarde",
            err,
            "",
            "Entendido",
            () => { }
          );
        }
        hideSpinner();
      })
      .catch((e) => {
        hideSpinner();
        let err =
          "EnviarConsultaUsuario: No pudo conectar al servidor, inténtelo de nuevo más tarde." +
          e.message;
        SweetAlert(3, "Acción Interrumpida", err, "", "Entendido", () => { });
      });
  };

  return (
    <>
    {spinner}
      <section>
        <div className="container-fluid h-100 justify-content-center align-items-center my-5">
          <div className="row h-100 justify-content-center align-items-center">
            <form onSubmit={handleSubmit(onSubmit)} className="col-md-12">
              <fieldset>
                {titulo ? <h2 className="mb-5">{titulo} </h2> : null}

                <div className="form-group">
                  <label>Nombre</label>

                  <input
                    type="text"
                    className={
                      "form-control " + (errors.nombre ? "input-error" : null)
                    }
                    name="nombre"
                    placeholder="Nombre"
                    ref={register({ required: "Campo requerido." })}
                  />

                  {errors.nombre ? (
                    <ErrorForm mensaje={errors.nombre.message} />
                  ) : null}
                </div>

                <div className="form-row">
                  <div className="form-group col-md-7">
                    <label>Correo Electrónico</label>

                    <input
                      type="text"
                      className={
                        "form-control " + (errors.email ? "input-error" : null)
                      }
                      name="email"
                      placeholder="Correo electrónico"
                      ref={register({
                        required: ERROR_REQUERIDO,
                        pattern: {
                          value: REGEX_VALIDACION_EMAIL,
                          message: ERROR_REGEX_EMAIL,
                        },
                      })}
                    />

                    {errors.email ? (
                      <ErrorForm mensaje={errors.email.message} />
                    ) : null}
                  </div>

                  <div className="form-group col-md-5">
                    <label>Teléfono</label>

                    <input
                      type="tel"
                      className={
                        "form-control " +
                        (errors.telefono ? "input-error" : null)
                      }
                      name="telefono"
                      placeholder="(506) 8888-8888"
                      ref={register({ required: "Campo requerido." })}
                    />

                    {errors.telefono ? (
                      <ErrorForm mensaje={errors.telefono.message} />
                    ) : null}
                  </div>
                </div>

                <div className="form-group">
                  <label>Consulta</label>

                  <textarea
                    type="text"
                    className={
                      "form-control " + (errors.mensaje ? "input-error" : null)
                    }
                    name="mensaje"
                    placeholder="Ingrese su consulta."
                    ref={register({ required: "Campo requerido." })}
                    rows="4"
                  />

                  {errors.mensaje ? (
                    <ErrorForm mensaje={errors.mensaje.message} />
                  ) : null}
                </div>

                {enviado ? (
                  <p className="alert alert-success text-center p-2 animated fadeInUp faster">
                    Formulario enviado con éxito.En breve un compañero de sigma lo estará contactando
                  </p>
                ) : null}

                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={cargando}
                >
                  Enviar
                </button>
              </fieldset>
            </form>
          </div>
        </div>
      </section>

      <div className="bg-info">
        <div className="container">
          <div className="row"></div>
        </div>
      </div>
    </>
  );
};

export default FormSolicitudInfo;
