import React from "react";
import { GoogleMap, withScriptjs, withGoogleMap, Marker } from "react-google-maps";

const MapaGoogle = (props) => {
  let lati = parseFloat(props.latitud);
  let longi = parseFloat(props.longitud);

  return (
    <GoogleMap defaultZoom={18} defaultCenter={{ lat: lati, lng: longi }}>
      {props.isMarkerShown && <Marker icon={"https://res.cloudinary.com/du4k7xyix/image/upload/v1590809258/Sigma/Logos/icono_2_j0a04r_aujcgx.png"} position={{ lat: lati, lng: longi }}/>}
    </GoogleMap>
  )


};
export default withScriptjs(withGoogleMap(MapaGoogle));
