import React, { useState } from "react";
import { ArrowLeft, ArrowRight } from "react-feather";
import Slider from "react-slick";

const FlechaIzquierda = (props) => {
    const { onClick } = props;
    return (
        <button onClick={onClick} className="prev-arrow slick-arrow">
            <ArrowLeft />
        </button>
    );
}

const FlechaDerecha = (props) => {
    const { onClick } = props;
    return (
        <button onClick={onClick} className="next-arrow slick-arrow">
            <ArrowRight />
        </button>
    );
}

const CarouselSlick = (props) => {

    const [slick1, setSlick1] = useState(null);
    const [slick2, setSlick2] = useState(null);
    const [diapActiva, setDiapActiva] = useState(0)
    const setContadorSlick = (current) => {
        setDiapActiva(current)
    }
    let show = 5;
    // Validacion para que muestre correctamente el slider, dependiendo de la cantidad de datos disponibles

    if (props.datos.length >= 6) {
        show = 5;
    } else if (props.datos.length >= 3) {

        show = 3;
    } else {
        show = props.datos.length
    }
    const settingsImages = {
        speed: 300,
        slidesToShow: 1,
        slidesToScroll: 1,
        fade: true,
        draggable: true,
        asNavFor: slick2,
        arrows: false,
        adaptiveHeight:true

    };
    const settingsThumbnails = {
        speed: 300,
        slidesToShow: show,
        slidesToScroll: 1,
        infinite: true,
        autoplay: true,
        centerMode: true,
        draggable: false,
        focusOnSelect: true,
        asNavFor: slick1,
        prevArrow: <FlechaIzquierda />,
        nextArrow: <FlechaDerecha />
    };

    return (
        <>
            <div>
                <section className="feedback-area ptb-80 bg-f7fafd">
                    <div className="container">
                        <div className="feedback-slides">
                            <div className="client-feedback">
                                <Slider
                                    {...settingsImages}
                                    ref={slider => (setSlick1(slider))}
                                    afterChange={current => setContadorSlick(current)}
                                >
                                    {props.datos.map((cliente, idx) => {
                                        return (
                                            <div className="item" key={cliente.id}>
                                                <div className="single-feedback">
                                                    <div className="client-img">
                                                        <img
                                                            src={cliente.urlImagen}
                                                            alt="image3"
                                                        />
                                                    </div>

                                                    <h3>{cliente.nombre}</h3>
                                                    <span>{cliente.titulo}</span>
                                                    <p>{cliente.comentario}</p>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </Slider>
                            </div>

                            <div className="client-thumbnails">
                                <Slider
                                    {...settingsThumbnails}
                                    ref={slider => (setSlick2(slider))}
                                >
                                    {props.datos.map((cliente, idx) => {
                                        return (
                                            <div
                                                key={cliente.id}
                                                // Condicional que evalua aplicar estilo css si es un componente que está en el centro del slick
                                                className={
                                                    "item " +
                                                    (diapActiva === idx
                                                        ? "slick-center"
                                                        : "")
                                                }
                                            >
                                                <div className="img-fill">
                                                    <img
                                                        src={cliente.urlImagen}
                                                        alt={cliente.nombre}
                                                    />
                                                </div>
                                            </div>
                                        );
                                    })}
                                </Slider>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    );
}
export default CarouselSlick;