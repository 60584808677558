import React, { useEffect } from "react";
import EncabezadoDescripcion from "../components/Visuales/EncabezadoDescripcion";
import { withRouter } from 'react-router-dom';
import { getItem } from '../services/UseLocalStorage';
import SingleFeatures from "../components/Visuales/SingleFeatures";
import {  Package, Image, Users, List,Calendar,UserCheck } from "react-feather";

const Mantenimientos = (props) => {
  const { history } = props;

  useEffect(() => {
    if (getItem('usuario')) {
      if (getItem('usuario').uTipo != "tp") {
        history.goBack() ? history.goBack() : history.push("/")
      }
    } else {
      history.goBack() ? history.goBack() : history.push("/")
    }

  });

  return (
    <>
      <EncabezadoDescripcion titulo="Mantenimientos" id="Mantenimientos" descripcion="En esta sección se realizan los matenimientos de los siguientes apartados:" />
      <section className="contact-area animated fadeIn fast">
        <div id="mantenimientos" className="row justify-content-center align-items-center mt-5 mb-5">
          <div className="col-12 col-xs-10  formContainer">

            {/* Start Features Area*/}
            <section className="features-area">
              <div className="container">
                <div className="row">

                  <SingleFeatures
                    clase="col-lg-6 col-sm-12"
                    titulo="Servicios"
                    descripcion=""
                    callback={() => { history.push("/adm/servicios#AdmServicios") }}>
                    <Package />
                  </SingleFeatures>

                  <SingleFeatures
                    clase="col-lg-6  col-sm-12"
                    titulo="Publicidad"
                    descripcion=""
                    callback={() => { history.push("/adm/publicidad#AdmPublicidad") }}>
                    <Image />
                  </SingleFeatures>

                  <SingleFeatures
                    clase="col-lg-6  col-sm-12"
                    titulo="Clientes y testimonios"
                    descripcion=""
                    callback={() => { history.push("/adm/clientes#AdmClientes") }}>
                    <Users />
                  </SingleFeatures>

                  <SingleFeatures
                    clase="col-lg-6 col-sm-12"
                    titulo="Promociones"
                    descripcion=""
                    callback={() => { history.push("/adm/promociones#AdmPromociones") }}>
                    <Calendar />
                  </SingleFeatures>

                  <SingleFeatures
                    clase="col-12"
                    titulo="Pedidos"
                    descripcion=""
                    callback={() => { history.push("/adm/pedidos#AdmPedidos") }}>
                    <UserCheck />
                  </SingleFeatures>


                </div>
              </div>
            </section>
            {/* End Features Area*/}
          </div>
        </div>
      </section>
    </>
  );
};

export default withRouter(Mantenimientos);