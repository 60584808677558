import React, { useState, useEffect } from "react";
import { withRouter, useHistory } from "react-router-dom";
import ServicioTipoCarrito from "../components/Visuales/ServicioTipoCarrito";
import { Retrieve, SweetAlert } from "../services/ControlActions";
import { ListaEndpoints, ListaTipoServicios } from "../config/Config";
import ServicioTipoProx from "../components/Visuales/ServicioTipoProx";
import Spinner from "../components/Visuales/Spinner";

const Servicios = ({ match }) => {
  let id = match.params.id;
  let history = useHistory();
  const [datos, setDatos] = useState({});
  const [cargando, setCargando] = useState(false);
  const [spinner, showSpinner, hideSpinner] = Spinner();

  const evaluaComponente = (param) => {
    if (param === undefined) return null;
    switch (param) {
      case ListaTipoServicios.CARRITO:
        return (
          <ServicioTipoCarrito cargando={cargando} datos={datos}/>
        );
      case ListaTipoServicios.PROXIMAMENTE:
        return <ServicioTipoProx />;     
      default:
        return null;
    }
  };

  useEffect(() => {
    setCargando(true);
    showSpinner();
    Retrieve(ListaEndpoints.SERVICIO, "GetServicioActivo", id).then((res) => {
      if (res.data) {
        setDatos(res.data);
        setCargando(false);
      } else {
        let err =  res.msg;
        SweetAlert(3, "Acción Interrumpida", err, "", "Entendido", () => {
          history.push("/servicios");
        });
      }
      hideSpinner();
    });
  }, [history, id]);

  return (
    <>
      {spinner}
      {cargando ? null : evaluaComponente(datos.tipo)}
    </>
  );
};

export default withRouter(Servicios);
