import React, { useState } from "react";
import { useForm } from "react-hook-form";
import ErrorForm from "./ErrorForm";
import { Create, SweetAlert } from "../../services/ControlActions";
import Spinner from "../Visuales/Spinner";
import {
    ListaEndpoints,
    ERROR_REQUERIDO,
    REGEX_VALIDACION_EMAIL,
    ERROR_REGEX_EMAIL,
} from "../../config/Config";

const FormDesactivarNotificacion = ({ titulo, pln, cta }) => {
    const { register, handleSubmit, errors, reset } = useForm();

    const [cargando, setCargando] = useState(false);
    const [enviado, setEnviado] = useState(false);
    const [spinner, showSpinner, hideSpinner] = Spinner();
    const plan = pln;
    const cuenta = cta;


    const onSubmit = (data) => {
        consultarAPI(data);
    };

    const consultarAPI = async (data) => {
        showSpinner();

        setEnviado(false);
        setCargando(true);

        let cData = {};
        cData.pla_num = plan;
        cData.cta_Cuenta = cuenta;
        cData.app_Codigo = '004';
        cData.pla_NotificacionCobroENviar = false;
        cData.pla_NotificacionCobroMotivo = data.mensaje;

        Create(ListaEndpoints.USUARIO, "UpdateNotificacionCuenta", "", cData)
            .then((res) => {
                if (res.data) {

                    setCargando(false);
                    setEnviado(true);
                    reset();
                } else {
                    let err = "Consulta: " + res.error;
                    SweetAlert(
                        3,
                        "Acción Interrumpida, Intentelo de nuevo más tarde",
                        err,
                        "",
                        "Entendido",
                        () => { }
                    );
                }
                hideSpinner();
            })
            .catch((e) => {
                hideSpinner();
                let err =
                    "EnviarConsultaUsuario: No pudo conectar al servidor, inténtelo de nuevo más tarde." +
                    e.message;
                SweetAlert(3, "Acción Interrumpida", err, "", "Entendido", () => { });
            });
    };

    return (
        <>
            {spinner}
            <section>
                <div className="container-fluid h-100 justify-content-center align-items-center my-5">
                    <div className="row h-100 justify-content-center align-items-center">
                        <form onSubmit={handleSubmit(onSubmit)} className="col-md-12">
                            <fieldset>
                                {titulo ? <h2 className="mb-5">{titulo} </h2> : null}

                                <div className="form-group">
                                    <label>Favor indiquenos el motivo por el cual no desea recibir mas notificaciones por correo electrónico</label>

                                    <textarea
                                        type="text"
                                        className={
                                            "form-control " + (errors.mensaje ? "input-error" : null)
                                        }
                                        name="mensaje"
                                        placeholder="Motivo"
                                        ref={register({ required: "Campo requerido." })}
                                        rows="4"
                                        maxLength="255"
                                    />

                                    {errors.mensaje ? (
                                        <ErrorForm mensaje={errors.mensaje.message} />
                                    ) : null}
                                </div>

                                {enviado ? (
                                    <p className="alert alert-success text-center p-2 animated fadeInUp faster">
                                        La desactivacion se realizó con éxito
                                    </p>
                                ) : null}

                                <button
                                    type="submit"
                                    className="btn btn-primary"
                                    disabled={cargando}
                                >
                                    Enviar
                                </button>
                            </fieldset>
                        </form>
                    </div>
                </div>
            </section>

            <div className="bg-info">
                <div className="container">
                    <div className="row"></div>
                </div>
            </div>
        </>
    );
};

export default FormDesactivarNotificacion;
