import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import EncabezadoDescripcion from "../components/Visuales/EncabezadoDescripcion";
import CardPequenna from "../components/Visuales/CardPequenna";


const LoadingCard = () => { 
  return(<>
    <div className="col-lg-4 col-md-6 col-sm-6">
      <div className="single-services-box">
        <div className="">
          <img src="" alt="logo" />
        </div>
        <h3>
          <Link to="/">Cargando...</Link>
        </h3>
        <p>Cargando...</p>
      </div>
    </div>

    <div className="col-lg-4 col-md-6 col-sm-6">
      <div className="single-services-box">
        <div className="">
          <img src="" alt="logo" />
        </div>
        <h3>
          <Link to="/">Cargando...</Link>
        </h3>
        <p>Cargando...</p>
      </div>
    </div>
    <div className="col-lg-4 col-md-6 col-sm-6">
      <div className="single-services-box">
        <div className="">
          <img src="" alt="logo" />
        </div>
        <h3>
          <Link to="/">Cargando...</Link>
        </h3>
        <p>Cargando...</p>
      </div>
    </div>
  </>)
 }


const ServiciosList = ({ cargando, datos }) => {
  let contenido = cargando ? (
    <LoadingCard></LoadingCard>
  ) : (
    <>
      {datos.map((dato) => (
        <Fragment key={dato.id}>
          <CardPequenna
            id={dato.id}
            urlImagen={dato.urlImagen}
            titulo={dato.titulo}
            descripcion={dato.descripcion}
            redireccion={"/servicios/" + dato.id}
          />
        </Fragment>
      ))}
    </>
  );
  return (
    <>
      <EncabezadoDescripcion
        id="listaServicios"
        titulo="Servicios"
        descripcion=""
      />

      <section className="services-area-two ptb-80 bg-f9f6f6">
        <div className="container">
          <div className="row">{contenido}</div>
        </div>
      </section>
    </>
  );
};
export default ServiciosList;
