import React, { useEffect, useState } from 'react';
import EncabezadoFiguras from '../components/Visuales/EncabezadoFiguras';
import { withRouter } from 'react-router-dom';
import { getItem } from '../services/UseLocalStorage';
import { SweetAlert, GetWithToken} from '../services/ControlActions';
import { ListaEndpoints, IMPUESTO_VENTA } from '../config/Config';
import Spinner from '../components/Visuales/Spinner';
import moment from 'moment';
import $, {  } from "jquery";
import 'datatables.net';
import 'datatables.net-bs4';
import AlertMessage from "../components/Errors/AlertMessage";

const Pedido = (props) => {
    const { history, match } = props;
    const [data, setData] = useState({ data: null, cargando: true });
    const [table, setTable] = useState(true);
    const [spinner, showSpinner, hideSpinner] = Spinner();
    const [mensajeAlerta, setMensajeAlerta] = useState({ mensaje: "", error: false });
    const ID = match.params.id;
    const TBLID = "tblPromo" + ID;

    useEffect(() => {

        if (!getItem('usuario')) {

            history.goBack() ? history.goBack() : history.push("/")
        } else {
            if (data.cargando === true) {

                showSpinner()
                let usuario = getItem("usuario");
                GetWithToken(ListaEndpoints.CODIGO_APLICABLE, "GetRegistrosCodigoAplicable", ID).then((res) => {
                    if (res.data) {

                        if (usuario.uTipo !== "tp") {
                            setData({ data: null, cargando: false });
                                let err = "Registros: No puedes visualizar este registro";
                                SweetAlert(3, "Acción Interrumpida", err, "", "Entendido",
                                    () => { history.goBack() ? history.goBack() : history.push("/") })

                        } else {
                            //console.log("rtes",res);
                            setData({ data: res, cargando: false });

                        }
                    } else {
                        setData({ data: null, cargando: false });
                        let err = "Registros: " + res.error;
                        SweetAlert(3, "Acción Interrumpida", err, "", "Entendido",
                            () => { history.goBack() ? history.goBack() : history.push("/") })
                    }
                    hideSpinner();
                }).catch((e) => {
                    let err = "Registros: No pudo conectar al servidor, inténtelo de nuevo más tarde. "

                    setMensajeAlerta({ mensaje: err, error: true })
                    setData({ data: null, cargando: false });
                    hideSpinner();
                });

            } else {
                try {
                    //console.log("tabla", table)
                    if (table) {
                        setTable(false);
                        $("#" + TBLID).DataTable({                       
                            scrollX: true,               
                            processing: true,
                            destroy: true,
                            language: {
                                url: "//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json"
                            },
                            "ordering": true,
                            "info": true,
                            "colReorder": true,
                         //   "searching": false,
                            "bLengthChange": false,
                            dom: 'Blfrtip',
                            buttons: [
                                {
                                    extend: 'collection',
                                    text: 'Exportar <i class="fas fa-list-ol"></i>',
                                    className: "botonesSecundarios",
                                    buttons: [
                                        {
                                            extend: 'csv',
                                            text: 'CSV',
                                            footer: true,
                                            title: 'Listado de Facturas',
                                            filename: 'Listado_Facturas_csv',
                                            exportOptions: {
                                                columns: ':visible'
                                            }
                                        },
                                        {
                                            extend: 'pdfHtml5',
                                            text: 'PDF',
                                            footer: true,
                                            title: 'Listado de Facturas',
                                            filename: 'Listado_Facturas_pdf',
                                            exportOptions: {
                                                columns: ':visible'
                                            }
                                        },
                                        {
                                            extend: 'excel',
                                            text: 'Excel',
                                            footer: true,
                                            title: 'Listado de Facturas',
                                            filename: 'Listado_Facturas_excel',
                                            exportOptions: {
                                                columns: ':visible'
                                            }
                                        },
                                        {
                                            extend: 'copy',
                                            title: 'Listado de Facturas',
                                            text: 'Copiar',
                                            exportOptions: {
                                                columns: ':visible'
                                            }
                                        },
                                        {
                                            extend: 'print',
                                            title: 'Listado de Facturas',
                                            text: 'Imprimir',
                                            exportOptions: {
                                                columns: ':visible'
                                            }
            
                                        }
                                    ],
                                }
                            ],
                            dropup: true,
                            "order": [[0, "desc"]]
                        });

                    }
                } catch (error) {
                    //console.log("error de datatables", error)
                    let err = "_Table: Acción Interrumpida.\n " + error;
                    SweetAlert(3, "Acción Interrumpida", err, "", "Entendido",
                        () => { history.goBack() ? history.goBack() : history.push("/") })

                }

            }

        }

    }, [hideSpinner, history, data.cargando, showSpinner, table]);

    const irAtras = () => {
        history.goBack() ? history.goBack() : history.push("/")
    }

    return (
        <>
            {mensajeAlerta.error ? (
                <>
                    <AlertMessage
                        title={""}
                        message={mensajeAlerta.mensaje}
                        tipo={"alert-danger"}
                    />
                </>
            ) : null}
            <EncabezadoFiguras titulo="Registro de uso de promoción" />
            {spinner}
            {data.data === null ? (


                <section className="cart-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 col-md-12">
                                <div className="about-content">
                                    <div className="section-title">
                                        <h2>No hay nada que mostrar
                                            <button onClick={() => { irAtras() }} className="btn btn-primary float-right">
                                                Volver
                                            </button></h2>

                                        <div className="bar"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            ) : (
                <section className="cart-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 col-md-12">
                                <div className="about-content">
                                    <div className="section-title">
                                        <h2>Lista de clientes que usaron la promoción
                                            <button onClick={() => { irAtras() }} className="btn btn-primary float-right">
                                                Volver
                                            </button>
                                        </h2>

                                        <div className="bar"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                         
                        <div className="row mb-3">
                            <div className="col-lg-12 col-md-12">

                                <form>

                                    <div className="cart-table table-responsive carritoCompras">
                                        <table width="100%" id={TBLID} className="table table-bordered  ">
                                            <thead>
                                                <tr>
                                                <th scope="col">Id</th>
                                                    
                                                    <th scope="col">Fecha</th>
                                                    <th scope="col">Cuenta</th>
                                                    <th scope="col">Id usuario</th>

                                                </tr>
                                            </thead>
                                            <tbody>

                                                {data.cargando === false ? (

                                                    data.data === null  /*dataPedido.data.data.DetallePedido.length === 0 ? */ ?
                                                        (null) : (
                                                            data.data.data.map(pdato =>

                                                                <tr key={pdato.id}>
                                                                     <td className="product-name">
                                                                        {pdato.id}
                                                                    </td>
                                                             

                                                                    <td className="product-name">
                                                                    {pdato.fecha ? moment(pdato.fecha).format('YYYY/MM/DD, hh:mm a') : null}
                                                                    </td>
                                                                    <td className="product-name">
                                                                        {pdato.cuenta}
                                                                    </td>
                                                                    <td className="product-price text-right">
                                                                        <span className="unit-amount">
                                                                        {pdato.idUsuario}
                                                                        </span>
                                                                    </td>

                                                                </tr>

                                                            )
                                                        )
                                                ) : (
                                                    <h6>cargando</h6>
                                                )
                                                }

                                            </tbody>
                                        </table>
                                    </div>

                                </form>

                            </div>
                        </div>
                    </div>
                </section>
            )}
        </>
    );
};

export default withRouter(Pedido);
