
import "datatables.net";
import "datatables.net-bs4";
//import "../css/Table.css";
import { BASEURL, ListaMetodos } from "../config/Config";
import { getItem } from "./UseLocalStorage";
import Swal from "sweetalert2";

export const SweetAlert = (type, title, text, footer, btn, callBack) => {
  let icono = "";
  switch (type) {
    case 1:
      icono = "warning";
      break;
    case 2:
      icono = "success";
      break;
    case 3:
      icono = "error";
      break;
    case 4:
      icono = "info";
      break;
    case 5:
      icono = "question";
      break;
    default:
      icono = "";
      break;
  }

  const alerta = Swal.mixin({
    customClass: {
      confirmButton: "btn btn-primary",
      container: "zindex",
    },
    buttonsStyling: false,
  });
  alerta
    .fire({
      icon: icono,
      title: title,
      text: text,
      footer: footer,
      confirmButtonText: btn,
      showCloseButton: true,
    })
    .then((result) => {
      if (result.value) {
        callBack();
      }
    });
};
export const SweetAlert2 = (
  type,
  title,
  text,
  footer,
  btn,
  callBack,
  btn2,
  callBack2
) => {
  let icono = "";
  switch (type) {
    case 1:
      icono = "warning";
      break;
    case 2:
      icono = "success";
      break;
    case 3:
      icono = "error";
      break;
    case 4:
      icono = "info";
      break;
    case 5:
      icono = "question";
      break;
    default:
      icono = "";
      break;
  }

  const alerta = Swal.mixin({
    customClass: {
      confirmButton: "btn btn-primary m-1",
      cancelButton: "btn btn-primary m-1",
      container: "zindex",
    },
    buttonsStyling: false,
  });

  alerta
    .fire({
      title: title,
      text: text,
      icon: icono,
      footer: footer,
      showCancelButton: true,
      confirmButtonText: btn,
      cancelButtonText: btn2,
      showCloseButton: true,
    })
    .then((result) => {
      if (result.value) {
        callBack();
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        callBack2();
      }
    });
};
export const SwalToast = (type, title, callBack, ms) => {
  let icono = "";
  switch (type) {
    case 1:
      icono = "warning";
      break;
    case 2:
      icono = "success";
      break;
    case 3:
      icono = "error";
      break;
    case 4:
      icono = "info";
      break;
    case 5:
      icono = "question";
      break;
    default:
      icono = "";
      break;
  }

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: ms,
    timerProgressBar: true,
    onOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
    customClass: {
      container: "zindex",
    },
  });

  Toast.fire({
    icon: icono,
    title: title,
  }).then(() => {
    callBack();
  });
};
const request = async (servicio, metodo, tipo, id, data, query, token) => {
  try {
    let objPeticion = {
      method: tipo,
      body: data ? JSON.stringify(data) : undefined,
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "same-origin",
    };
    if (token != null) {
      objPeticion.headers.Authorization = `Bearer ${token}`;
    }
    const response = await fetch(
      `${BASEURL}${servicio}/${metodo}${id ? `/${id}` : ""}${query ? `?${query}` : ""
      }`,
      objPeticion
    ).catch((error) => {
      throw error;
    });
    const Jreponse = response.json();
    return Jreponse;
  } catch (err) {
   // console.log("catch de control actions",err)
    throw err;
  }

};

export const Retrieve = (servicio, metodo, id) => {
  return request(servicio, metodo, ListaMetodos.GET, id);
};
export const Create = (servicio, metodo, id, data) => {
  return request(servicio, metodo, ListaMetodos.POST, id, data);
};
export const Update = (servicio, metodo, id, data) => {
  return request(servicio, metodo, ListaMetodos.PUT, id, data);
};
export const Delete = (servicio, metodo, id, data) => {
  return request(servicio, metodo, ListaMetodos.DELETE, id, data);
};
export const PostWithToken = (servicio, metodo, id, data, queryObj) => {
  let query = null;

  if (queryObj) {
    query = new URLSearchParams(queryObj).toString();
  }

  return request(
    servicio,
    metodo,
    ListaMetodos.POST,
    id,
    data,
    query,
    getToken()
  );
};
export const UpdateWithToken = (servicio, metodo, id, data, queryObj) => {
  let query = null;

  if (queryObj) {
    query = new URLSearchParams(queryObj).toString();
  }

  return request(
    servicio,
    metodo,
    ListaMetodos.PUT,
    id,
    data,
    query,
    getToken()
  );
};
export const DeleteWithToken = (servicio, metodo, id, data, queryObj) => {
  let query = null;

  if (queryObj) {
    query = new URLSearchParams(queryObj).toString();
  }

  return request(
    servicio,
    metodo,
    ListaMetodos.DELETE,
    id,
    data,
    query,
    getToken()
  );
};
export const GetWithToken = (servicio, metodo, id, data, queryObj) => {
  let query = null;

  if (queryObj) {
    query = new URLSearchParams(queryObj).toString();
  }

  return request(
    servicio,
    metodo,
    ListaMetodos.GET,
    id,
    data,
    query,
    getToken()
  );
};
export const existeToken = () => {
  if (getToken()) {
    return true;
  } else {
    return false;
  }
};
const getToken = () => {
  
  let tokenUsuario = getItem("usuario")?.access_token;

  if (tokenUsuario) {
    return tokenUsuario;
  } else {
    return null;
  }
};


