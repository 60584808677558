import React from "react";



const EncabezadoDescripcion = ({ id, titulo, descripcion }) => {
    return (
        <div id={id} className="section-title encabezadoDescripcion">
            <spam className="encabezado-titulo">{titulo}</spam>
            <div className="bar"></div>
            <p>{descripcion}</p>

           
        </div>

    );
};

export default EncabezadoDescripcion;
