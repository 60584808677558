export const shape1 =
  "https://res.cloudinary.com/du4k7xyix/image/upload/v1590082266/Sigma/Shapes/shape1.png";
export const shape2 =
  "https://res.cloudinary.com/du4k7xyix/image/upload/v1590082266/Sigma/Shapes/shape2.svg";
export const shape3 =
  "https://res.cloudinary.com/du4k7xyix/image/upload/v1590082266/Sigma/Shapes/shape3.svg";
export const shape4 =
  "https://res.cloudinary.com/du4k7xyix/image/upload/v1590082266/Sigma/Shapes/shape4.svg";
export const shape5 =
  "https://res.cloudinary.com/du4k7xyix/image/upload/v1590082266/Sigma/Shapes/shape5.png";

export const imagen1 =
  "https://res.cloudinary.com/du4k7xyix/image/upload/v1590082280/Sigma/default/1_vm5xgm_n4xton.png";

export const contador_exp =
  "https://res.cloudinary.com/du4k7xyix/image/upload/v1590086110/Sigma/Logos/logo_express_oscuro_j9wwmf.png";

export const contador_mil =
  "https://res.cloudinary.com/du4k7xyix/image/upload/v1590086109/Sigma/Logos/logo_contador_oscuro_sx9q3e.png";

export const facturelo =
  "https://res.cloudinary.com/du4k7xyix/image/upload/v1703697420/Sigma/Logos/Facturelo_Logo_zjawoq.png";
export const facel =
  "https://res.cloudinary.com/du4k7xyix/image/upload/v1703696514/Sigma/Logos/logo_facel_oscuro_pauauq.png";

export const img_default_info_evento =
  "https://res.cloudinary.com/du4k7xyix/image/upload/v1590082288/Sigma/default/CECAPSI_u9sg4x_t2kh97.jpg";
export const img_default_servicio =
  "https://res.cloudinary.com/du4k7xyix/image/upload/v1590094101/Sigma/default/img_lxmprq.png";
export const img_default_cliente =
  "https://res.cloudinary.com/du4k7xyix/image/upload/v1590082280/Sigma/default/4_ekrlei_za5pta.jpg";

// Servicio CECAPSI

export const cecapsi_logo =
  "https://res.cloudinary.com/du4k7xyix/image/upload/v1703715920/Sigma/default/imagen_1_ocp7ry.jpg";

export const cecapsi_capacitaciones =
  "https://res.cloudinary.com/du4k7xyix/image/upload/v1590774973/Sigma/default/CECAPSI_oet1pt.jpg";

// Pag Nosotros
export const nosotros_logo_sigma =
  "https://res.cloudinary.com/du4k7xyix/image/upload/v1703715920/Sigma/default/imagen_1_ocp7ry.jpg";

export const nosotros_mision_vision =
  "https://res.cloudinary.com/du4k7xyix/image/upload/v1703715921/Sigma/default/imagen_2_ovhssv.jpg";
