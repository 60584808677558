import React, { useState, useEffect } from "react";
import Carousel from "./Carousel";
import { ListaEndpoints } from "../../config/Config";
import { Retrieve } from "../../services/ControlActions";

const BannerPublicidad = ({ setAlert, alert, id }) => {
    const [carouselData, setCarouselData] = useState({
        data: null,
        cargando: true,
    });

    useEffect(() => {

        if (carouselData.cargando) {
            Retrieve(ListaEndpoints.IMAGEN, "GetImagenesActivas")
                .then((res) => {
                    if (res.data) {
                        setCarouselData({ data: res.data, cargando: false });

                    } else {
                        let err = "-Publicidad: Acción Interrumpida.\n" + res.error;
                  
                         setAlert({
                            mensaje: alert.mensaje + err,
                            error: true,
                        });
                        setCarouselData({ data: null, cargando: false });
                    }
                })
                .catch((e) => {
                    let err =
                        "-Publicidad: No pudo conectar al servidor, inténtelo de nuevo más tarde.";

                    //console.log(e);
                    setAlert({
                        mensaje: alert.mensaje + err,
                        error: true,
                    });
                    setCarouselData({ data: null, cargando: false });
                });
        }


    }, [carouselData])



    return (

        <Carousel id={id} data={carouselData.cargando ||
            carouselData.data == null ? null : carouselData.data}></Carousel>

    );
}
export default BannerPublicidad;