import React from "react";


const InfoPrivacidad = () => {
    return (
        <>
            <div className="about-content">
                <h1>Privacidad</h1>
                <p>
                    <span lang="ES">Actualizado a marzo de 2022</span>
                </p>
                <p>
                    <span lang="ES">
                        Sigma Aseserorias Contables, S.A. públicamente asume
                        el compromiso y obligación de salvaguardar y proteger la información personal y
                        de su negocio que nos suministre. Es importante que lea detenidamente la
                        presente política de privacidad la cuál aplica, en la actualidad, para mayores
                        de 18 años y empresas y clientes de Costa Rica. La información protegida incluye
                        tanto la suministrada para la apertura y gestión de su cuenta, como la
                        configuración y uso de nuestras aplicaciones web.
                    </span>
                </p>
                <p>
                    <span lang="ES">
                        La información protegida por esta política es aquella
                        suministrada por el cliente o usuario y no aquella recopilada por SIGMA a través
                        de sitios públicos o de gobierno.
                    </span>
                </p>
                <span lang="ES">
                    <h5><span lang="ES">1) El sitio</span></h5>
                </span>
                <p>
                    <span lang="ES">
                        La información protegida es aquella suministrada por el cliente
                        a través de
                    </span>
                    <span lang="ES-TRAD">
                        <a href="http://www.sigmacr.net">
                            <span lang="ES">www.sigmacr.net</span>
                        </a>
                    </span>
                    <span lang="ES">
                        , en adelante
                        “el sitio”.
                    </span>
                </p >
            
                    <span lang="ES"> <h5>2) Información suministrada</h5></span>
             
                <p>
                    <span lang="ES">
                        En el sitio se recopilará información personal obligatoria tal
                        como:
                    </span>
                </p >

                <h6><span lang="ES">Información general o personal:</span></h6>
                <p>   <span lang="ES">
                    Nombre, dirección, correo electrónico, número de teléfono, contraseñas, área de
                    negocio, número de fax, sitio web, país de origen y referencia de contacto.
                </span>
                </p >

                <h6><span lang="ES">Información de su negocio o comercial:</span></h6>
                <p>  <span lang="ES">
                    Productos y servicios, precios, datos de sus clientes,
                    facturación, notas de débito, notas de crédito, recibos, facturas recibidas y
                    estados de cuenta.
                </span>
                </p >
                <p>
                    <span lang="ES">
                        La información de su clave de su firma digital no puede ser
                        almacenada en nuestro sitio a pesar que usted la digita al firmar documentos
                        electrónicos en nuestras aplicaciones.
                    </span>
                </p >

                <h5><span lang="ES">3) Uso de su información</span></h5>

                <p>
                    <span lang="ES">La información que usted nos suministra es utilizada para:</span>
                </p>
                <p >
                    <span lang="ES">·</span>
                    <span lang="ES">Contactarnos con usted.</span>
                </p >
                <p >
                    <span lang="ES">· </span>
                    <span lang="ES">
                        Enviarle información importante acerca
                        de cambios en nuestras políticas, términos y condiciones, el Sitio y otros.
                    </span>
                </p >
                <p>
                    <span lang="ES">·</span>
                    <span lang="ES">
                        Configurar su cuenta e información
                        tributaria para el uso de nuestras aplicaciones conforme a la normativa contable
                        y tributaria vigente.
                    </span>
                </p >

                <p >
                    <span lang="ES">· </span>
                    <span lang="ES">
                        Almacenarle en nuestra base de datos
                        como nuestro cliente.
                    </span>
                </p >
                <p >
                    <span lang="ES">·</span>
                    <span lang="ES">
                        Generarle estados de cuenta, facturas y
                        recibos.
                    </span>
                </p >
                <p >
                    <span lang="ES">·</span>
                    <span lang="ES">
                        Enviar información importante sobre
                        nuestros negocios y productos.
                    </span>
                </p >
                <p >
                    <span lang="ES">·</span>
                    <span lang="ES">Identificar su cuenta.</span>
                </p >
                <p >
                    <span lang="ES">·</span>
                    <span lang="ES">
                        Prevenir e identificar posibles delitos
                        y fraudes.
                    </span>
                </p >
                <p >
                    <span lang="ES">·</span>
                    <span lang="ES">
                        Recibir sus solicitudes de información,
                        contacto y soporte.
                    </span>
                </p >

                <h5><span lang="ES">4) Seguridad</span></h5>

                <p>
                    <span lang="ES">
                        Nuestro objetivo en SIGMA es proteger su información personal y
                        comercial enviada a través del sitio. Se mantienen respaldos físicos y
                        electrónicos para evitar la pérdida y uso no autorizado de su información.
                    </span>
                </p >
                <p>
                    <span lang="ES">
                        SIGMA se compromete a no divulgar, modificar, comercializar y/o
                        destruir su información personal o comercial. El acceso a su información
                        personal y comercial se encuentra restringida a nuestros productos, servicios y
                        personal autorizado.
                    </span>
                </p >
                <p>
                    <span lang="ES">
                        SIGMA se compromete a que sus empleados, personeros, socios y /o
                        asociados conocen la presente Política de Privacidad y deberán respetarlas como
                        parte de sus obligaciones laborales y/o contractuales.
                    </span>
                </p >
                <p>
                    <span lang="ES">
                        Se le insta a remitir su información personal y comercial a
                        través de nuestro sitio dado que su correo electrónico y otros medios
                        electrónicos pueden no ser seguros. Se le sugiere utilizar en todo momento
                        medios seguros y protegidos para ayudarnos a proteger su información.
                    </span>
                </p >
                <p>
                    <span lang="ES">
                        SIGMA reconoce la importancia especial que tiene su información
                        tributaria y contable por lo que nos comprometemos a: (i) restringir el acceso a
                        y el uso de dicha información por nuestros empleados y/o colaboradores; (ii)
                        prevenir el acceso no autorizado por terceros; y (iii) prohibir la divulgación
                        ilegal de su información.
                    </span>
                </p >
                <p>
                    <span lang="ES">
                        SIGMA solo podrá suministrar su información a entes
                        gubernamentales, autoridades tributarias y/o autoridades judiciales que lo
                        requieran conforme a la normativa vigente.
                    </span>
                </p >
                <p>
                    <span lang="ES">
                        SIGMA garantiza que la información personal y comercial que nos
                        brinde se conservará de forma precisa y completa y durante toda la vigencia de
                        la relación contractual. Una vez finalizada su relación comercial con SIGMA, su
                        información personal y comercial será bloqueada durante un período prudencial y
                        posteriormente destruida para evitar acceso de terceros.
                    </span>
                </p >
                <p>
                    <span lang="ES">
                        SIGMA podrá remitir publicidad e información de nuestros
                        productos y servicios a su correo electrónico proporcionado. En cualquier
                        momento usted podrá cancelar la remisión de este tipo de información.
                    </span>
                </p >

                <h5><span lang="ES">5) Información crediticia</span></h5>

                <p>
                    <span lang="ES">
                        SIGMA no almacena o administra información de tarjetas de
                        crédito y/o débito, números de cuenta, vencimiento de sus tarjetas o cualquier
                        otra relativa al cobro de nuestros productos o servicios. Dicha información es
                        remitida y administrada directamente por el Banco Nacional de Costa Rica.
                    </span>
                </p >

                <h5><span lang="ES">6) Información no personal o comercial</span></h5>

                <p>
                    <span lang="ES">
                        SIGMA podrá recopilar otro tipo de información para su uso
                        exclusivo tal como:
                    </span>
                </p >

                <h6><span lang="ES">Información de su navegador:</span></h6>
                <p>  <span lang="ES">

                    Dirección de IP, resolución de pantalla, tipo de sistema operativo (Windows o
                    Mac) y versión, tipo de navegador de Internet y versión, tiempo de la visita y
                    las páginas visitadas.&nbsp; Esta información es para el diagnóstico de nuestro
                    propio sitio y no tiene ningún interés comercial.
                </span>
                </p >

                <h6><span lang="ES">Cookies:</span></h6>
                <p>   <span lang="ES">
                    &nbsp; “Cookies” son
                    porciones de información almacenada directamente en su computadora que permiten
                    reconocer su computador para futuras visitas. Por medio de las Cookies se
                    recopila información como páginas visitadas, tiempo en el sitio, idiomas, entre
                    otros. Las cookies nos ayudarán a mejorar los protocolos de seguridad de la
                    página para que sean más eficaces. Se podrán utilizar Cookies para mostrarle
                    publicidad más cercana a sus gustos.
                </span>
                </p >

                <h6>
                    <span lang="ES">
                        Uso de tags pixel, contadores de visitantes, GIF
                        transparentes u otras tecnologías parecidas:
                    </span>
                </h6>
                <p> <span lang="ES">
                    &nbsp;
                    Estos podrían ser utilizados en relación con algunas páginas del Sitio y
                    mensajes del formato HTML para, entre otras cosas, darles seguimiento a las
                    acciones de los usuarios del Sitio y destinatarios de correos electrónicos,
                    medir el éxito de nuestras campañas comerciales y compilar estadísticas sobre el
                    uso del Sitio e índice de respuestas.&nbsp;
                </span>
                </p >

                <h5>
                    <span lang="ES">7) Gestión de su información
                    </span>
                </h5>

                <p>
                    <span lang="ES">
                        Mensualmente se le remitirá un estado de su cuenta y una factura
                        electrónica de acuerdo al plan adquirido. Usted podrá revisar su información,
                        modificarla y/o suprimirla en cualquier momento. No nos hacemos responsables por
                        la información personal o comercial que usted mismo o terceros con conocimiento
                        de sus claves modifiquen o supriman de nuestro sitio dado que no tenemos control
                        sobre dichas acciones.
                    </span>
                </p >

                <h5><span lang="ES">8) Otros Sitios</span></h5>


                <p>
                    <span lang="ES">
                        La presente política de privacidad tan solo aplica para la
                        información suministrada en el sitio y no en sitios de terceros. Los enlaces
                        aquí sugeridos son propiedad de terceras personas y SIGMA manifiesta que ha
                        enlazado sitios con sus propias políticas de privacidad. SIGMA no se hace
                        responsable por el contenido, la seguridad y las prácticas de privacidad
                        empleadas por otros sitios. La información que divulgue a otras partes o a
                        través de dichos sitios está sujeta a las prácticas y políticas de privacidad de
                        esas partes o esos sitios.
                    </span>
                </p >


                <h5><span lang="ES">9) Usuarios Internacionales</span></h5>

                <p>
                    <span lang="ES">
                        Este sitio es operado y administrado por SIGMA desde Costa Rica
                        y le aplica las leyes de la República de Costa Rica. Si usted accede a esta
                        página desde un lugar distinto de Costa Rica, debe entender que le aplica la
                        presente Política de Privacidad.
                    </span>
                </p >
                <p>
                    <span lang="ES">
                        SIGMA se somete a las leyes y jurisdicción de la República de
                        Costa Rica y el usuario acepta que cualquier acceso que realice desde otro país
                        no obliga a SIGMA a las leyes de ese país ni a su jurisdicción.
                    </span>
                </p >

                <h5><span lang="ES">10) Cambios a esta Política</span></h5>

                <p>
                    <span lang="ES">
                        SIGMA se reserva el derecho de realizar cambios no sustanciales
                        a la presente Política de Privacidad. Cualquier cambio realizado a la política
                        será actualizada en el sitio. Recomendamos revisar regularmente la presente
                        Política para verificar cualquier cambio.
                    </span>
                </p >


                <h6>
                    <span lang="ES">
                        El usuario acepta con el uso de nuestras aplicaciones que ha
                        leído y conoce de estas Políticas de Privacidad, que pueden ser visualizadas en
                        cualquier momento desde el sitio y se compromete a acatarlas y respetarlas. Así
                        mismo, comunicará a SIGMA de cualquier posible violación de su información o de
                        otros usuarios de SIGMA que llegue a su conocimiento.
                    </span>
                </h6>

            </div >
        </>
    );
};

export default InfoPrivacidad;
