import React, { useState, useEffect } from 'react';

export const UsePagination = () => {

    const [totalItems, setTotalItems] = useState(0);//total de items
    const [countItems, setCountItems] = useState(10);//cantidad de item por page
    const [currentPage, setCurrentPage] = useState({ pageNum: 1, skip: 0 });//pagina actual
    const [pages, setPages] = useState([])
    // totalItems % countItems == 0 ? totalItems / countItems : Math.trunc(totalItems / countItems) + 1;

    useEffect(() => {

        let pagesCount = Math.ceil(totalItems / countItems);
        let pages = [];
        let skips = 0;
        if (totalItems > 0) {
            for (let index = 0; index < pagesCount; index++) {
                pages.push({ pageNum: index + 1, skip: skips });
                skips += countItems;
            }
        } else {
            pages.push({ pageNum: 1, skip: 0 });
        }

        setPages(pages);
    }, [totalItems, countItems])
    const nextPage = () => {
        let item = getLast();
        if (item !== undefined && item !== null && currentPage.pageNum !== item.pageNum) {
            setCurrentPage(pages.find(e => e.pageNum === currentPage.pageNum + 1))
        }

    }
    const previous = () => {
        let item = getFirst();
        if (item !== undefined && item !== null && currentPage.pageNum !== item.pageNum) {
            setCurrentPage(pages.find(e => e.pageNum === currentPage.pageNum - 1))
        }

    }
    const getLast = () => pages.slice(-1).pop();
    const getFirst = () => pages.slice(0, 1).pop();
    const goPage = (page) => {

        if (page !== undefined && page !== null && currentPage.pageNum !== page.pageNum) {
            setCurrentPage(page)
        }

    }
    const goFirstPage = () => {
        setCurrentPage({ pageNum: 1, skip: 0 });
    }
    const isTheLast = () => {
        let item = getLast();
        if (item !== undefined && item !== null && currentPage.pageNum == item.pageNum) {
            return true;
        } else {
            return false;
        }

    }
    const Pagination = () => {
        // console.log("hola buenas ", pages)
        return (
            <>
                <div className='f-left'>{`Mostrando resultados del ${currentPage.skip + 1} al ${isTheLast() ? totalItems : currentPage.skip + countItems} de un total de ${totalItems} registros`}</div>
                <ul className="pagination">
                    <li className="paginate_button page-item previous" >
                        <button onClick={previous} className="page-link">Anterior</button>
                    </li>
                    {pages?.length > 0 ? <>
                        {pages.map(page =>
                            <li key={page.pageNum} className={"paginate_button page-item " + (page.pageNum === currentPage.pageNum ? "active" : "")}>
                                <button onClick={() => goPage(page)} className="page-link">{page.pageNum}</button>
                            </li>
                        )}
                    </> :
                        <li className="paginate_button page-item active">
                            <button className="page-link">1</button>
                        </li>
                    }
                    <li className="paginate_button page-item next" id="tblPedidosAdm_next">
                        <button onClick={nextPage} className="page-link">Siguiente</button>
                    </li>
                </ul>
            </>
        )
    }
    return (
        {
            Pagination,
            setTotalItems,
            setCountItems,
            goFirstPage,
            countItems,
            currentPage
        }
    )
}
