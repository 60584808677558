import  { useState, useEffect } from 'react'
import { SweetAlert } from "../services/ControlActions"
import {  useHistory } from "react-router-dom";
import { setItem } from "../services/UseLocalStorage"
import { URL_SINGALR, ListaHubsSignalR } from "../config/Config"
//signalR
import { hubConnection } from 'signalr-no-jquery';
const connectionPagoPedido = hubConnection(URL_SINGALR);
const hubProxyPagoPedido = connectionPagoPedido.createHubProxy(ListaHubsSignalR.HUB_CONCRETARPEDIDO.name);

export const UseSignalR = () => {
    let history = useHistory();
    //signalrConexion
    const [conexionId, setConexionId] = useState("");
    const InicializaConexionSignalR = () => {
        // let self = this;

        //#region lista de metodos
        //Se declara el proxy al hub llamado hubConcretarPedido en el API que es el retorno y se obtiene la descarga
        hubProxyPagoPedido.on(ListaHubsSignalR.HUB_CONCRETARPEDIDO.hubConcretarPedido, function (res) {
            //alignet-modal
            // console.log("Entró al método signalR", res)
            const modalPayment = document.getElementById("BodyPrincipal");
            if (modalPayment != null) {
                //console.log("modal", modalPayment)
                modalPayment.classList.remove('alignet-body-modal');
                const modal = document.getElementById("alignet-modal");
                if (modal) {
                    modalPayment.removeChild(modal)
                }
            }
            //window.location.reload(true)
            if (res != null) {//si no hay respuesta         
                switch (res.data) {
                    case '01':
                        window.location.reload(true)
                        break;
                    case '02':
                        //   console.log("02")
                        if (res.ok) {
                            SweetAlert(2, "Acción Completada", res.msg, "", "Entendido", () => { history.push("/historialPedidos"); });
                        } else {
                            SweetAlert(1, "Acción Completada", res.msg, "", "Entendido", () => { history.push("/historialPedidos"); });
                            console.error(res.error, res.errorDescription);
                        }
                        setTimeout(() => {
                            history.push("/historialPedidos");
                        }, 3000)

                        break;
                    case '03':
                        SweetAlert(3, "Acción interrumpida", res.msg, "", "Entendido",
                            () => { history.goBack() ? history.goBack() : history.push("/"); });
                        console.error(res.error, res.errorDescription);
                        break;
                    case '04':
                        SweetAlert(1, "Acción en proceso", res.msg, "", "Entendido", () => { });
                        //console.log(res.error, res.errorDescription);
                        break;
                    default:
                        // window.location.reload(true)
                        break;
                }

            } else {
                SweetAlert(3, "No se pudo completar la acción", "No hubo respuesta", "", "Entendido",
                    () => { history.goBack() ? history.goBack() : history.push("/"); });
                setTimeout(() => {
                    history.goBack() ? history.goBack() : history.push("/");
                }, 2000);
            }
        });
        //#endregion

        // Se inicializa la conexión al API con el signalr
        connectionPagoPedido.start({ jsonp: true })
            .done(function () {
               // console.log('Now connected, connection signalR ID=' + connectionPagoPedido.id);
                setItem("conexionSignalRId", connectionPagoPedido.id);
                setConexionId(connectionPagoPedido.id)
            })
            .fail(function () {
           //     console.log('Could not connect');
            });
    }
    useEffect(() => {
        //solo se ejecute una vez
        InicializaConexionSignalR();
    }, [])
    return (
        {
            conexionId,
            InicializaConexionSignalR
        }
    )
}
