import React from "react";
import EncabezadoDescripcion from "./EncabezadoDescripcion";

const ServicioTipoProx = () => {

  return (
    <EncabezadoDescripcion titulo="Proximamente" id="proximamenteContainer" descripcion="Estará disponible en una futura actualización"/>
  );
};

export default ServicioTipoProx;
