import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import ErrorForm from "./ErrorForm";
import ListaDropdown from "./ListaDropdown";
import moment from "moment";
import {
  ListaEndpoints,
  ListaTipoCedulas,
  ERROR_REQUERIDO,
  LONG_CEDULA_FISICA,
  LONG_CEDULA_JURIDICA,
  LONG_DIMEX,
  // LONG_NITE,
  MSG_FORM_CARGANDO,
  REGEX_VALIDACION_EMAIL,
  ERROR_REGEX_EMAIL,
} from "../../config/Config";
import {
  Retrieve,
  GetWithToken,
  PostWithToken,
} from "../../services/ControlActions";
import Spinner from "../Visuales/Spinner";

const FormPerfil = ({ titulo, usuario, setUsuario, accion }) => {
  const { register, handleSubmit, errors, setValue, watch } = useForm({
    defaultValues: {
      cedula: 0,
      email: MSG_FORM_CARGANDO,
      nombre: MSG_FORM_CARGANDO,
      pais: "",
      telefono: MSG_FORM_CARGANDO,
      tipoCedula: "",
      direccion: MSG_FORM_CARGANDO,
    },
  });
  const [modoEditar, setModoEditar] = useState(true);
  const [spinner, showSpinner, hideSpinner] = Spinner();
  const [paises, setPaises] = useState([
    { 188: "Costa Rica" },
    { 187: "Estados Unidos" },
  ]);
  const [tipoCedulaSeleccionada, setTipoCedulaSeleccionada] = useState(0);
  const [cargando, setCargando] = useState(false);
  const [enviado, setEnviado] = useState(false);
  const [mensajeRespuesta, setMensajeRespuesta] = useState({
    error: false,
    mensaje: "",
  });

  // Devuelve el label adecuado el tipo de cedula, dependiendo de la cédula escogida
  const getLabelCedula = () => {
    let res = TIPOCEDULAS.find((cedula) => {
      return Number(Object.keys(cedula)[0]) === tipoCedulaSeleccionada;
    });

    if (res) {
      return Object.values(res)[0];
    } else {
      return "";
    }
  };

  // Devuelve la longitud adecuada para el tipo de cedula, dependiendo de la cédula escogida
  const getLongCedula = () => {
    switch (tipoCedulaSeleccionada) {
      case ListaTipoCedulas.FISICA:
        return LONG_CEDULA_FISICA;
      case ListaTipoCedulas.JURIDICA:
        return LONG_CEDULA_JURIDICA;
      case ListaTipoCedulas.DIMEX:
        return LONG_DIMEX;
      default:
        return 0;
    }
  };

  const getTipoCedulaPorNumCedula = (numCedula) => {
    if (!numCedula) {
        return ListaTipoCedulas.FISICA;
    }

    switch (numCedula.length) {
      case LONG_CEDULA_FISICA:
        return ListaTipoCedulas.FISICA;

      case LONG_CEDULA_JURIDICA:
        return ListaTipoCedulas.JURIDICA;

      case LONG_DIMEX:
        return ListaTipoCedulas.DIMEX;

      default:
            return ListaTipoCedulas.FISICA;
    }
  };

  // Validacion para que se borren las modificaciones que hizo el usuario,
  // en caso de que le dé al cancelar
 /* const onCancel = () => {
    setModoEditar(false);

    let tipoCed = getTipoCedulaPorNumCedula(usuario.Identificacion);
    setTipoCedulaSeleccionada(tipoCed);

    setValue([
      { cedula: usuario.Identificacion },
      { email: usuario.Email },
      { nombre: usuario.Nombre },
      { pais: usuario.Pais },
      { telefono: usuario.Telefono },
      { direccion: usuario.Direccion },
      { tipoCedula: tipoCed === 0 ? "" : tipoCed },
    ]);
  };*/

  const onSubmit = (data) => {

    if (modoEditar) {
      let objUsuario = {
        Identificacion: data.cedula,
        Email: data.email,
        Nombre: data.nombre || '""',
        Compannia: '""',
        Pais: data.pais,
        Telefono: data.telefono,
        Direccion: data.direccion,
        UltimoAcceso: moment.utc().toISOString(),
      };

      setUsuario((prevState) => {
       // console.log("datos usuario", { ...prevState, ...objUsuario })
        return { ...prevState, ...objUsuario };
      });

      setCargando(true);
    }
  };

  // UseEffect para modificar info perfil
  useEffect(() => {
    const consultarAPI = async () => {
       setEnviado(false);
       showSpinner();
       
      // setCargando(true);

      PostWithToken(ListaEndpoints.USUARIO, "UpdateUsuario", "", usuario).then(
        (res) => {
          setEnviado(true);

          if (res.data) {

            setMensajeRespuesta({ error: false, mensaje: res.msg });
            setCargando(false);
            //setModoEditar(false);
          } else {
            console.error(res.error, res.errorDescription);
            setMensajeRespuesta({
              error: true,
              mensaje: res.errorDescription,
            });
            setCargando(false);
          }
        },
        (error) => {
          console.error("Ha ocurrido un error.", error);

          
        }
      ).finally(()=>{hideSpinner(); setCargando(false);});
    };

    if (cargando) {
      consultarAPI();
    }
  }, [usuario, cargando, modoEditar]);

  // UseEffect inicial
  useEffect(() => {
    // Carga Paises
    showSpinner();
    Retrieve(ListaEndpoints.USUARIO, "GetPaises").then(
      (res) => {
        if (res.data) {
          // Carga y formatea paises para el dropdown
          let respPaises = res.data?.listaPaises;
          let objPaisesTemp = [];

          for (const pais of respPaises) {
            let paisTemp = { [pais.pai_isoNum]: pais.pai_Nombre };

            objPaisesTemp.push(paisTemp);
          }

          setPaises(objPaisesTemp);
          // fin CargaPaises

          // Carga info usuario, se hace dentro de la respuesta de getPaises,
          // para asegurarse que los paises fueron cargados correctamente, y
          // sea seleccionado el que habia escogido el usuario
          GetWithToken(ListaEndpoints.USUARIO, "GetUsuario").then(
            (resU) => {
              if (resU.data) {
                let respUsuario = resU.data;

                if (!respUsuario) {
                  console.error("Ha ocurrido un error al mostrar el usuario");
                  return null;
                }

                let tipoCed = getTipoCedulaPorNumCedula(
                  respUsuario.Identificacion
                );

                setTipoCedulaSeleccionada(tipoCed);

                setValue([
                  { cedula: respUsuario.Identificacion },
                  { email: respUsuario.Email },
                  { nombre: respUsuario.Nombre },
                  { pais: respUsuario.Pais },
                  { telefono: respUsuario.Telefono },
                  { direccion: respUsuario.Direccion },
                  { tipoCedula: tipoCed === 0 ? "" : tipoCed },
                ]);

                setUsuario(respUsuario);
              } else {
                console.error("Error usuario.");
              }
            },
            (error) => {
              console.error("Ha ocurrido un error al traer el usuario.", error);

              setCargando(false);
            }
          );
        } else {
        }
      },
      (error) => {
        console.error("Ha ocurrido un error al traer los países.", error);

        setCargando(false);
      }
    ).finally(()=>{hideSpinner(); setCargando(false);});;
  }, []);

  return (
    <>
    {spinner}
      <section>
        <div className="container-fluid h-100 justify-content-center align-items-center mt-1 mb-5">
          <div className="row h-100 justify-content-center align-items-center">
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="col-md-12 formPerfil"
            >
              <fieldset>
                <h2>{titulo}</h2>
                <div className="form-row">
                  <div className="form-group col-md-6">
                    <label>Correo Electrónico</label>

                    <input
                      type="text"
                      className={
                        "form-control " + (errors.email ? "input-error" : null)
                      }
                      name="email"
                      placeholder="Correo electrónico"
                      ref={register({
                        required: ERROR_REQUERIDO,
                        pattern: {
                          value: REGEX_VALIDACION_EMAIL,
                          message: ERROR_REGEX_EMAIL,
                        },
                      })}
                      disabled={true}
                    />

                    {errors.email ? (
                      <ErrorForm mensaje={errors.email.message} />
                    ) : null}
                  </div>
                  <div className="form-group col-md-6">
                    {tipoCedulaSeleccionada === ListaTipoCedulas.SELECCIONAR ||
                      tipoCedulaSeleccionada === ListaTipoCedulas.DIMEX ||
                      tipoCedulaSeleccionada === ListaTipoCedulas.FISICA ? (
                      <label>Nombre Completo</label>
                    ) : (
                      <label>Nombre Empresa</label>
                    )}

                    <input
                      type="text"
                      className={
                        "form-control " + (errors.nombre ? "input-error" : null)
                      }
                      name="nombre"
                      placeholder="Nombre"
                      ref={register({ required: ERROR_REQUERIDO })}
                      disabled={
                        tipoCedulaSeleccionada === 0 || !modoEditar
                          ? true
                          : false
                      }
                    />
                  </div>
                </div>

                <div className="form-row">
                  <div className="form-group col-md-5">
                    <div className="select-box">
                      <label>Tipo Cédula</label>

                      <select
                        className={
                          "form-control " +
                          (errors.tipoCedula ? "input-error" : null)
                        }
                        disabled={!modoEditar ? true : false}
                        name="tipoCedula"
                        ref={register({ required: ERROR_REQUERIDO })}
                        onChange={() => {
                          setTipoCedulaSeleccionada(
                            Number(watch("tipoCedula")) || 0
                          );
                        }}
                      >
                        <ListaDropdown opciones={TIPOCEDULAS} />
                      </select>
                    </div>

                    {errors.tipoCedula ? (
                      <ErrorForm mensaje={errors.tipoCedula.message} />
                    ) : null}
                  </div>

                  <div className="form-group col-md-7">
                    <label>{getLabelCedula() || "Céd."}</label>

                    <input
                      type="number"
                      className={
                        "form-control " + (errors.cedula ? "input-error" : null)
                      }
                      name="cedula"
                      disabled={
                        tipoCedulaSeleccionada === 0 || !modoEditar
                          ? true
                          : false
                      }
                      placeholder={
                        watch("tipoCedula") === ""
                          ? "Seleccionar tipo de céd."
                          : `Ingrese ${getLabelCedula()}`
                      }
                      ref={register({
                        required: ERROR_REQUERIDO,
                        maxLength: {
                          value: getLongCedula(),
                          message: `Máximo ${getLongCedula()} caracteres de longitud.`,
                        },
                        minLength: {
                          value: getLongCedula(),
                          message: `Mínimo ${getLongCedula()} caracteres de longitud.`,
                        },
                      })}
                    />
                    {errors.cedula ? (
                      <ErrorForm mensaje={errors.cedula.message} />
                    ) : null}
                  </div>
                </div>

                <div className="form-row">
                  <div className="form-group col-md-7">
                    <label>Teléfono</label>

                    <input
                      type="tel"
                      className={
                        "form-control " +
                        (errors.telefono ? "input-error" : null)
                      }
                      name="telefono"
                      placeholder="(506) 8888-8888"
                      ref={register({ required: ERROR_REQUERIDO })}
                      disabled={
                        tipoCedulaSeleccionada === 0 || !modoEditar
                          ? true
                          : false
                      }
                    />

                    {errors.telefono ? (
                      <ErrorForm mensaje={errors.telefono.message} />
                    ) : null}
                  </div>

                  <div className="form-group col-md-5">
                    <div className="select-box">
                      <label>País</label>

                      <select
                        className={
                          "form-control " + (errors.pais ? "input-error" : null)
                        }
                        name="pais"
                        ref={register({ required: ERROR_REQUERIDO })}
                        disabled={
                          tipoCedulaSeleccionada === 0 || !modoEditar
                            ? true
                            : false
                        }
                      >
                        <ListaDropdown opciones={paises} />
                      </select>
                    </div>

                    {errors.pais ? (
                      <ErrorForm mensaje={errors.pais.message} />
                    ) : null}
                  </div>
                  <div className="form-group col-md-12">
                    <label>Dirección</label>

                    <textarea
                      type="text"
                      className={
                        "form-control " +
                        (errors.direccion ? "input-error" : null)
                      }
                      name="direccion"
                      placeholder="Dirección Exacta"
                      ref={register({ required: "Campo requerido." })}
                      disabled={
                        tipoCedulaSeleccionada === 0 || !modoEditar
                          ? true
                          : false
                      }
                    />

                    {errors.direccion ? (
                      <ErrorForm mensaje={errors.direccion.message} />
                    ) : null}
                  </div>
                </div>

                {enviado ? (
                  mensajeRespuesta.error ? (
                    <div className="alert alert-danger text-center p-2 animated fadeInUp faster mb-3">
                      {mensajeRespuesta.mensaje}
                    </div>
                  ) : (
                    <div className="alert alert-success text-center p-2 animated fadeInUp faster mb-3">
                      {mensajeRespuesta.mensaje}
                    </div>
                  )
                ) : null}

                <div
                  className={`d-flex justify-content-${modoEditar ? "between" : "center"
                    } mt-2`}
                >
                  {modoEditar ? (
                    <>
                      
                      <button
                        type="button"
                        className="btn btn-primary "
                        onClick={accion}
                      >
                        Cambiar contraseña
                      </button>
                      <button
                        type="submit"
                        className="btn btn-primary ml-5"
                        disabled={cargando}
                      >
                        Guardar
                      </button>
                    </>
                  ) : (
                    null
                  )}
                </div>
              </fieldset>
            </form>
            {modoEditar ? (
              null
            ) : (
              <div
                className={`container`}
              >
                <div className={` d-flex justify-content-between`}>
                  <button
                    type="button"
                    className="btn btn-primary "
                    onClick={accion}
                  >
                    Cambiar contraseña
                  </button>
                  <button
                    type="submit"
                    className="btn btn-primary"
                    onClick={() => { setModoEditar(true); setEnviado(false) }}
                  >
                    Editar
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </section>

      <div className="bg-info">
        <div className="container">
          <div className="row"></div>
        </div>
      </div>
    </>
  );
};

export default FormPerfil;

const TIPOCEDULAS = [
  { [ListaTipoCedulas.FISICA]: "Cédula Física" },
  { [ListaTipoCedulas.JURIDICA]: "Cédula Jurídica" },
  { [ListaTipoCedulas.DIMEX]: "DIMEX" },
];
