import React from 'react';
import { Link } from 'react-router-dom';


const CardPequenna = ({ id, titulo,  urlImagen, descripcion, redireccion }) => {



    return (

        <div id={id} className="col-lg-4 col-md-6 col-sm-6">
            <Link to={redireccion}>
                <div className="single-services-box">
                    <div className="servicio-img">
                        <img src={urlImagen} alt="shape" />

                    </div>

                    <h3>{titulo}</h3>
                    <p>{descripcion}</p>
                </div>
            </Link>
        </div >

    )
}

export default CardPequenna;