import React, { useState, useEffect } from "react";
import EncabezadoDescripcion from "../components/Visuales/EncabezadoDescripcion";
import ProductosPago from "../components/Visuales/ProductosPago";
import SeleccionPagoBN from "../components/Visuales/SeleccionPagoBN";
import { existeToken, SweetAlert } from "../services/ControlActions";
import { useHistory } from "react-router-dom";

const PagoPage = () => {
  let history = useHistory();
  const [muestraPagina] = useState(existeToken());

  useEffect(() => {// UseEffect inicial
  
    if (!muestraPagina) {//existe token   
      history.push("/");
      SweetAlert(3, "No puede acceder", "Necesita                                                                  iniciar sesión para continuar", "", "Entendido", () => { });
    }

  }, [muestraPagina,history]);//no borrar esos parentesis

  return (
    <>
        <section className="checkout-area mb-5">
          <div className="container">
            <EncabezadoDescripcion titulo="Pago" />
            <div className="cart-area">
              <div className="row">
                <div className="col-lg-6 col-md-12 mb-5">
                  <ProductosPago />
                </div>

                <div className="col-lg-6 col-md-12">                
                  <SeleccionPagoBN />                
                </div>
              </div>
            </div>
          </div>
        </section>
    </>
  );
};

export default PagoPage;
