import React from 'react';
import { getItem } from '../../services/UseLocalStorage';
import { useHistory } from "react-router-dom";

const BtnBack = () => {
    let history = useHistory();

    const go = () => {

        if (getItem('usuario')?.uTipo != "tp") {
            history.goBack() ? history.goBack() : history.push("/")
        } else {
            history.push("/mantenimientos")
        }
    }
    return (
        <div className="cart-buttons mb-3 container ">

            <div className="continue-shopping-box  float-right">
                <button type='button' onClick={go} className="btn btn-primary">
                    Volver
                </button>
            </div>
        </div>
    );
};

export default BtnBack;