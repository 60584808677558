import React, { useState, useEffect } from 'react';
import EncabezadoFiguras from '../components/Visuales/EncabezadoFiguras';
import { withRouter } from 'react-router-dom';
import { getItem } from '../services/UseLocalStorage';
import { ListaEndpoints, IMPUESTO_VENTA } from '../config/Config';
import FormatMoney from "../services/FormatMoney";
import { PostWithToken, Retrieve, SweetAlert } from '../services/ControlActions';
import Spinner from '../components/Visuales/Spinner';
import $ from "jquery";
import 'datatables.net';
import 'datatables.net-bs4';
import moment from 'moment';
import AlertMessage from "../components/Errors/AlertMessage";

const Planes = (props) => {
    const { history } = props;
    const [list, setList] = useState({ data: {}, cargando: true });
    const [table, setTable] = useState(true);
    const [spinner, showSpinner, hideSpinner] = Spinner();
    const [mensajeAlerta, setMensajeAlerta] = useState({ mensaje: "", error: false });
    const TBLID = "tblHistorial";
    useEffect(() => {

        try {
            if (!getItem('usuario')) {

                history.goBack() ? history.goBack() : history.push("/")
            } else {
                if (list.cargando === true) {

                    showSpinner();
                    PostWithToken(ListaEndpoints.USUARIO, `TraerPlanes?correo=${getItem('usuario').cta_id}`).then((res) => {
                        if (res.data) {

                            //console.log("Respu", res);


                            setList({ data: res, cargando: false });
                        } else {
                            let err = "Planes:" + res.error;
                            if (res.Message) {
                                err = res.Message;
                                SweetAlert(3, "Acción Interrumpida", err, "", "Entendido",
                                    () => { })
                                setList({ data: null, cargando: false });
                            } else {
                                SweetAlert(3, "Acción Interrumpida", err, "", "Entendido",
                                    () => { })

                            }
                            setMensajeAlerta({ mensaje: err, error: true })
                            setList({ data: null, cargando: false });

                        }
                        hideSpinner();
                    }).catch((e) => {
                        let err = "Planes: No pudo conectar al servidor, inténtelo de nuevo más tarde. " + e.message

                        setMensajeAlerta({ mensaje: err, error: true })
                        setList({ data: null, cargando: false });
                        hideSpinner();
                    });


                } else {
                    if (table) {

                        $("#" + TBLID).DataTable({
                            processing: true,
                            language: {
                                url: "//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json"
                            },
                            scrollX: true,
                            //  order: [[2, "desc"]]
                        });
                        setTable(false);
                    }
                }

            }
        } catch (error) {
            alert("Acción Interrumpida:", error);
        }

    }, [history, list.cargando, showSpinner, hideSpinner, table]);

    return (
        <>
            {mensajeAlerta.error ? (
                <>
                    <AlertMessage title={""} message={mensajeAlerta.mensaje} tipo={"alert-danger"} />
                </>
            ) : null}
            <EncabezadoFiguras titulo="Planes" />
            {spinner}
            <section className="cart-area ptb-80">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12">

                            <form>

                                <div className="cart-table table-responsive carritoCompras">

                                    <table width="100%" id={TBLID} className="table table-bordered  ">
                                        <thead>
                                            <tr>
                                                <th scope="col">Imagen</th>
                                                <th scope="col">Nombre</th>
                                              
                                                <th scope="col">Fecha inicio</th>
                                                <th scope="col">Fecha fin</th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            {list.cargando === false ? (

                                                list.data === null ?
                                                    (null) : (
                                                        list.data.data.map((pdato, index) =>

                                                            <tr key={index} onClick={() => { }}>

                                                                <td className="product-thumbnail text-center">

                                                                    <img src={pdato.producto.urlImagen} style={{ maxHeight: "80px" }} />
                                                                </td>


                                                                <td className="product-name">
                                                                    {pdato.producto.nombre}
                                                                </td>

                                                               {/* <td className="product-price text-center">
                                                                    <span className="unit-amount">
                                                                        pdato.pla_FechaC ? moment(pdato.pla_FechaC).format('YYYY/MM/DD') : null
                                                                    </span>
                                                                </td>
                                                    */ }
                                                                <td className="product-price text-center">
                                                                    <span className="unit-amount">
                                                                        {pdato.pla_FechaI ? moment(pdato.pla_FechaI).format('YYYY/MM/DD') : null}
                                                                    </span>
                                                                </td>
                                                                <td className="product-price text-center">
                                                                    <span className="unit-amount">
                                                                        {pdato.pla_FechaF ? moment(pdato.pla_FechaF).format('YYYY/MM/DD') : null}
                                                                    </span>
                                                                </td>




                                                            </tr>

                                                        )
                                                    )
                                            ) : null
                                            }

                                        </tbody>
                                    </table>

                                </div>

                            </form>

                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default withRouter(Planes);
