import React, { useState, useEffect } from "react";
import CardProducto from "./CardProducto.js"
import { Retrieve } from "../../services/ControlActions";
import { ListaEndpoints } from "../../config/Config.js";
import EncabezadoFiguras from "../Visuales/EncabezadoFiguras";
const PlanesFacturelo = () => {
    const [planesData, setPlanesData] = useState({
        data: [],
        cargando: true,
    });

    useEffect(() => {
        Retrieve(ListaEndpoints.SERVICIO, "GetServicioActivo", "facturelo").then((res) => {
          //  console.log(res);
            if (res.data) {
                setPlanesData({
                    data: res.data.objetosList.slice(0, 3),
                    cargando: false,
                });

            } else {
                // let err = "-Planes Facturelo: Acción Interrumpida.\n " + res.error;
                //console.log(err,res)
                setPlanesData({
                    data: null,
                    cargando: false,
                });
            }
        }).catch((e) => {
            // let err = "-Planes Facturelo: Acción Interrumpida.\n " + e.message;
            //console.log(err)
            setPlanesData({
                data: null,
                cargando: false,
            });
        });

    }, [])



    return (
        <>
            {planesData.data != null && planesData.data.length > 0 ?
                <section className="pricing-area mt-4 bg-f9f6f6">
                    <div className="container">
                        <div className="section-title">
                                <div className="logoFac"></div>
                            <p>
                                Ahora puede comprar y recargar su cuenta de Factura Electrónica  desde acá.
                            </p>
                        </div>

                        <div className="tab pricing-tab bg-color">
                            <div className="tab_content pb-5 pt-5">
                                <div className="tabs_item">
                                    <div className="row">

                                        {planesData.data.map(cardDato => (

                                            <CardProducto key={cardDato.id} dato={cardDato} />
                                        ))}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                : null
            }
        </>);
};

export default PlanesFacturelo;