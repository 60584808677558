import React, { useState,useEffect } from "react";
import CarouselSlick from "./CarouselSlick";
import { img_default_cliente } from "../../images/imagesURL.js";
import { ListaEndpoints } from "../../config/Config";
import { Retrieve } from "../../services/ControlActions";
let clientesJsonEjemplo = [
    {
      id: 1,
      posicion: 1,
      nombre: "Cargando...",
      titulo: "Cargando...",
      comentario: "Cargando...",
      urlImagen: img_default_cliente,
    },
    {
      id: 2,
      posicion: 2,
      nombre: "Cargando...",
      titulo: "Cargando...",
      comentario: "Cargando...",
      urlImagen: img_default_cliente,
    },
    {
      id: 3,
      posicion: 3,
      nombre: "Cargando...",
      titulo: "Cargando...",
      comentario: "Cargando...",
      urlImagen: img_default_cliente,
    },
    {
      id: 4,
      posicion: 4,
      nombre: "Cargando...",
      titulo: "Cargando...",
      comentario: "Cargando...",
      urlImagen: img_default_cliente,
    },
    {
      id: 5,
      posicion: 5,
      nombre: "Cargando...",
      titulo: "Cargando...",
      comentario: "Cargando...",
      urlImagen: img_default_cliente,
    },
    {
      id: 6,
      posicion: 6,
      nombre: "Cargando...",
      titulo: "Cargando...",
      comentario: "Cargando...",
      urlImagen: img_default_cliente,
    },
  ];
const CarouselClientes = ({setAlert, alert}) => {
    const [clienteData, setClienteData] = useState({
        data: null,
        cargando: true,
      });

      useEffect(() => {
        
        if (clienteData.cargando) {
            Retrieve(ListaEndpoints.CLIENTE_FAV, "GetClientesActivos")
              .then((res) => {
                if (res.data) {
                  setClienteData({ data: res.data, cargando: false });
                } else {
                  let err = "-Clientes: Acción Interrumpida.\n " + res.error;
                  
                  setAlert({
                    mensaje: alert.mensaje + err,
                    error: true,
                  });
                  setClienteData({ data: null, cargando: false });
                }
              }).catch((e) => {
                let err =
                  "-Clientes: No pudo conectar al servidor, inténtelo de nuevo más tarde. \n"
                setAlert({
                  mensaje: alert.mensaje + err,
                  error: true,
                });
                setClienteData({ data: null, cargando: false });
              });
          }
      

      }, [clienteData])
      
    

    return (
        
            <CarouselSlick datos={clienteData.cargando || 
                clienteData.data ==null? clientesJsonEjemplo : clienteData.data }></CarouselSlick>
        
    );
}
export default CarouselClientes;