import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import ErrorForm from "./ErrorForm";
import ListaDropdown from "./ListaDropdown";
import moment from "moment";
import {
  COD_APLICACION,
  ListaEndpoints,
  ERROR_REQUERIDO,
  REGEX_VALIDACION_EMAIL,
  ERROR_REGEX_EMAIL,
  REGEX_VALIDACION_TEL,
  ERROR_REGEX_TEL
} from "../../config/Config";
import { Create, Retrieve } from "../../services/ControlActions";
import { useRef } from "react";

const FormRegistro = ({ endpoint, titulo, history }) => {
  const { register, handleSubmit, errors, reset, setValue } = useForm();

  const [paises, setPaises] = useState([
    { 188: "Costa Rica" },
    { 187: "Estados Unidos" },
  ]);

  const paisUsuario = useRef(0);

  const [cargando, setCargando] = useState(false);
  const [enviado, setEnviado] = useState(false);
  const [mensajeRespuesta, setMensajeRespuesta] = useState({
    error: false,
    mensaje: "",
  });

  // UseEffect para seleccionar pais del usuario
  useEffect(() => {

    if (paisUsuario.current !== 0) {

      setValue("pais", seleccionarPais());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paises]);

  const seleccionarPais = () => {

    let res = paises.find((pais) => {
      return Number(Object.keys(pais)[0]) === paisUsuario.current;
    });

    if (res) {
      return Object.keys(res)[0];
    } else {
      return "";
    }
  };
  const onSubmit = (data) => {

    let obj = {
      Codigo: 0,
      Email: data.email,
      Nombre: data.nombre || '""',
      Compannia: '""',
      Password: '""',
      Telefono: data.telefono,
      Pais: data.pais,
      Identificacion: '""',
      UltimoAcceso: moment.utc().toISOString(),
      Pregunta: '""',
      Respuesta: '""',
      Actividad: 1,
      estaConfirmada: false,
      Confirmacion: '""',
      Aplicacion: COD_APLICACION,
      PasswordConfirm: '""',
      Comentario: '""',
    };



    consultarAPI(obj);
  };

  // UseEffect inicial
  useEffect(() => {
    // Carga Paises
    Retrieve(ListaEndpoints.USUARIO, "GetPaises").then(
      (res) => {
        if (res.data) {
          // Carga y formatea paises para el dropdown
          let respPaises = res.data?.listaPaises;
          paisUsuario.current = res.data?.codPaisUsuario
            ? res.data?.codPaisUsuario
            : 0;
          let objPaisesTemp = [];

          for (const pais of respPaises) {
            let paisTemp = { [pais.pai_isoNum]: pais.pai_Nombre };

            objPaisesTemp.push(paisTemp);
          }

          setPaises(objPaisesTemp);
          // fin CargaPaises
        } else {

        }
      },
      (error) => {
       // console.error("Ha ocurrido un error al traer los países.", error);
        console.error("Ha ocurrido un error al traer los países.");
        setCargando(false);
      }
    );
  }, []);

  const consultarAPI = async (data) => {
    setEnviado(false);
    setCargando(true);



    Create(ListaEndpoints.USUARIO, "Registro", "", data).then(
      (res) => {
        setEnviado(true);

        if (res.data) {

          setMensajeRespuesta({ error: false, mensaje: res.msg });
          reset();
          if (paisUsuario.current !== 0) {

            setValue("pais", seleccionarPais());
          }

        } else {

          console.error(res.error, res.errorDescription);
          setMensajeRespuesta({ error: true, mensaje: res.errorDescription });
          setCargando(false);
        }
      },
      (error) => {
        console.error("Ha ocurrido un error.", error);

        setCargando(false);
      }
    );
  };

  // TODO: Borrar cuentas
  // relmigulmo@enayu.com
  // sitribamla@enayu.com
  // hospusilma@enayu.com

  return (
    <>
      <section>
        <div className="container-fluid h-100 justify-content-center align-items-center mt-1 mb-5">
          <div className="row h-100 justify-content-center align-items-center">
            <form onSubmit={handleSubmit(onSubmit)} className="col-md-12">
              <fieldset>
                <h2>{titulo}</h2>

                <div className="form-row">
                  <div className="form-group col-md-12">
                    <label>Correo Electrónico</label>

                    <input
                      type="text"
                      className={
                        "form-control " + (errors.email ? "input-error" : null)
                      }
                      name="email"
                      placeholder="Correo electrónico"
                      ref={register({
                        required: ERROR_REQUERIDO,
                        pattern: {
                          value: REGEX_VALIDACION_EMAIL,
                          message: ERROR_REGEX_EMAIL,
                        },
                      })}
                    />

                    {errors.email ? (
                      <ErrorForm mensaje={errors.email.message} />
                    ) : null}
                  </div>
                  <div className="form-group col-md-12">
                    <label>Nombre Completo</label>

                    <input
                      type="text"
                      className={
                        "form-control " + (errors.nombre ? "input-error" : null)
                      }
                      name="nombre"
                      placeholder="Nombre"
                      ref={register({ required: ERROR_REQUERIDO })}
                    />

                    {errors.nombre ? (
                      <ErrorForm mensaje={errors.nombre.message} />
                    ) : null}
                  </div>
                </div>

                <div className="form-row">
                  <div className="form-group col-md-7">
                    <label>Teléfono</label>

                    <input
                      type="tel"
                      className={
                        "form-control " +
                        (errors.telefono ? "input-error" : null)
                      }
                      name="telefono"
                      placeholder="(506) 88888888"
                      ref={register({
                        required: ERROR_REQUERIDO,
                        pattern: {
                          value: REGEX_VALIDACION_TEL,
                          message: ERROR_REGEX_TEL,
                        },
                      })}
                    />

                    {errors.telefono ? (
                      <ErrorForm mensaje={errors.telefono.message} />
                    ) : null}
                  </div>

                  <div className="form-group col-md-5">
                    <div className="select-box">
                      <label>País</label>

                      <select
                        className={
                          "form-control " + (errors.pais ? "input-error" : null)
                        }
                        name="pais"
                        ref={register({ required: ERROR_REQUERIDO })}
                      >
                        <ListaDropdown opciones={paises} />
                      </select>
                    </div>

                    {errors.pais ? (
                      <ErrorForm mensaje={errors.pais.message} />
                    ) : null}
                  </div>
                </div>

                {enviado ? (
                  mensajeRespuesta.error ? (
                    <div className="alert alert-danger text-center p-2 animated fadeInUp faster mb-3">
                      {mensajeRespuesta.mensaje}
                    </div>
                  ) : (
                      <div className="alert alert-success text-center p-2 animated fadeInUp faster mb-3">
                        {mensajeRespuesta.mensaje}
                      </div>
                    )
                ) : null}

                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={cargando}
                >
                  Enviar
                </button>
              </fieldset>
            </form>
          </div>
        </div>
      </section>

      <div className="bg-info">
        <div className="container">
          <div className="row"></div>
        </div>
      </div>
    </>
  );
};

export default FormRegistro;
