import React, { Component } from "react";
import "../../css/WidgetTarjetas.css";
import $ from "jquery";
import {
  PostWithToken,
  GetWithToken,
  SweetAlert,
  SwalToast
} from "../../services/ControlActions";
import { ListaEndpoints, MODENA} from "../../config/Config";
window.$ = window.jQuery = $;
// require("jquery");

class WidgetTarjetas extends Component {
  constructor(props) {
    super(props);


    this.state = {
      cargando: true,
      msgError: {
        error: false,
        mensaje: "",
      },
    };
    this.handleClose = this.handleClose.bind(this);
    //console.log("c", this.props.mostrarWidget);
  }

  // response.ResponseCode !== T00
  SaveCreditCard_FailureCallback = (response) => {

    this.ValidarError(response);
  };

  // response.ResponseCode === T00: SUCCESS
  SaveCreditCard_SuccessCallback = (response) => {

    this.AgregarToken(response);
  };

  // Usuario da click al botón cancelar cuando trató de agregar un token
  SaveCreditCard_CancelCallback = () => {

   // console.log(this.props.mostrarWidget);
    // this.props.setMostrarWidget(false);

    this.props.setMostrarWidget(false);
  };

  componentWillUnmount() {
    this.handleClose();
  }

  componentDidUpdate(propsAnteriores) {
    if (propsAnteriores.token !== this.props.token) {
     // console.log("Se actualiza widget", propsAnteriores);
      $(".contenedor-widget").slideUp(200);
      $(".contenedor-widget").html("");
      this.GetWidget(
        this.props.moneda != null ? this.props.moneda : MODENA,
        this.props.token != null ? this.props.token : ""
      );
    }
  }

  componentDidMount() {
    window.SaveCreditCard_FailureCallback = this.SaveCreditCard_FailureCallback;
    window.SaveCreditCard_SuccessCallback = this.SaveCreditCard_SuccessCallback;
    window.SaveCreditCard_CancelCallback = this.SaveCreditCard_CancelCallback;

    this.GetWidget(
      this.props.moneda != null ? this.props.moneda : MODENA,
      this.props.token != null ? this.props.token : ""
    );
  }

  handleClose = () => {
    this.props.setMostrarWidget(false);
  };

  GetWidget(moneda = MODENA, token = "") {
 

    if (moneda !== null && token !== null) {
      let objWidget = {
        token: token,
        moneda: moneda,
      };

      this.setState({ cargando: true });


      GetWithToken(ListaEndpoints.PAGO, "CargarWidget", "", null, objWidget)
        .then(
          (res) => {
            //setEnviado(true);

            if (res.data) {
              let resp = res.data.obj;

              $(".contenedor-widget").html(resp);
              $(".contenedor-widget").slideDown(500);

              this.setState({ cargando: false });

              //setMensajeRespuesta({ error: false, mensaje: data.Message });
              // setCargando(false);
              // setModoEditar(false);
              $(".contenedor-widget").slideDown(500);
            } else {
              console.error("Tarjeta", res.error, res.errorDescription);
              this.setState({
                msgError: { error: true, mensaje: res.errorDescription },
              });

              let err = "Consulta:  " + res.error;
              SweetAlert(
                3,
                "Acción Interrumpida al cargar la tarjeta, intentelo de nuevo más tarde",
                err,
                "",
                "Entendido",
                () => {}
              );
              // setCargando(false);
            }
          },
          (error) => {
            let err =
              "Tarjetas: No pudo conectar al servidor, inténtelo de nuevo más tarde. " +
              error;
            this.setState({
              msgError: { mensaje: error, error: true },
            });
            SweetAlert(3, "Acción Interrumpida", err, "", "Entendido", () => {});
          }
        )
        .catch((e) => {
          this.setState({ cargando: false });
          let err =
            "Tarjetas: No pudo conectar al servidor, inténtelo de nuevo más tarde.  " +
            e.message;
          this.setState({
            msgError: { mensaje: e, error: true },
          });
          SweetAlert(3, "Acción Interrumpida", err, "", "Entendido", () => {});
        });
    }
  }

  ValidarError(respWidget) {
    if (respWidget === null) return null;

    switch (respWidget.ResponseCode) {
      case "T01": // Internal error
        alert("BAC : " + respWidget.ResponseDescription);
        break;

      // Aunque ya no debería de mostrar nunca este error por un cambio que se solicitó,
      // se deja por si ocurriera el caso de que el token lo muestre como repetido.
      case "T02": // Account / Token already exists
        alert("TOKEN DUPLICADO: " + respWidget.ResponseDescription);

        this.AgregarToken(respWidget);
        break;

      default:
        
        alert("Acción interrumpida: " + respWidget.ResponseDescription);
    }
  }

  AgregarToken(respWidget) {
    if (respWidget === null) return null;

    this.setState({ cargando: true });
    $(".contenedor-widget").hide();

    let objWidget = {
      token: respWidget.TokenDetails.AccountToken,
      numero_Tarjeta: respWidget.TokenDetails.CardNumber,
      cta_Cuenta: this.props.usuario.Codigo,
      cta_Email: this.props.usuario.Email,
      moneda: MODENA,
    };



    PostWithToken(ListaEndpoints.PAGO, "addToken", "", objWidget)
      .then(
        (res) => {
          if (res.data !== null) {
            if (res.data === true) {
              this.props.setRefrescandoTokens(true);
             

              SwalToast(
                2,
                "Tarjeta agregada correctamente",
                () => { },
                3000
              );
            } else {
              SwalToast(
                2,
                "Ha ocurrido un error al registrar la tarjeta.",
                () => { },
                3000
              );
         
            }

            $(".contenedor-widget").slideUp(200);
            $(".contenedor-widget").html("");
          } else {

          //  console.log(res.error, res.errorDescription);

            SweetAlert(
              3,
              res.error,
              `Error: ${
                res.errorDescription?.msg
                  ? res.errorDescription?.msg
                  : "No se pudo agregar la tarjeta"
              }.`,
              "",
              "Entendido",
              () => {}
            );
          }
          this.props.setMostrarWidget(false);
          this.setState({ cargando: false });
        },
        (error) => {
          let err =
            "Tarjetas: No pudo conectar al servidor, inténtelo de nuevo más tarde. " +
            error;
          this.setState({
            msgError: { mensaje: error, error: true },
          });
          SweetAlert(3, "Acción Interrumpida", err, "", "Entendido", () => {});
        }
      )
      .catch((e) => {
        this.setState({ cargando: false });
        let err =
          "Tarjetas: No pudo conectar al servidor, inténtelo de nuevo más tarde. Error: " +
          e.message;
        this.setState({
          msgError: { mensaje: e, error: true },
        });
        SweetAlert(3, "Acción Interrumpida", err, "", "Entendido", () => {});
      });
  }

  render() {
    return (
      <div className="py-2">
        {this.state.msgError.error ? <h5>Ha ocurrido un error.</h5> : null}
        {this.state.cargando ? (
          <h5>Cargando...</h5>
        ) : (
          <h5 className="mb-3 text-left">
            {this.props.token ? "Información de tarjeta" : "Agregando tarjeta:"}
          </h5>
        )}
        <div className="contenedor-widget" />
      </div>
    );
  }
}

export default WidgetTarjetas;
