import React, { useEffect } from "react";
import { withRouter } from 'react-router-dom';


const SingleFeatures = (props,) => {

    const { history } = props;
    useEffect(() => {

    });



    return (
        <div className={props.clase}>
            <div className="single-features row" onClick={props.callback}>
                <div className="icon ">
                    {props.children}
                </div>
                <h3 className="pt-3 col-10"><a>{props.titulo}</a></h3>
                <p>{props.descripcion}</p>
            </div>
        </div>
    )
}
export default withRouter(SingleFeatures);