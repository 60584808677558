import React, { useState, useEffect } from "react";
/* Prefiero manipularlos lo minimo, por lo que estan practicamente intactos. */
/*primero importo los css de la plantilla*/
import "./css/bootstrap.min.css";//general
import "./css/slick.min.css";//de clientes
import "./css/style.css";//general
import "./css/responsive.css";

import "bootstrap";
// Selecciona espaniol como el idioma por defecto del momentjs
import "moment/locale/es";

import Layout from "./components/Layout/Layout";
import { Route, Switch } from "react-router-dom";
/*pages*/
import LandingPage from "./pages/LandingPage";
import Servicios from "./pages/Servicios";
import ServiciosList from "./pages/ServiciosList";
import Contacto from "./pages/Contacto";
import Nosotros from "./pages/Nosotros";
import Registro from "./pages/Registro";
import Login from "./pages/Login";
import CarritoPage from "./pages/CarritoPage";
import HistorialPedidos from "./pages/HistorialPedidos";
import Pedido from "./pages/Pedido";
import Distribuidores from "./pages/Distribuidores";
import DesactivarNotificacion from "./pages/DesactivarNotificacionPage";

import $ from "jquery";
import { ArrowUp } from "react-feather";
import { Retrieve, SweetAlert } from "./services/ControlActions";
import ConfirmacionUsuario from "./pages/ConfirmacionUsuario";
import ErrorPage from "./pages/ErrorPage";

import PerfilPage from "./pages/PerfilPage";
import PoliticasPrivacidadPage from "./pages/PoliticasPrivacidadPage";
import TerminosCondicionesPage from "./pages/TerminosCondicionesPage";
import PagoPage from "./pages/PagoPage";
import AlertMessage from "./components/Errors/AlertMessage";
import Mantenimientos from "./pages/Mantenimientos";
import AdmServicios from "./pages/AdmServicios";
import AdmClientes from "./pages/AdmClientes";
import AdmProductos from "./pages/AdmProductos";
import AdmImagenes from "./pages/AdmImagenes";
import AdmPedidos from "./pages/AdmPedidos";
import AdmPromociones from "./pages/AdmPromociones";
import Planes from "./pages/Planes";
import RegistroPromocion from "./pages/RegistroPromocion";


import { ListaEndpoints, DOCUMENTO_DISTRIBUIDORES } from "./config/Config";
import { useHistory } from "react-router-dom";

const App = () => {
  let history = useHistory();
  const [datos, setDatos] = useState([]);
  const [cargando, setCargando] = useState(true);
  const [mensajeAlerta, setMensajeAlerta] = useState({ mensaje: "", error: false });

  //#region data servicios
  useEffect(() => {
    // setCargando(true);
    if (cargando) {
      Retrieve(ListaEndpoints.SERVICIO, "GetServiciosActivos").then((res) => {
        if (res != null) {
          if (res.data) {
            if (res.data?.length > 0) {
              setDatos(res.data);

            }
          } else {
            let err = "Servicios: Acción Interrumpida.\n  " + res.error;
            setMensajeAlerta({ mensaje: err, error: true });

          }
        } else {
          let mensaje = "No hay servicios disponibles. \n Respuesta nula"
          SweetAlert(3, "Acción Interrumpida", mensaje, "", "Ok", () => { })

        }
      }).catch((e) => {
        let err = "Servicios: No pudo conectar al servidor, inténtelo de nuevo más tarde.\n "
        setMensajeAlerta({ mensaje: err, error: true })
        //  $("html, body").animate({ scrollTop: "0" }, 10);
      });
      setCargando(false);
    }


  }, []);
  //#endregion
  //#region go to top
  // Go to Top
  $(() => {
    //Scroll event
    $(window).on("scroll", () => {
      var scrolled = $(window).scrollTop();
      if (scrolled > 300) $(".go-top").fadeIn("slow");
      if (scrolled < 300) $(".go-top").fadeOut("slow");
    });
    //Click event
    $(".go-top").on("click", () => {
      $("html, body").animate({ scrollTop: "0" }, 500);
    });
  });
  //#endregion
  return (
    <>

      <Layout datosServicio={datos} cargandoServicios={cargando}>
        {mensajeAlerta.error ? (
          <>
            <AlertMessage title={""} message={mensajeAlerta.mensaje} tipo={"alert-danger"} />
          </>
        ) : null}
        <Switch>
          <Route exact path="/" component={LandingPage} />

          <Route path="/Servicios/:id" component={Servicios} />
          <Route
            path="/Servicios"
            render={(props) => (
              <ServiciosList {...props} cargando={cargando} datos={datos} />//JBL123 para que los 3 puntos
            )}
          />

          <Route
            path="/confirmar-cuenta/:idUsuario/:token"
            component={ConfirmacionUsuario}
          />
          <Route path="/privacidad" component={PoliticasPrivacidadPage} />
          <Route path="/terminos" component={TerminosCondicionesPage} />
          <Route path="/perfil" component={PerfilPage} />
          <Route path="/distribuidores" component={Distribuidores} />
          <Route path="/nosotros" component={Nosotros} />
          <Route path="/sigma" component={LandingPage} />
          <Route path="/contacto" component={Contacto} />
          <Route path="/carritoPage" component={CarritoPage} />
          <Route target="_blank" path="/historialPedidos" component={HistorialPedidos} />
          <Route path="/planes" component={Planes} />
          <Route path="/pedido/:id" component={Pedido} />
          <Route path="/pago" component={PagoPage} />
          <Route path="/desactivarNotificacion/:cuenta/:plan" component={DesactivarNotificacion} />

          {/*<Route path='/redirectPago' component={() => {
            window.location.href = URL_SIGMA + 'redirectPago';
            return null;
          }} />*/}
          <Route path="/registro" component={Registro} />
          <Route path="/login" component={Login} />
          <Route path='/facebook' component={() => {
            window.location.href = 'https://www.facebook.com/SigmaCostaRica/';
            return null;
          }} />
          <Route path='/instagram' component={() => {
            window.location.href = 'https://www.instagram.com/sigma_asesoriascontables/';
            return null;
          }} />
          <Route path='/linkedin' component={() => {
           
            window.location.href = 'https://cr.linkedin.com/';
            return null;
          }} />
          <Route path='/whatsapp' component={() => {
            window.location.href = 'https://wa.me/c/50689918111';
            return null;
          }} />
          <Route path='/facel' component={() => {
            window.location.href = 'https://www.facelcr.com/index.html#/home';
            return null;
          }} />
          <Route path='/facturelo' component={() => {
            window.location.href = 'https://facturelo.com/';
            return null;
          }} />
          <Route path='/millenium' component={() => {
            window.location.href = 'https://technosoftcr.com/ContadorMillenium.aspx';
            return null;
          }} />
          <Route path='/pdfDistribuidores' component={() => {
            window.location.href = DOCUMENTO_DISTRIBUIDORES;
            // window.location.href = 'D:\Technosoft\Sigmacr.net\jBlanco\Technosoft.Sigma.UI\ClientApp\public\prueba.pdf'
            return null;
          }} />
          {/*Aqui inician los matenimientos*/}
          <Route path="/mantenimientos" component={Mantenimientos} />
          <Route path="/adm/clientes" component={AdmClientes} />
          <Route path="/adm/publicidad" component={AdmImagenes} />
          <Route path="/adm/servicios/productos/:id" component={AdmProductos} />
          <Route path="/adm/servicios" component={AdmServicios} />
          <Route path="/adm/pedidos" component={AdmPedidos} />
          <Route path="/adm/promociones/:id" component={RegistroPromocion} />
          <Route path="/adm/promociones" component={AdmPromociones} />

          <Route path="*" component={ErrorPage} />
        </Switch>

      </Layout>
      <div className="go-top" style={{ zIndex: 100 }}>
        <ArrowUp />
      </div>

    </>
  );
};

export default App;
