import React from "react";
import FormSolicitudInfo from "../components/Formularios/FormSolicitudInfo";
import { Mail, MapPin, Phone } from "react-feather";
import { imagen1 } from "../images/imagesURL";
import EncabezadoFiguras from "../components/Visuales/EncabezadoFiguras";
import EncabezadoDescripcion from "../components/Visuales/EncabezadoDescripcion";
import MapaGoogle from "../components/MapaGoogle";
import { GOOGLE_MAP_API_KEY } from "../config/Config";

const Contacto = () => {
  let api_key_maps = GOOGLE_MAP_API_KEY;

  return (
    <>
      <EncabezadoFiguras titulo="Contáctenos" />

      <section className="contact-info-area ptb-80">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-4 col-sm-12">
              <div className="contact-info-box">
                <div className="icon">
                  <Mail />
                </div>
                <h3>Escríbanos</h3>
                <p>
                  info@sigmacr.net
                </p>
              </div>
            </div>

            <div className="col-lg-4 col-md-4 col-sm-12">
              <div className="contact-info-box">
                <div className="icon">
                  <MapPin />
                </div>

                <h3>Visítenos</h3>
                <p>
                  San Pedro de Montes de Oca, Barrio la Granja, de la
                  Embajada de Panamá 25m al este
                </p>
              </div>
            </div>

            <div className="col-lg-4 col-md-4 col-sm-12">
              <div className="contact-info-box">
                <div className="icon">
                  <Phone />
                </div>
                <h3>Llámenos</h3>
                <p>
                  +506 2291-7170
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <MapaGoogle
        googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${api_key_maps}`}
        containerElement={<div style={{ height: "500px" }}></div>}
        mapElement={<div id="mapaContacto" style={{ height: "100%" }}></div>}
        loadingElement={<h1>Cargando Mapa...</h1>}
        latitud="9.929151"
        longitud="-84.047390"
        isMarkerShown={true}
      />
      <EncabezadoDescripcion
        id="formContacto"
        titulo="¿Necesita más información?"
        descripcion="Lo que necesite, estaremos encantados de atenderle."
      />
      <section className="contact-area mb-5">
        <div className="container">


          <div className="row h-100 justify-content-center align-items-center">
            <div className="col-lg-6 col-md-12">
              <img src={imagen1} alt="fondo" />
            </div>

            <div className="col-lg-6 col-md-12 formContainer">
              <FormSolicitudInfo
                titulo={""}
                asunto={"Consulta general de la página de sigma"}
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Contacto;
