import React from "react";


const Carousel = ({data,id }) => {

  return (

    <>
      {  data === null ? ( null ) : (
          <>
            { data.length > 0 ? (
              <section className="contenedorCarousel our-latest-projects">
                <div className="carouselFrag">
                  <div id={id} className=" carousel slide" data-ride="carousel">

                    <ol className="carousel-indicators">

                      {data.map((imagen, index) => (
                        <li
                          key={index}
                          data-target={"#" + id}
                          data-slide-to={index}
                          className={index === 0 ? "active" : ""}
                        ></li>
                      ))}
                    </ol>
                    <div className="carousel-inner">

                      {data.map((imagen, index) => (
                        <div key={index} className={index === 0 ? "carousel-item active" : "carousel-item"} >
                          <img src={imagen.urlImagen} className="imagenCarousel" alt="..." />
                        </div>
                      ))}
                    </div>
                    <a
                      className="carousel-control-prev"
                      href={"#" + id}
                      role="button"
                      data-slide="prev"
                    >
                      <span
                        className="carousel-control-prev-icon"
                        aria-hidden="true"
                      ></span>
                      <span className="sr-only">Anterior</span>
                    </a>
                    <a
                      className="carousel-control-next"
                      href={"#" + id}
                      role="button"
                      data-slide="next"
                    >
                      <span
                        className="carousel-control-next-icon"
                        aria-hidden="true"
                      ></span>
                      <span className="sr-only">Siguiente</span>
                    </a>
                  </div>
                </div>
              </section>
            ) : (null)}
          </>
        )
      }
    </>
  );
};
export default Carousel;
