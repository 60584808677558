import React from "react";


const InfoTerminosYCondiciones = () => {
    return (
        <>
            <div className="about-content">

                <p>Revisión Abril 2022</p>
                <h2 className="mt-5" align="center">Condiciones de uso y Condiciones de Venta</h2>
                <h5 className="mb-2" >Condiciones de uso</h5>
                <p>Le rogamos que lea detenidamente las presentes condiciones antes de utilizar los Servicios de Sigma. Al utilizar los Servicios de Sigma, usted acepta quedar vinculado por las presentes condiciones. Ofrecemos una amplia gama de Servicios de Sigma y en ocasiones pueden aplicarse condiciones adicionales. Además, cada vez que utilice cualquier Servicio de Sigma, (por ejemplo: apertura de cuenta o aplicaciones de Sigma para dispositivos móviles), estará igualmente sujeto a los términos y condiciones generales y a las condiciones particulares aplicables a dichos Servicios de Sigma (las "Condiciones Generales de los Servicios"). Las Condiciones Generales de los Servicios prevalecerán sobre las presentes Condiciones de Uso en caso de discrepancia entre ambas.</p>

                <h2 align="center">Procedimiento y formulario de aviso de vulneración de derechos </h2>
                <h5 className="mb-2" >1. Privacidad </h5>
                <p>Le rogamos que revise nuestro Aviso de Privacidad, y nuestra página sobre Cookies y Publicidad en Internet, que también rigen el uso que realice de los Servicios de Sigma, para que conozca nuestras prácticas. </p>
                <h5 className="mb-2" >2. Comunicaciones electrónicas </h5>
                <p>Cada vez que utilice un Servicio de Sigma o nos envíe un correo electrónico, estará comunicándose electrónicamente con nosotros. Nosotros nos pondremos en contacto con usted mediante correo electrónico, mediante la publicación de avisos en nuestro sitio web o mediante los otros Servicios de Sigma. A efectos del presente contrato, usted acepta recibir comunicaciones de nuestra parte mediante medios electrónicos, y acepta que todos los contratos, avisos y otras notificaciones y comunicaciones que le enviemos por medios electrónicos satisfacen cualquier requisito de forma escrita, salvo que cualquier legislación aplicable con carácter imperativo exigiera una forma distinta de comunicación. </p>
                <h5 className="mb-2" >3. Derechos de autor, derechos de propiedad intelectual y derechos sobre bases de datos </h5>
                <p>
                    Todo contenido alojado o puesto a disposición en cualquiera de los Servicios de Sigma, como por ejemplo texto, gráficos, logotipos, iconos de botones, imágenes, clips de audio, descargas digitales, y recopilaciones de datos son propiedad de Sigma o de sus proveedores de contenido y está protegido por las leyes de la República de Costa Rica así como por la legislación internacional sobre derechos de propiedad intelectual, derechos de autor y derechos sobre bases de datos. El conjunto de todo el contenido albergado o puesto a disposición a través de cualquier Servicio de Sigma es propiedad exclusiva de Sigma, y está protegido por las leyes de la República de Costa Rica e internacionales sobre derechos de propiedad intelectual y derechos sobre bases de datos.
                    No está permitida la extracción sistemática ni la reutilización de parte alguna del contenido de ninguno de los Servicios de Sigma sin nuestro expreso consentimiento por escrito . En particular, no se permite el uso de herramientas o robots de búsqueda y extracción de datos para la extracción (ya sea en una o varias ocasiones) de partes sustanciales de los Servicios de Sigma para su reutilización sin nuestro expreso consentimiento por escrito. Tampoco le está permitido al usuario crear ni publicar sus propias bases de datos cuando éstas contengan partes sustanciales de cualquiera de los Servicios de Sigma (por ejemplo, nuestras listas de productos y listas de precios) sin nuestro expreso consentimiento por escrito.
                </p>

                <h5 className="mb-2" >4. Marcas registradas </h5>
                <p>Los gráficos, logotipos, encabezados de página, iconos de botón, scripts y nombres de servicio que aparecen incluidos o están disponibles a través de los Servicios de Sigma son marcas registradas o representan la imagen comercial de Sigma. No podrán utilizarse las marcas registradas ni la imagen comercial de Sigma en relación con ningún producto o servicio que no pertenezca a Sigma, ni en ninguna forma que fuera susceptible de causar confusión entre los usuarios o que pueda menospreciar o desacreditar a Sigma. El resto de marcas registradas que no son propiedad de Sigma y que aparecen incluidos o están disponibles a través de los Servicios de Sigma pertenecen a sus respectivos propietarios, quienes podrán o no estar afiliados o relacionados de cualquier modo con Sigma, o patrocinados por Sigma. </p>
                <p></p>
                <h5 className="mb-2" >5. Patentes </h5>
                <p>Diversas patentes pertenecientes a Sigma son aplicables a los Servicios de Sigma así como a las funciones y servicios accesibles a través de los mismos. Por otro lado, diferentes partes de los Servicios de Sigma operan en virtud de distintas licencias de una o varias patentes. </p>
                <h5 className="mb-2" >6. Licencia y acceso </h5>
                <p>
                    Sujeto al cumplimiento por usted de estas Condiciones de Uso y las Condiciones Generales de los Servicios aplicables así como al pago del precio aplicable, en su caso, Sigma o sus proveedores de contenidos le conceden una licencia limitada no exclusiva, no transferible y no sublicenciable, de acceso y utilización, a los Servicios de Sigma para fines personales no comerciales. Dicha licencia no incluye derecho alguno de reventa ni de uso comercial de los Servicios de Sigma ni de sus contenidos, ni derecho alguno a utilizar lista alguna de productos, descripciones o precios. Tampoco incluye el derecho a realizar ningún uso derivado de los Servicios de Sigma ni de sus contenidos, ni a descargar o copiar información de cuenta alguna para el beneficio de otra empresa, ni el uso de herramientas o robots de búsqueda y extracción de datos o similar.
                    Sigma y sus licenciantes, proveedores, editores, titulares de derechos u otros proveedores de contenidos se reservan cualquier derecho que no esté expresamente comprendido en estas Condiciones de Uso o en las Condiciones Generales de los Servicios.
                    No está permitida la reproducción, duplicación, copia, venta, reventa o explotación de ningún tipo de los Servicios de Sigma ni de parte alguna de los mismos con fines comerciales, en cada caso sin nuestro previo consentimiento por escrito.
                    Tampoco está permitido utilizar técnicas de framing para introducir cualquier marca comercial, logotipo u otra información protegida por derechos de autor (incluyendo imágenes, texto, diseños de página o formatos) de Sigma sin el correspondiente consentimiento previo por escrito. No está permitido el uso de metaetiquetas (meta tags) ni de ningún otro "texto oculto" que utilice los nombres o marcas registradas de Sigma sin nuestro consentimiento previo y por escrito.
                    Le rogamos que haga un uso correcto de los Servicios de Sigma. Sólo le está permitido utilizar los Servicios de Sigma de forma lícita. Cualquier incumplimiento por Ud. de estas Condiciones de Uso o de las Condiciones Generales de los Servicios supondrá la anulación del permiso o la licencia concedidos por Sigma.
                </p>
                <p></p>
                <h5 className="mb-2" >7. Su Cuenta </h5>
                <p>
                    Cuando usted utiliza los Servicios de Sigma es responsable de mantener la confidencialidad de los datos de su cuenta y su contraseña, así como de restringir el acceso a su ordenador. En la medida en que así lo permita la legislación aplicable, usted acepta asumir la responsabilidad que proceda por todas las actividades realizadas desde su cuenta o utilizando su contraseña. Usted deberá tomar todas las medidas necesarias a efectos de asegurar y salvaguardar la confidencialidad de su contraseña, y deberá informarnos inmediatamente en caso de que tenga motivos para creer que su contraseña ha sido puesta en conocimiento de un tercero, o si ésta ha sido utilizada de manera no autorizada o es susceptible de serlo. Es su responsabilidad comprobar que los datos que nos facilita son correctos y completos, viniendo asimismo obligado a informarnos inmediatamente cuando tenga lugar cualquier variación en la información que nos ha facilitado. Puede acceder a su información y actualizarla a través del apartado Mi Cuenta del sitio web.
                    No podrá utilizar ningún Servicio de Sigma: (i) en forma alguna que cause, o pueda causar, daño o perjuicio alguno a cualquiera de los Servicios de Sigma o la interrupción del acceso a los mismos; o (ii) para cualquier fin fraudulento, ni a efectos de la comisión de delito alguno u otra actividad ilícita de ningún otro tipo; o (iii) para generar cualquier tipo de molestia, inconveniente o ansiedad en un tercero.
                    Nos reservamos el derecho a denegar el acceso al servicio, a cancelar cualesquiera cuentas y a eliminar o modificar cualquier contenido en caso de que el usuario incumpliera la legislación aplicable, las presentes Condiciones de Uso o cualesquiera otros términos y condiciones o políticas aplicables.
                </p>
                <h5 className="mb-2" >8. Pago electrónico, cancelación y devolución </h5>
                <p>Los SERVICIOS DE Sigma son ofrecidos para el USUARIO, éste libremente decide cual producto o paquete y cuánta cantidad solicite a través del SITIO WEB en el momento de realizar la compra, sin presión y sin conocimiento de Sigma hasta que el USUARIO ejecute la compra y se aplique el método de pago mediante el ingreso de una tarjeta de crédito o débito. Sigma se reserva el criterio de realizar devoluciones en plena fuerza y vigor de estos términos descritos.</p>
                <p>Sigma utilizará el SITIO WEB para facilitarle al USUARIO una forma de pago electrónico seguro y directo con la entidad Bancaria. Los pagos son finales y por lo tanto no se permitirán devoluciones ni cancelaciones mediante el SITIO WEB. Sigma no activará planes de facturación o brindará los productos de software comprados a través del SITIO WEB si el pago no fue procesado de manera exitosa cuando se realice a través del SITIO WEB.</p>
                <p>Si desea realizar cambio de productos de software adquiridos a través del SITIO WEB, se aclara que los costos y cargos administrativos deben ser asumidos por el USUARIO. Lo anterior no afecta las garantías legales y demás derechos reconocidos al consumidor por la legislación vigente. Si desea realizar cambio de productos de software adquiridos a través del SITIO WEB en caso de solicitar el cambio por un producto de mayor valor al efectivamente pagado y/o características diferentes, el USUARIO deberá pagar el valor del excedente que resultare necesario para cubrir el precio del nuevo producto. Si el Usuario solicita una devolución Sigma se reserva el criterio de realizar devoluciones, de aprobar la devolución se procesará en los siguientes 30 días hábiles en la misma tarjeta con la cual se realizó la compra por parte el USUARIO y verificada por SIGMA S.A., deduciendo los costos y cargos administrativos hasta por un costo de $8 USD al tipo de cambio del día de la devolución, mismo que asumirá el USUARIO. </p>
                <p>Sigma presta servicios mediante el SITIO WEB únicamente como agente intermediario entre el USUARIO y la entidad Bancaria, Usted libera a Sigma sobre cualquier responsabilidad ante cualquier falla o falta de cumplimiento por parte de la entidad Bancaria en el caso de transacciones fallidas o problemas directamente con la tarjeta.</p>

                <p></p>
                <h5 className="mb-2" >9. Opiniones, comentarios, comunicaciones y otros contenidos </h5>
                <p>
                    Los usuarios podrán publicar sus opiniones y comentarios así como publicar otros contenidos, enviar comunicaciones, sugerencias, ideas, comentarios, preguntas u otra información, siempre que el contenido de las mismas no resulte ilícito, obsceno, abusivo, constituya un supuesto de amenaza o difamación, o invada la privacidad de terceros, infrinja derechos de propiedad intelectual o de cualquier otra forma resulte ofensivo para terceros o censurable, ni consista en o contenga virus informáticos, propaganda política o contenido publicitario, correos en cadena, envío masivo de correos o constituya cualquier otro tipo de "spam". No está permitido el uso de direcciones de correo falsas, ni suplantar la identidad de otra persona o entidad, ni falsear de cualquier otro modo el origen de cualquier otro contenido. Nos reservamos el derecho (sin que no obstante, y en ausencia de un Formulario de Aviso válido, venga obligada a ello) a eliminar o modificar cualquier contenido. Si considera que cualquier contenido de los Servicios de Sigma o cualquier material cuya venta estuviera publicitada en los mismos incluye cualquier afirmación difamatoria, o si entendiera que sus derechos de propiedad intelectual están siendo infringidos por cualquier elemento o información disponible en los Servicios de Sigma.
                    Usted declara y garantiza ser titular de cualquier otra forma controlar la totalidad de los derechos sobre el contenido que pudiera almacenar en nuestra base de datos, manifestando y garantizado asimismo que a la fecha de la remisión de dicho contenido: (i) dicho contenido y material es exacto; y que (ii) la utilización de dicho contenido o material no supondrá incumplimiento alguno de las políticas o directrices aplicables de Sigma, ni supondrá perjuicio alguno a ninguna persona o entidad (garantizando asimismo que el contenido o material en cuestión no es de carácter difamatorio). Usted se compromete a liberar a Sigma de cualesquiera reclamaciones presentadas por terceros contra Sigma derivadas de o en relación con dicho contenido y materiales, salvo en la medida en que dicha responsabilidad derive de la falta de supresión adecuada por nuestra parte de dicho contenido o material tras haber sido notificados del carácter ilícito de dicho contenido o material.
                </p>
                <p></p>
                <h5 className="mb-2" >10. Reclamaciones sobre Propiedad Intelectual </h5>
                <p>Sigma respeta la propiedad intelectual de terceros. Si considera que sus derechos de propiedad intelectual han podido ser infringidos, le rogamos que haga uso de nuestra Política y Procedimiento de Reclamaciones por Infracción. </p>
                <h5 className="mb-2" >11. Condiciones Generales sobre el Software de Sigma </h5>
                <p>Todo software, incluidas las actualizaciones, mejoras y cualquier otra documentación relacionada con dicho software, que ponemos a su disposición en cualquier momento en relación con los Servicios de Sigma (el "Software de Sigma") está sujeto, además de a las presentes Condiciones de Uso y Venta, a las condiciones de Licenciamiento. </p>

                <h5 className="mb-2" >12. Otros negocios </h5>
                <p>Además de Sigma, distribuidores y aliados estratégicos ofrecen sus servicios a través del presente sitio web. De forma adicional, ofrecemos enlaces a los sitios web de sociedades de nuestro grupo y de otras empresas. No somos responsables de examinar o calificar, ni en ningún caso garantizamos, las ofertas de ninguna de tales empresas o personas físicas, ni el contenido alojado en sus respectivos sitios web. No asumimos responsabilidad alguna por las acciones, productos y contenidos de ninguna de tales empresas o individuos o cualesquiera otros terceros. El cliente siempre estará informado sobre si un tercero participa en su operación y podremos compartir con dicho tercero cualquier información relativa a dicha operación. Le sugerimos que revise detenidamente las declaraciones de privacidad y demás condiciones de uso de tales terceros. </p>
                <h5 className="mb-2" >13. Nuestra responsabilidad </h5>
                <p>
                    Realizaremos nuestros mejores esfuerzos para asegurar la disponibilidad, sin interrupciones, de los Servicios de Sigma, así como la ausencia de errores en cualquier transmisión de información que pudiera tener lugar. No obstante, y debido a la naturaleza misma de Internet, no es posible garantizar tales extremos. Asimismo, su acceso a los Servicios de Sigma pudiera ocasionalmente verse suspendido o restringido a efectos de la realización de trabajos de reparación o mantenimiento, o la introducción de nuevos productos o servicios. Procuraremos limitar la frecuencia y duración de tales suspensiones o restricciones.
                    Sigma no será responsable de (i) cualesquiera pérdidas que no fueran atribuibles a incumplimiento alguno por su parte, (ii) pérdidas empresariales (incluyendo lucro cesante, de ingresos, de contratos, de ahorros previstos, de datos, pérdida del fondo de comercio o gastos innecesarios incurridos), ni de (iii) cualesquiera pérdidas indirectas o de carácter consecuencial que no fueran razonablemente previsibles por ambas partes en el momento en que el usuario hubiera comenzado a utilizar los Servicios de Sigma. tampoco seremos responsable de ninguna demora o falta de cumplimiento de nuestras obligaciones derivadas de las presentes condiciones si dicha demora o falta de cumplimiento fuera atribuible a circunstancias ajenas a nuestro control razonable. Esta disposición no afecta al derecho del cliente a recibir la prestación del correspondiente servicio en un plazo razonable, o a recibir el oportuno reembolso para el caso de que no pudiéramos suministrarle tales servicios en un plazo razonable por cualquier causa ajena a nuestro control razonable.
                    Nada de lo dispuesto en las presentes condiciones limita o excluye nuestra responsabilidad en caso de falsedad, ni en supuestos de fallecimiento o daños personales atribuibles a nuestra negligencia o dolo.
                </p>

                <h5 className="mb-2" >14. Ley aplicable </h5>
                <p>Las presentes condiciones se regirán e interpretarán de conformidad con las leyes de la República de Costa Rica. Ambas partes acordamos someternos a la jurisdicción de los tribunales de la República de Costa Rica. </p>

                <h5 className="mb-2" >15. Modificación del Servicio o Variación de las Condiciones </h5>
                <p>Nos reservamos el derecho a realizar cambios en los Servicios de Sigma, en nuestras políticas y en nuestros términos y condiciones, incluyendo las presentes Condiciones de Uso, en todo momento usted quedará sujeto a los términos y condiciones, políticas, Condiciones de Uso y Condiciones Generales de los Servicios vigentes a la fecha en que utilice los Servicios de Sigma. Si alguna de las presentes Condiciones de Uso o de las Condiciones Generales de los Servicios fuera declarada inválida, nula o por cualquier causa ineficaz, dicha condición se entenderá excluida sin que dicha declaración pueda afectar a la validez ni a la exigibilidad del resto de condiciones. </p>
                <h5 className="mb-2" >16. Renuncia </h5>
                <p>En caso de incumplimiento por su parte de las presentes Condiciones de Uso, y aun cuando pudiéramos optar por no ejercitar cualesquiera derechos a nuestro alcance en dicho momento, podremos hacer uso de tales derechos y acciones en cualquier otra ocasión en la que usted pudiera incumplir nuevamente las presentes Condiciones de Uso. </p>
                <h5 className="mb-2" >17. Menores de edad </h5>
                <p>No ofrecemos nuestros servicios a menores de edad. Los menores de 18 años no podrán utilizar los Servicios de Sigma, ni siquiera bajo la supervisión de un padre o un tutor. </p>
                <h4 className="mb-2 mt-5" >Condiciones de venta</h4>
                <p>Las presentes Condiciones de Venta se aplican a la venta de productos por parte de Sigma. Sigma es un nombre comercial de Sigma Asesorias Contables, S.A. Además, cada vez que utilice cualquier servicio presente o futuro de sigmacr.net estará igualmente sujeto a los términos y condiciones generales y a las condiciones particulares aplicables a dicho servicio (las "Condiciones Generales"). Dichas Condiciones Generales prevalecerán sobre las presentes Condiciones de Venta en caso de discrepancia entre ambas. Le rogamos que lea detenidamente las presentes condiciones antes de cursar su pedido a Sigma. Al cursar su compra a Sigma, usted acepta quedar vinculado por las presentes condiciones.</p>
                <p>&nbsp;</p>
                <h5 className="mb-2" >1. Contrato</h5>
                <p>Cuando usted realice su compra, le enviaremos un correo electrónico confirmando la recepción de su compra. Usted recibirá una factura electrónica vía correo electrónico una vez confirmado el pago correspondiente. Una vez confirmado el pago quedará habilitado en su cuenta la descarga de sus respectivos productos.</p>
                <h5 className="mb-2" >2. Pago seguro</h5>
                <p>Esta tienda está autorizada por VISA INTERNACIONAL y MASTER CARD INTERNACIONAL como un comercio seguro para realizar transacciones electrónicas.</p>
                <p>Tomando en cuenta su seguridad, Sigma no almacena datos de tarjeta durante la apertura de la cuenta. Sigma se apega a los métodos de seguridad especificados por la entidad bancaria correspondiente.</p>
                <p>Sigma NO venderá, proporcionará, intercambiará o de ninguna forma, divulgará información de calidades de nuestros clientes.</p>
                <h5 className="mb-2" >3. Precios</h5>
                <p>Todos nuestros precios están dados en colones e incluyen los impuestos exigidos por la legislación tributaria.</p>
                <p>No se agregará costo adicional por el solo hecho del pago con tarjetas de crédito ó débito.</p>
                <h5 className="mb-2" >4. Pago Electrónico, Cancelación y Devolución</h5>
                <p>Los SERVICIOS DE Sigma son ofrecidos para el USUARIO, éste libremente decide cual producto o paquete y cuánta cantidad solicite a través del SITIO WEB en el momento de realizar la compra, sin presión y sin conocimiento de Sigma hasta que el USUARIO ejecute la compra y se aplique el método de pago mediante la utilización de una tarjeta de crédito. Si el USUARIO desea realizar cambio de los productos de software adquiridos a través de nuestra página web, se aclara que los costos y cargos administrativos deben ser asumidos por el USUARIO, igualmente Sigma se reserva el criterio de realizar devoluciones en plena fuerza y vigor de estos términos descritos.</p>
                <p>Sigma utilizará el SITIO WEB para facilitarle al USUARIO una forma de pago electrónico seguro y directo con la entidad Bancaria. Los pagos son finales y por lo tanto no se permitirán devoluciones ni cancelaciones mediante el SITIO WEB. Sigma no activará planes de facturación o brindará los productos de software comprados a través del SITIO WEB si la tarjeta por parte del cliente no tiene los fondos suficientes para que el pago se realice a través del SITIO WEB.</p>
                <p>Si desea realizar cambio de productos de software adquiridos a través del SITIO WEB, se aclara que los costos y cargos administrativos deben ser asumidos por el USUARIO. Lo anterior no afecta las garantías legales y demás derechos reconocidos al consumidor por la legislación vigente. En caso de solicitar el cambio por un producto de mayor valor al efectivamente pagado y/o características diferentes, el USUARIO deberá pagar el valor del excedente que resultare necesario para cubrir el precio del nuevo producto. </p>
                <p>Si el Usuario solicita una devolución Sigma se reserva el criterio de realizar devoluciones, de aprobar la devolución se procesará en los siguientes 30 días hábiles en la tarjeta utlizada por el USUARIO para la compra y verificada por SIGMA S.A., deduciendo los costos y cargos administrativos hasta por un costo de $8 USD al tipo de cambio del día de la devolución, mismo que asumirá el USUARIO.</p>
                <p>Sigma presta servicios mediante el SITIO WEB únicamente como agente intermediario entre el USUARIO y la entidad Bancaria, Usted libera a Sigma sobre cualquier responsabilidad ante cualquier falla o falta de cumplimiento por parte de la entidad Bancaria en el caso de transacciones fallidas o problemas directamente con la tarjeta.</p>
                <h5 className="mb-2" >5. Ley aplicable</h5>
                <p>Las presentes condiciones se regirán e interpretarán de conformidad con las leyes de la República de Costa Rica. Ambas partes acordamos someternos a la jurisdicción de los tribunales de Costa Rica.</p>
                <h5 className="mb-2" >6. Modificación de las condiciones de venta</h5>
                <p>Nos reservamos el derecho a realizar cambios a nuestro sitio web, a nuestras políticas y a nuestros términos y condiciones, incluyendo a las presentes Condiciones de Venta, en cualquier momento. Usted quedará sujeto a los términos y condiciones, políticas y Condiciones de Venta en vigor en el momento en que realice su compra, a menos que, por ley o por requerimiento de las autoridades públicas, se deba efectuar un cambio a dichos términos y condiciones, políticas o a las presentes Condiciones de Venta (en cuyo caso, tales cambios podrían resultar aplicables a cualesquiera pedidos que hubiera realizado anteriormente). Si alguna de las presentes condiciones fuera declarada inválida, nula o por cualquier causa ineficaz, dicha condición se entenderá excluida sin que dicha declaración pueda afectar a la validez ni a la exigibilidad del resto de condiciones</p>
                <h5 className="mb-2" >7. Renuncia</h5>
                <p>En caso de incumplimiento por su parte de las presentes Condiciones de Venta, y aún cuando Sigmacr.net pudiera no ejercitar cualesquiera derechos a su alcance en dicho momento, Sigmacr.net podrá hacer uso de tales derechos y acciones en cualquier otra ocasión en la que usted pudiera incumplir las presentes Condiciones de Venta.</p>
                <h5 className="mb-2" >8. Menores de edad</h5>
                <p>No vendemos productos a menores de edad. Los menores de 18 años no podrán utilizar Sigmacr.net bajo la supervisión de un padre o un tutor.</p>


                <h5 className="mb-2" >9. Nuestros datos</h5>
                <p>
                    Sigma es el nombre comercial que utiliza Sigma Asesorias Contables, S.A. El presente sitio web es propiedad de Sigma Asesorias Contables, S.A., quien es responsable asimismo de su explotación.

                    Información sobre Sigma Asesorias Contables, S.A.:
                </p>

                <div align="center">
                    <table border="0" cellPadding="0" cellSpacing="0" width="665">
                        <tbody><tr>
                            <td width="189">
                                <p className="style2">
                                    Razón Social:
                                </p>
                            </td>
                            <td width="1000">
                                <p className="style2">
                                    Sigma Asesorias Contables, S.A.
                                </p>
                            </td>
                        </tr>
                            <tr>
                                <td width="189">
                                    <p className="style2">
                                        Cédula Jurídica:
                                    </p>
                                </td>
                                <td width="1000">
                                    <p className="style2">
                                        3-101-578689
                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td width="189">
                                    <p className="style2">
                                        Dirección Geográfica:
                                    </p>
                                </td>
                                <td width="1000">
                                    <p className="style2">
                                        San José, Montes de Oca, San Pedro, La granja, 50m este de la embaja de Panamá
                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td width="189">
                                    <p className="style2">
                                        Teléfono:
                                    </p>
                                </td>
                                <td width="1000">
                                    <p className="style2">
                                        (506) 2291-7170
                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td width="189">
                                    <p className="style2">
                                        Fax:
                                    </p>
                                </td>
                                <td width="1000">
                                    <p className="style2">
                                        (506) 2291-7170
                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td width="189">
                                    <p className="style2">
                                        Horario de atención telefónica:
                                    </p>
                                </td>
                                <td width="1000">
                                    <p className="style2">
                                        De lunes a viernes&nbsp;de 8:00 AM. a 7:00 PM. y sábados de 8:00 A.M. A 3:00 P.M.
                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td width="189">
                                    <p className="style2">
                                        Correo electrónico:
                                    </p>
                                </td>
                                <td width="1000">
                                    <p className="style2">
                                        <a href="mailto:info@sigmacr.net">info@sigmacr.net</a>
                                    </p>
                                </td>
                            </tr>
                        </tbody></table>
                </div>
            </div>
        </>
    );
};

export default InfoTerminosYCondiciones;
