import React from "react";

const ListaDropdown = ({ opciones }) => {
  if (opciones === null) return null;
//<option value="">- Seleccione -</option>
  return (
    <>
      

      {opciones.map(opcion => (
        <option key={Object.keys(opcion)} value={Object.keys(opcion)}>
          {opcion[Object.keys(opcion)]}
        </option>
      ))}
    </>
  );
};

export default ListaDropdown;
