import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Edit, X } from "react-feather";
import ErrorForm from "./../Formularios/ErrorForm.js";
import {
    SwalToast,
    SweetAlert,
    Retrieve,
    SweetAlert2
} from "../../services/ControlActions";
import { ListaEndpoints} from "../../config/Config";
import Spinner from "./Spinner";
import IconPromo from "./IconPromo";



const CardProductoCarrito = ({ idCodigo, setPromo, userId }) => {//setpromo = metodo para validar la promocion y agregarla al carrito
    const [spinner, showSpinner, hideSpinner] = Spinner();

    const [codigoAplicable, setCodigoAplicable] = useState(null)
    const { register, handleSubmit, errors } = useForm();

    const [agregar, setAgregar] = useState(true);



    useEffect(() => {//busca la promoción y valida su existencia y si se puede aplicar a este user

        if (idCodigo > 0 && idCodigo != null) {
            //falta la logica para buscar

            Retrieve(ListaEndpoints.CODIGO_APLICABLE, "GetCodigoAplicableById", idCodigo)
                .then((retr) => {
                    //console.log("use effect",retr);
                    if (retr) {
                        if (retr.data) {//si está disponible

                            Retrieve(ListaEndpoints.CODIGO_APLICABLE, `GetValidaCodigo?codigo=${retr.data.codigo}&userId=${userId}`, "")
                                .then((res) => {
                                    if (res) {
                                        //console.log(res);
                                        if (res.ok) {//si está disponible
                                            setCodigoAplicable(res.data);

                                            setPromo(res.data)
                                            SwalToast(2, "Promoción Aplicada", () => { }, 3000);
                                            setAgregar(false);
                                        } else {
                                            setCodigoAplicable(null);
                                            setPromo(null)

                                            let err = "Promociones " + res.error;
                                            SweetAlert(3, "Acción Interrumpida", err, "", "Entendido", () => { });

                                        }

                                    } else {
                                        setCodigoAplicable(null);
                                        setPromo(null)
                                        let err = "Promociones:" + res.error;
                                        SweetAlert(3, "Acción Interrumpida", err, "", "Entendido", () => { });
                                        //     console.log(res);

                                    }
                                    hideSpinner();
                                })
                                .catch((e) => {
                                    hideSpinner();
                                    let err =
                                        "Promociones: No se pudo conectar al servidor, inténtelo de nuevo más tarde. " +
                                        e.message;
                                    SweetAlert(3, "Acción Interrumpida", err, "", "Entendido", () => { });

                                });



                        } else {
                            setCodigoAplicable(null);
                            setPromo(null)

                            let err = "Promociones: " + retr.error;
                            SweetAlert(3, "Acción Interrumpida", err, "", "Entendido", () => { });

                        }

                    } else {
                        setCodigoAplicable(null);
                        setPromo(null)
                        let err = "Promociones:  " + retr.error;
                        SweetAlert(3, "Acción Interrumpida", err, "", "Entendido", () => { });
                        // console.log(res);
                    }
                    hideSpinner();
                })
                .catch((e) => {
                    hideSpinner();
                    let err =
                        "Promociones: No se pudo conectar al servidor, inténtelo de nuevo más tarde.  " +
                        e.message;
                    SweetAlert(3, "Acción Interrumpida", err, "", "Entendido", () => { });
                });

        }
    }, [idCodigo]);

    const validarCodigo = () => {

        if (document.getElementById("btnAgregarCodigo").disabled === false) {
            showSpinner();
            document.getElementById("btnAgregarCodigo").disabled = true;
            let input = document.querySelector("#nptCodigoPromo");
            let valor = input.value;

            //  let carritoTem = getItem("carrito");
            //  carritoTem.codigo = valor;//agrega el codigo


           // console.log("validar codigo", valor);
            Retrieve(ListaEndpoints.CODIGO_APLICABLE, `GetValidaCodigo?codigo=${valor}&userId=${userId}`, "")
                .then((res) => {
                    if (res) {
                       // console.log("valida promo", res);
                        if (res.ok) {//si está disponible
                            setCodigoAplicable(res.data);

                            setPromo(res.data)
                            SwalToast(2, "Promoción Aplicada", () => { }, 3000);
                            setAgregar(false);
                        } else {
                            setCodigoAplicable(null);
                            setPromo(null)

                            let err = "Promociones: " + res.error;
                            SweetAlert(3, "Acción Interrumpida", err, "", "Entendido", () => { });

                        }

                    } else {
                        setCodigoAplicable(null);
                        setPromo(null)
                        let err = "Promociones: " + res.error;
                        SweetAlert(3, "Acción Interrumpida", err, "", "Entendido", () => { });
                        //     console.log(res);

                    }
                    hideSpinner();
                })
                .catch((e) => {
                    hideSpinner();
                    let err =
                        "Promociones: No se pudo conectar al servidor, inténtelo de nuevo más tarde." +
                        e.message;
                    SweetAlert(3, "Acción Interrumpida", err, "", "Entendido", () => { });

                });
            document.getElementById("btnAgregarCodigo").disabled = false;
        }

    };

    const eliminarCodigo = () => {

        SweetAlert2(3, "Desea Eliminar la promoción?", "Mientras siga vigente, podrá usar la promoción en otro momento", "", "Cancelar",
            () => { }, "Entendido",
            () => {
                setAgregar(true);
                setCodigoAplicable(null);
                setPromo(null);

            });



    }
    return (
        <>

            {spinner}

            <div className="cardPromocion">
                <div className="row">
                    <div className="col-3  " >
                        <div className="products-details-image  " style={{
                            height: "100%", display: "flex",
                            alignItems: "center"
                        }}>

                            {agregar === false ? <IconPromo word={`-${codigoAplicable.descuento * 100}%`} alto={"65px"} ancho={"65px"} /> : <IconPromo word={"PROMO"} alto={"85px"} ancho={"85px"} />}

                        </div>
                    </div>
                    <div className="col-7">
                        <div className="products-details mt-3">

                            {
                                agregar ?
                                    //logica de agregar
                                    <div className="form-row">
                                        {/**Codigo */}
                                        <div className="form-group col-12 ">
                                            <label>Código:</label>
                                            <input
                                                id="nptCodigoPromo"
                                                type="text"
                                                className={
                                                    "form-control " +
                                                    (errors.codigo ? "input-error" : null)
                                                }
                                                name="codigo"
                                                placeholder="Código"
                                                defaultValue={codigoAplicable != null ? codigoAplicable.codigo : ""}
                                                ref={register({
                                                    required: "Campo requerido.",
                                                    pattern: {
                                                        value: /[A-Z0-9]{4,30}$/,
                                                        message: "Código inválido"
                                                    },
                                                    maxLength: { message: "El código es muy largo", value: 30 }
                                                })}
                                            />


                                            {errors.codigo ? (
                                                <ErrorForm mensaje={errors.codigo.message} />
                                            ) : null}
                                        </div>

                                        <button
                                            type="submit"
                                            id={"btnAgregarCodigo"}
                                            onClick={handleSubmit(() => validarCodigo())}
                                            className="btnIconCarrito"
                                        >
                                            Aplicar
                                        </button>

                                    </div> : <>
                                        <div className="product-name pt-2">
                                            <h5>{codigoAplicable.codigo}</h5>
                                        </div>
                                        <p>{codigoAplicable.descripcion}</p>

                                    </>

                            }
                        </div>
                    </div>
                    <div className="col-1 p-0">
                        <div className="mt-3">
                            {agregar ?
                                null
                                :
                                //opciones de modificar y eliminar la promoción
                                <>
                                    <button
                                        type="button"
                                        id={"btnModificarCodigo"}
                                        onClick={() => { eliminarCodigo() }}
                                        className="btnIconCarrito"
                                    >
                                        <X />
                                    </button>

                                    <button
                                        type="button"
                                        id={"btnModificarCodigo"}
                                        onClick={() => { setAgregar(true); }}
                                        className="btnIconCarrito"
                                    >
                                        <Edit />
                                    </button></>
                            }



                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CardProductoCarrito;
