import React, { useState } from "react";
import { useForm } from "react-hook-form";
import ErrorForm from "./ErrorForm";
import moment from "moment";
import {
  COD_APLICACION,
  ListaEndpoints,
  ERROR_REQUERIDO,
  LONG_MAX_PASSWORD,
  LONG_MIN_PASSWORD,
} from "../../config/Config";
import { Create, SweetAlert } from "../../services/ControlActions";

const FormConfirmacionUsuario = ({ idUsuario, token }) => {
  const { register, handleSubmit, errors, reset, watch } = useForm();

  const [cargando, setCargando] = useState(false);
  const [enviado, setEnviado] = useState(false);
  const [mensajeRespuesta, setMensajeRespuesta] = useState({
    error: false,
    mensaje: "",
  });

  const onSubmit = (data) => {
    let obj = {
      Codigo: idUsuario,
      Email: '""',
      Nombre: '""',
      Compannia: '""',
      Password: data.password2,
      Telefono: 0,
      Pais: 0,
      Identificacion: '""',
      UltimoAcceso: moment.utc().toISOString(),
      Pregunta: '""',
      Respuesta: '""',
      Actividad: 1,
      estaConfirmada: false,
      Confirmacion: token,
      Aplicacion: COD_APLICACION,
      PasswordConfirm: data.password2,
      Comentario: '""',
    };

    consultarAPI(obj);
  };

  const consultarAPI = async (data) => {
    setEnviado(false);
    setCargando(true);

    Create(ListaEndpoints.USUARIO, "ConfirmarUsuario", "", data).then(
      (res) => {
        setEnviado(true);
        if (res.data) {
          setMensajeRespuesta({ error: false, mensaje: res.msg });
          reset();
        } else {
          setMensajeRespuesta({ error: true, mensaje: res.error });
         // console.log(res);
          setCargando(false);
        }
      },
      (error) => {
        let err = "Confirmación de usuario: No pudo conectar al servidor, inténtelo de nuevo más tarde. " + error.message
        SweetAlert(3, "Acción Interrumpida", err, "", "Entendido",
          () => { })
      }
    ).catch((e) => {

      let err = "Confirmación de usuario: No pudo conectar al servidor, inténtelo de nuevo más tarde. " + e.message
      SweetAlert(3, "Acción Interrumpida", err, "", "Entendido",
        () => { })
    });
  };

  return (
    <>
      <section>
        <div className="container-fluid h-100 justify-content-center align-items-center mt-2 mb-5">
          <div className="row h-100 justify-content-center align-items-center">
            <form onSubmit={handleSubmit(onSubmit)} className="col-md-12">
              <fieldset>
                <div className="form-row">
                  <div className="form-group col-md-12">
                    <label>Contraseña</label>

                    <input
                      type="password"
                      className={
                        "form-control " +
                        (errors.password1 ? "input-error" : null)
                      }
                      name="password1"
                      placeholder="Ingrese contraseña"
                      ref={register({
                        required: ERROR_REQUERIDO,
                        maxLength: {
                          value: LONG_MAX_PASSWORD,
                          message: `Máximo ${LONG_MAX_PASSWORD} caracteres de longitud.`,
                        },
                        minLength: {
                          value: LONG_MIN_PASSWORD,
                          message: `Mínimo ${LONG_MIN_PASSWORD} caracteres de longitud.`,
                        },
                      })}
                    />

                    {errors.password1 ? (
                      <ErrorForm mensaje={errors.password1.message} />
                    ) : null}
                  </div>

                  <div className="form-group col-md-12">
                    <label>Confirmar Contraseña</label>

                    <input
                      type="password"
                      className={
                        "form-control " +
                        (errors.password2 ? "input-error" : null)
                      }
                      name="password2"
                      placeholder="Confirmar contraseña"
                      ref={register({
                        required: "Campo requerido.",
                        validate: (value) =>
                          value === watch("password1") ||
                          "Contraseñas no coinciden.",
                      })}
                    />

                    {errors.password2 ? (
                      <ErrorForm mensaje={errors.password2.message} />
                    ) : null}
                  </div>

                  <div className="col-md-12 text-center">
                    {enviado ? (
                      mensajeRespuesta.error ? (
                        <div className="alert alert-danger text-center p-2 animated fadeInUp faster mb-3">
                          {mensajeRespuesta.mensaje}
                        </div>
                      ) : (
                          <div className="alert alert-success text-center p-2 animated fadeInUp faster mb-3">
                            {mensajeRespuesta.mensaje}
                          </div>
                        )
                    ) : null}
                    <button
                      type="submit"
                      className="d-inline btn btn-primary "
                      disabled={cargando}
                    >
                      Enviar
                    </button>
                  </div>
                </div>
              </fieldset>
            </form>
          </div>
        </div>
      </section>

      <div className="bg-info">
        <div className="container">
          <div className="row"></div>
        </div>
      </div>
    </>
  );
};

export default FormConfirmacionUsuario;
