import React, { useState } from "react";
import FormRegistro from "../components/Formularios/FormRegistro";
import { imagen1 } from "../images/imagesURL";
import { useHistory } from "react-router-dom";
import { existeToken } from "../services/ControlActions";

const Registro = () => {
  let history = useHistory();

  const [muestraPagina] = useState(existeToken());

  if (muestraPagina) {
    history.push("/");
    alert("Sesión iniciada, debe cerrar sesión para registrar otra cuenta");
  }

  return (
    <>
      {!muestraPagina ? (
        <>
          <section className="contact-area animated fadeIn fast">
            <div className="container">
              <div className="section-title"></div>

              <div className="row h-100 justify-content-center align-items-center mx-0">
                <div className="col-lg-5 col-md-12">
                  <img src={imagen1} alt="" />
                </div>

                <div className="col-lg-7 col-md-12 formContainer mb-5">
                  <div className="container-fluid h-100 justify-content-center align-items-center my-3">
                    <div className="row">
                      <div className="col-md-12">
                        <h2>Bienvenido</h2>
                        <div className="bar"></div>
                        <p>
                          Puede registrarse llenando el formulario que se
                          encuentra a continuación.
                        </p>
                      </div>
                    </div>
                  </div>
                  <FormRegistro />
                </div>
              </div>
            </div>
          </section>
        </>
      ) : null}
    </>
  );
};

export default Registro;
