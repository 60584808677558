import React, { useState } from "react";
import $ from "jquery";
import "../../css/NavBar.css";
import Headroom from "react-headroom";
import { Link, useHistory } from "react-router-dom";
import { ShoppingCart, ChevronDown } from "react-feather";
import { HashLink } from "react-router-hash-link";
import { getItem } from "../../services/UseLocalStorage";
import useInterval from "../../services/useInterval";

const NavBar = ({ datos, cargando }) => {
  $(document).ready(() => {
    $(".second-button").on("click", function () {
      $(".animated-icon2").toggleClass("open");
    });
  });

  let history = useHistory();

  const [recargar, setRecargar] = useState(true);
  const [cartNum, setCartNum] = useState(0);

  useInterval(() => {
    if (recargar) {
      if (getItem("usuario") !== null) {
        setRecargar(false);
      }
    }
  }, 1000);

  useInterval(() => {
    if (getItem("carrito")) {
      let pCarritoLong = getItem("carrito").ProductoCarrito.length;
      if (cartNum !== pCarritoLong) {
        setCartNum(pCarritoLong);
      }
    }
  }, 1000);

  const cerrarSesion = () => {
    localStorage.clear("usuario");
    localStorage.clear("carrito");
    setRecargar(true);
    history.push("/");
  };

  const irPerfil = () => {
    history.push("/perfil");
  }; 
   const irPlanes = () => {
    history.push("/planes");
  };


  const irHistorial = () => {
    history.push("/historialPedidos");
  };
  const styleLink = {
    color: "#bc2424",
  }


  return (
    <Headroom
      style={{
        zIndex: 9999,
      }}
    >
      <header id="header" className="headroom">
        <div className="startp-nav">
          <div className="container-fluid">
            <nav className="navbar navbar-expand-md navbar-light">
              <Link to="/" className="navbar-brand">
                <div className="photo img-fluid" alt="Responsive image"></div>
              </Link>

              <button
                className="navbar-toggler second-button"
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <div className="animated-icon2">
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              </button>
              <div
                className="collapse navbar-collapse mean-menu"
                id="navbarSupportedContent"
              >
                <ul className="navbar-nav nav mr-auto ml-auto">
                  <li className="nav-item">
                    <HashLink
                      smooth
                      to="/#carousel"
                      className="nav-link active"
                    >
                      Inicio <ChevronDown />
                    </HashLink>
                    <ul className="dropdown_menu">
                      <li className="nav-item">
                        <HashLink smooth to="/#planes" className="nav-link">
                          Planes Factúrelo
                        </HashLink>
                      </li>

                      {/* <li className="nav-item">
                        <HashLink smooth to="/#cecapsi" className="nav-link">
                          CECAPSI<i></i>
                        </HashLink>
                      </li>*/}

                      <li className="nav-item">
                        <HashLink smooth to="/#servicios" className="nav-link">
                          Otros Servicios<i></i>
                        </HashLink>
                      </li>

                      <li className="nav-item">
                        <HashLink smooth to="/#clientes" className="nav-link">
                          Nuestros Clientes<i></i>
                        </HashLink>
                      </li>
                    </ul>
                  </li>

                  <li className="nav-item">
                    <Link to={"/servicios"} className="nav-link">
                      Servicios <ChevronDown />
                    </Link>
                    <ul className="dropdown_menu">
                      {cargando ? (
                        <>
                          <li className="nav-item">
                            <Link to={"/"} className="nav-link">
                              Cargando...
                            </Link>
                          </li>
                        </>
                      ) : (
                        datos != null && datos.length > 0 ? (
                          <>
                            {datos.map((dato) => (
                              <li key={dato.id} className="nav-item">
                                <Link
                                  to={`/servicios/${dato.id}`}
                                  className="nav-link"
                                >
                                  {dato.titulo}
                                </Link>
                              </li>
                            ))}
                          </>
                        ) : (
                          <>
                            <li className="nav-item">
                              <Link to={"/"} className="nav-link">
                                No hay servicios
                              </Link>
                            </li>
                          </>
                        )
                      )}
                    </ul>
                  </li>

                  <li className="nav-item">
                    <Link to="/Distribuidores" className="nav-link">
                      Distribuidores
                    </Link>
                  </li>

                  <li className="nav-item">
                    <Link to="/nosotros" className="nav-link">
                      Nosotros <ChevronDown />
                    </Link>
                    <ul className="dropdown_menu">
                      <li className="nav-item">
                        <HashLink
                          smooth
                          to="/nosotros#sigma"
                          className="nav-link"
                        >
                          Sigma
                        </HashLink>
                      </li>

                      <li className="nav-item">
                        <HashLink
                          smooth
                          to="/nosotros/#mision"
                          className="nav-link"
                        >
                          Misión
                        </HashLink>
                      </li>

                      <li className="nav-item">
                        <HashLink
                          smooth
                          to="/nosotros/#vision"
                          className="nav-link"
                        >
                          Visión
                        </HashLink>
                      </li>

                      <li className="nav-item">
                        <HashLink
                          smooth
                          to="/nosotros/#valores"
                          className="nav-link"
                        >
                          Valores
                        </HashLink>
                      </li>
                    </ul>
                  </li>

                  <div className="nav-item">
                    <Link to="/contacto" className="nav-link">
                      Contacto
                    </Link>
                  </div>
                </ul>
              </div>

              <div className="others-option ml-0">
                {getItem("usuario") ? (
                  <>
                    <Link to="/carritoPage" className="cart-wrapper-btn">
                      <ShoppingCart />
                      <span>{cartNum}</span>
                    </Link>

                    {/* <Link to="/pago" className="cart-wrapper-btn">
                      <CreditCard />
                      <span>{cartNum}</span>
                    </Link> */}
                    <div className="dropdown dropdownUser">
                      <button
                        className="btn btn-light dropdown-toogle"
                        type="button"
                        id="dropdownUser"
                        data-toggle="dropdown"
                      >
                        {/*aria-extended="true"*/}
                        {getItem("usuario")["userName"]} <ChevronDown />
                      </button>

                      <ul
                        className="dropdown-menu"
                        role="menu"
                        arial-labelledby="dropdownUser"
                      >
                        <li className="dropdown-item">
                          <div
                            role="button"
                            type="button"
                            onClick={() => irPerfil()}
                          >
                            Perfil
                          </div>
                        </li>
                        <li className="dropdown-item">
                          <div
                            role="button"
                            type="button"
                            onClick={() => irHistorial()}
                          >
                            Historial de pedidos
                          </div>
                        </li><li className="dropdown-item">
                          <div
                            role="button"
                            type="button"
                            onClick={() => irPlanes()}
                          >
                         Planes
                          </div>
                        </li>
                        {getItem('usuario').uTipo === "tp" ?
                          <li className="dropdown-item">
                            <HashLink
                              smooth
                              to="/mantenimientos#Mantenimientos"
                              style={styleLink}
                              className="hashLink"
                            >

                              Mantenimientos


                            </HashLink>


                          </li>

                          : null
                        }
                        {getItem('usuario').uTipo === "sc" ?
                          <li className="dropdown-item">
                            <HashLink
                              smooth
                              to="/adm/pedidos#AdmPedidos"
                              style={styleLink}
                              className="hashLink"
                            >

                              Lista de pedidos


                            </HashLink>


                          </li>

                          : null
                        }
                        <li className="dropdown-item">
                          <div role="button" onClick={() => cerrarSesion()}>
                            Cerrar Sesion
                          </div>
                        </li>
                      </ul>
                    </div>
                  </>
                ) : (
                  <>
                    <Link to="/registro" className="btn btn-light">
                      Registro
                    </Link>
                    <Link to="/login" className="btn btn-light">
                      Iniciar Sesion
                    </Link>
                  </>
                )}
              </div>
            </nav>
          </div>
        </div>
      </header>
    </Headroom >
  );
};

export default NavBar;
