import React, { useState } from "react";
import EncabezadoDescripcion from "../components/Visuales/EncabezadoDescripcion";
import FormPerfil from "../components/Formularios/FormPerfil";
import FormCambiarContrasenna from "../components/Formularios/FormCambiarContrasenna";
import WidgetTarjetas from "../components/Visuales/WidgetTarjetas";
import { useHistory } from "react-router-dom";
import { existeToken, SweetAlert } from "../services/ControlActions";
import TarjetasInfo from "../components/Visuales/TarjetasInfo";

const PerfilPage = () => {
  let history = useHistory();


  const [usuario, setUsuario] = useState({});
  const [muestraPagina] = useState(existeToken());
  const [action, setAction] = useState({
    nombre: "perfil",
    titulo: "Información de la Cuenta:",
  });

  if (!muestraPagina) {
    history.push("/");
    SweetAlert(3, "No puede acceder", "Necesitas iniciar sesión para continuar", "", "Entendido", () => { });
  }

  const goCambiarContrasenna = () => {
    setAction({
      nombre: "cambiarContrasenna",
      titulo: "Cambiar Contraseña:",
    })
  };

  const goPerfil = () => {
    setAction({
      nombre: "perfil",
      titulo: "Información de la Cuenta:",
    })
  };


  return (
    <>

      {muestraPagina ? (
        <>
          <section className="about-area my-5">
            <div className="container">
              <EncabezadoDescripcion id="perfil" titulo="Perfil" />
              <div className="row align-items-center">
              <div className="col-lg-3 col-md-12">
              </div>
                <div className="col-lg-6 col-md-12">
                  <div className="calendario">
                    <div className="calendario-header">
                      <h5>{action.titulo}</h5>
                    </div>
                   
                    {action.nombre == "perfil" ?
                      <FormPerfil usuario={usuario} setUsuario={setUsuario} accion={goCambiarContrasenna} />
                      : null}
                    {action.nombre == "cambiarContrasenna" ?
                      <FormCambiarContrasenna usuario={usuario} setUsuario={setUsuario} accion={goPerfil} />
                      : null}
                  </div>
                </div>
                <div className="col-lg-3 col-md-12">
              </div>
                {/*
                <div className="col-lg-6 col-md-12 mt-3 mt-sm-0">
                  {Object.keys(usuario).length === 0 ? (
                    <>
                      <div className="calendario">
                        <div className="calendario-header">
                          <h5>Tarjetas guardadas:</h5>
                        </div>

                        <p className="text-center">Cargando...</p>
                      </div>
                    </>
                  ) : (
                      <div className="calendario">
                        <TarjetasInfo usuario={usuario} />
                      </div>
                    )}
                </div>
                  */ }
              </div>
            </div>
          </section>
        </>
      ) : null}
    </>
  );
};

export default PerfilPage;
