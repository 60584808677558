import React,{useEffect} from 'react';
import Carrito from '../components/Carrito';
import EncabezadoFiguras from '../components/Visuales/EncabezadoFiguras';
import { withRouter } from 'react-router-dom';
import {getItem} from '../services/UseLocalStorage';

const CarritoPage = (props) => {
    const { history } = props;   
    useEffect(() => {
        if (!getItem('carrito')) {
            history.goBack() ? history.goBack() : history.push("/")
        }
    });

    return (
        <>
            <EncabezadoFiguras titulo="Carrito" />
            <section className="cart-area mt-4">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12">
                            <Carrito />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default withRouter(CarritoPage);
