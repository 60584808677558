import React, { useState } from "react";
import BannerPublicidad from "../components/Visuales/BannerPublicidad";
import CarouselClientes from "../components/Visuales/CarouselClientes";

import PlanesFacturelo from "../components/Visuales/PlanesFacturelo";
import EncabezadoFiguras from "../components/Visuales/EncabezadoFiguras";
import { PlusCircle } from "react-feather";
import { Link } from "react-router-dom";
import {
  contador_mil,
  facel,
  facturelo
} from "../images/imagesURL";
//import $ from "jquery";
import AlertMessage from "../components/Errors/AlertMessage";

const LandingPage = () => {

  /*const [existenEventos, setExistenEventos] = useState({
    data: null,
    cargando: true,
  });*/
  const [mensajeAlerta, setMensajeAlerta] = useState({
    mensaje: "",
    error: false,
  });


  return (
    <>
      {mensajeAlerta.error ? (
        <>
          <AlertMessage
            title={""}
            message={mensajeAlerta.mensaje}
            tipo={"alert-danger"}
          />
        </>
      ) : null}

      <BannerPublicidad id="carousel" alert={mensajeAlerta} setAlert={setMensajeAlerta} />
      <EncabezadoFiguras id="planes" titulo="Planes de Facturelo" />                 
      <PlanesFacturelo />
      <EncabezadoFiguras titulo="Otros Servicios" id="servicios" />
      <section className="solutions-area ptb-80">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-sm-6 col-md-6">
            </div>

            <div className="col-lg-4 col-sm-6 col-md-6">
              <div className="single-solutions-box">
                <div className="icon">
                  <img src={facel} alt="" />
                </div>
                <h3>
                  <Link className="pt-5" to="/facel" target="_blank">Facel</Link>
                </h3>
                <p>
                  Ofrece una solución web que cumple con la normativa vigente en
                  Costa Rica en cuanto a facturación electrónica se refiere,
                  dirigida a profesionales independientes y empresas que
                  utilizan un alto volumen de facturación.
                </p>
                <Link to="servicios/facel" className="learn-more-btn">
                  <PlusCircle /> Más información
                </Link>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6 col-md-6">
            </div>

            {/* <div className="col-lg-4 col-sm-6 col-md-6">
             <div className="single-solutions-box">
               <div className="icon">
                 <img src={contador_mil} alt="contmil" />
               </div>
               <h3>
                 <Link className="pt-5" to="/millenium" target="_blank">
                   Contador Millenium
                 </Link>
               </h3>
               <p>
                 Es un sistema contable que a lo largo de los años se ha
                 convertido en una herramienta estándar necesaria para los
                 profesionales dedicados a la contabilidad, con la capacidad de
                 llevar el control de múltiples compañías.
               </p>
               <Link
                  to="/millenium"
                  className="learn-more-btn"
                 target="_blank"
                >
                 <PlusCircle /> Más información
               </Link>
             </div>
            </div> */}
            {/* <div className="col-lg-4 col-sm-6 col-md-6">
              <div className="single-solutions-box">
                <div className="icon">
                  <img src={facturelo} alt="xpress" />
                </div>
                <h3 className="pt-5">
                  <Link to="/facturelo" target="_blank">Facturelo</Link>
                </h3>
                <p>
                  Planes de facturación a su medida con la cantidad de documentos que requiere.
                </p>

                <Link
                  to="/facturelo"
                  target="_blank"
                  className="learn-more-btn"
                >
                  <PlusCircle /> Más información
                </Link>
              </div>
            </div> */}
            {/* <div className="col-lg-4 col-sm-6 col-md-6">
              <div className="single-solutions-box">
                <div className="icon">
                   <img src={contador_exp} alt="xpress" />
                </div>
                <h3>
                  <Link to="/servicios/contador-xpress">Contador Xpress</Link>
                </h3>
                <p>
                  Sistema contable en la nube al cual se puede acceder desde
                  cualquier dispositivo. Cuenta con diferentes módulos internos
                  con el fin de brindar más facilidad y orden al analizar la
                  información financiera de la compañía.
                </p>

                <Link
                  to="/servicios/contador-xpress"
                  className="learn-more-btn"
                >
                  <PlusCircle /> Más información
                </Link>
              </div>
            </div> */}
          </div>
        </div>
      </section>
      <EncabezadoFiguras titulo="Testimonios" id="clientes" />
      <CarouselClientes alert={mensajeAlerta} setAlert={setMensajeAlerta}/>
    </>
  );
};

export default LandingPage;

