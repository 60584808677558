import React from "react";


const EncabezadoFiguras = ({id, titulo }) => {
  return (
    <div id={id} className="encabezado page-title-area">
      <div className="d-table">
        <div className="d-table-cell">
          <div className="container">
            <spam className="encabezado-titulo">{titulo}</spam>
          </div>
        </div>
      </div>

   

    </div>
  );
};

export default EncabezadoFiguras;
