import React, { useEffect, useState } from "react";
import FormLogin from "../components/Formularios/FormLogin";
import FormGenerarContrasenna from "../components/Formularios/FormGenerarContrasenna";
import { withRouter } from 'react-router-dom';
import { getItem } from '../services/UseLocalStorage';

const Login = (props) => {
  const { history } = props;

  useEffect(() => {
    if (getItem('usuario')) {
      history.goBack() ? history.goBack() : history.push("/")
    }

  });


  const [action, setAction] = useState({
    nombre: "login",
    titulo: "Bienvenido",
  });


  const goGenerarContrasenna = () => {
    setAction({
      nombre: "generarContrasenna",
      titulo: "Generar contraseña",
    })
  };

  const goLogin = () => {
    setAction({
      nombre: "login",
      titulo: "Bienvenido",
    })
  };

  return (
    <>
      <section className="contact-area animated fadeIn fast">
        <div id="login" className="row justify-content-center align-items-center mt-5 mb-5 mx-0">
          <div className="col-lg-6 col-md-10 col-xs-10  formContainer">
            <div className="container-fluid my-3 col-md-12">
              <h2>{action.titulo}</h2>
            </div>
            {action.nombre == "login" ?
              <FormLogin accion={goGenerarContrasenna } />
              : null}
            {action.nombre == "generarContrasenna" ?
              <FormGenerarContrasenna accion={goLogin } />
              : null}
          </div>
        </div>
      </section>
      
    </>
  );
};

export default withRouter(Login);
