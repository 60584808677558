import React, { useState, useEffect } from 'react';
import EncabezadoDescripcion from "../components/Visuales/EncabezadoDescripcion";
import { withRouter } from 'react-router-dom';
import { getItem } from '../services/UseLocalStorage';
import { ListaEndpoints, IMPUESTO_VENTA, MODAL_STYLES } from '../config/Config';
import { GetWithToken, SweetAlert, SweetAlert2, SwalToast, UpdateWithToken, PostWithToken, DeleteWithToken } from '../services/ControlActions';
import Spinner from '../components/Visuales/Spinner';
import $ from "jquery";
import AlertMessage from "../components/Errors/AlertMessage";
import moment from 'moment';
import FormatMoney from "../services/FormatMoney";
import { Eye, List, Check, Trash, Search } from 'react-feather';
import { useForm } from "react-hook-form";
import ErrorForm from "../components/Formularios/ErrorForm.js";
import Modal from "react-modal";
//test datatables
import 'datatables.net';
//import "datatables.net-dt/js/dataTables.dataTables";

import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import "pdfmake/build/pdfmake.js";
import "pdfmake/build/vfs_fonts.js";
import * as JSZip from 'jszip';
import pdfMake from "pdfmake/build/pdfmake.js";
import pdfFonts from "pdfmake/build/vfs_fonts";
import 'datatables.net-bs4';
import "moment"
import { UsePagination } from '../hooks/UsePagination';
import BtnBack from '../components/Formularios/BtnBack';
//import { use } from 'datatables.net-datetime';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
window.JSZip = JSZip;

const place = "Pedidos";
const AdmPedidos = (props) => {
    const { history } = props;
    const [listEstados, setListEstados] = useState({ data: {}, cargando: true });
    const [objeto, setObjeto] = useState({
        id: 0,
        estado: 0,
    });
    const [modalEditEstado, setModalEditEstado] = useState(false);
    const [modalListEstados, setModalListEstados] = useState(false);
    const [spinner, showSpinner, hideSpinner] = Spinner();
    const [mensajeAlerta, setMensajeAlerta] = useState({ mensaje: "", error: false });
    const [mensajeError, setMensajeError] = useState({ error: false, mensaje: "", });
    const [enviado, setEnviado] = useState(false);
    //#region datatables
    const TblID = "tblPedidosAdm";
    const [list, setList] = useState(null);
    const [datatable, setDatatable] = useState(null)
    //filtros
    const { register, handleSubmit, errors, reset } = useForm();
    const [fechaI, setFechaI] = useState(moment().subtract(1, 'months').format("YYYY-MM-DD"));
    const [fechaF, setFechaF] = useState(moment().format("YYYY-MM-DD"));
    const [estadoSelected, setEstadoSelected] = useState(0);//0 = todos
    const [ignorarF, setIgnorarF] = useState(false);//ignorar las fechas en el filtro
    //pagination 
    const { Pagination, setTotalItems, setCountItems, goFirstPage, countItems, currentPage } = UsePagination();
    //const [cargando, setCargando] = useState(false);
    //const [edit, setEdit] = useState(false);
    useEffect(() => {
        initData();
    }, []);
    useEffect(() => {
        try {
            // el validador de los campos de los filtros
            recargarLista();
        } catch (e) {
            console.error("error", e)
        }
    }, [currentPage])
    const startDatatable = () => {
        // console.log("start")
        setDatatable($("#" + TblID).DataTable({
            scrollX: true,
            processing: true,
            destroy: true,
            language: {
                url: "//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json"
            },
            "paging": false,//paginacion
            "ordering": false,
            "info": false,
            "colReorder": false,//ordenamiento des o asc
            "searching": true,//buscador
            "bLengthChange": false,//cantidad de registro por page
            dom: 'Blfrtip',
            buttons: [
                {
                    extend: 'collection',
                    text: 'Exportar <i class="fas fa-list-ol"></i>',
                    className: "botonesSecundarios",
                    buttons: [
                        {
                            extend: 'csv',
                            text: 'CSV',
                            footer: true,
                            title: 'Listado de Facturas',
                            filename: 'Listado_Facturas_csv',
                            exportOptions: {
                                columns: ':visible'
                            }
                        },
                        {
                            extend: 'pdfHtml5',
                            text: 'PDF',
                            footer: true,
                            title: 'Listado de Facturas',
                            filename: 'Listado_Facturas_pdf',
                            exportOptions: {
                                columns: ':visible'
                            }
                        },
                        {
                            extend: 'excel',
                            text: 'Excel',
                            footer: true,
                            title: 'Listado de Facturas',
                            filename: 'Listado_Facturas_excel',
                            exportOptions: {
                                columns: ':visible'
                            }
                        },
                        {
                            extend: 'copy',
                            title: 'Listado de Facturas',
                            text: 'Copiar',
                            exportOptions: {
                                columns: ':visible'
                            }
                        },
                        {
                            extend: 'print',
                            title: 'Listado de Facturas',
                            text: 'Imprimir',
                            exportOptions: {
                                columns: ':visible'
                            }

                        }
                    ],
                }
            ],
            dropup: true,
            "order": [[0, "desc"]]
        }));
    }
    const destroyDatatable = () => {
        if (datatable != null) {
            datatable.destroy();
            setDatatable(null)
        }
    }
    const recargarLista = async () => {
        destroyDatatable();
        listar().then((res) => {
            //  console.log("res", res)
            if (res) {
                startDatatable();
            }
        })
    }
    const onChangeFechaI = (val) => {
        // console.log("Fecha i", val)
        setFechaI(val);
    }

    const onChangeFechaF = (val) => {
        //console.log("Fecha f", val)
        setFechaF(val);
    }

    const buscar = (data) => {

        setEstadoSelected(data.estadoF);
        setCountItems(parseInt(data.cantidad));
       // console.log("data", data)
        setTimeout(() => {
            goFirstPage();
        }, 500)

    }
    //#endregion

    const initData = () => {//solo se deberia ejecutar una vez
        if (getItem('usuario')) {
            if (getItem('usuario').uTipo != "tp" && getItem('usuario').uTipo != "sc") {
                history.goBack() ? history.goBack() : history.push("/")
            } else {
                try {
                    listarEstados();
                } catch (error) {
                    alert("Acción Interrumpida:", error.message);
                }
            }
        } else {
            history.goBack() ? history.goBack() : history.push("/")
        }
    }
    const GetColor = (i) => {
        switch (i) {
            case 1: return "rgb(226, 114, 114)";//rojo

            case -1: return "rgb(226, 114, 114)";//rojo

            case 2:
                return "rgb(118, 226, 114)";//verde
            case 12:
                return "rgb(118, 226, 114)";//verde
            case 11:
                return "rgb(118, 226, 114)";//verde
            default:
                return "rgb(114, 162, 226)";//azul

        }
    }
    const listar = async () => {
        showSpinner();
        /* console.log("data", `GetBuscarPedidos?fechaI=${fechaI}&fechaF=${fechaF}&idEstado=${estadoSelected}
         &ignorarFecha=${ignorarF}&cantidad=${cantidad}&pagina=${paginaActual}`);
 */
        let ok = false;
        await GetWithToken(ListaEndpoints.PEDIDO, `GetBuscarPedidos?fechaI=${fechaI}&fechaF=${fechaF}&idEstado=${estadoSelected}
        &ignorarFecha=${ignorarF}&cantidad=${countItems}&pagina=${currentPage.skip}`).then((res) => {
            // console.log("res pedidos", res.data)
            if (res.data) {
                setList(res.data);
                setTotalItems(res.data.total);
                setCountItems(res.data.count);
                ok = true;
            } else {
                if (res.Message) {
                    let err = res.Message;
                    SweetAlert(3, "Acción Interrumpida", err, "", "Entendido",
                        () => { history.goBack() ? history.goBack() : history.push("/") })
                    setList(null);
                } else {
                    let err = place + ":" + res.error;
                    SweetAlert(3, "Acción Interrumpida", err, "", "Entendido",
                        () => { history.goBack() ? history.goBack() : history.push("/") })
                    setList(null);
                }
            }
        }).catch((e) => {
            let err = place + ": No pudo conectar al servidor, inténtelo de nuevo más tarde. " + e.message
            SweetAlert(3, "Acción Interrumpida", err, "", "Entendido",
                () => { history.goBack() ? history.goBack() : history.push("/") })
            setList(null);

        }).finally(() => {
            hideSpinner();
            // console.log("end")
        });
        return ok;

    }
    const listarEstados = async () => {
        // showSpinner();
        await GetWithToken(ListaEndpoints.ESTADO_PEDIDO, "GetEstadosPedido").then((res) => {
            //  console.log("Listar estados ", res)
            if (res.ok) {
                setListEstados({ data: res.data, cargando: false });
            } else {
                let err = "Estados del pedido: " + res.error;
                if (res.Message) {
                    err = res.Message;
                    SweetAlert(3, "Acción Interrumpida", err, "", "Entendido",
                        () => { })
                    setListEstados({ data: null, cargando: false });
                    setTimeout(() => {
                        localStorage.clear("usuario");
                        localStorage.clear("carrito");
                        history.push("/login");
                    }, 3000)
                } else {
                    SweetAlert(3, "Acción Interrumpida", err, "", "Entendido",
                        () => { })
                    setListEstados({ data: null, cargando: false });
                }
            }
        }).catch((e) => {
            let err = "Estados del pedido: No pudo conectar al servidor, inténtelo de nuevo más tarde. " + e.message
            setMensajeAlerta({ mensaje: err, error: true })
            setListEstados({ data: null, cargando: false });
            setTimeout(() => {
                localStorage.clear("usuario");
                localStorage.clear("carrito");
                history.push("/login");
            }, 3000);
        }).finally(() => {
            hideSpinner();
            // console.log("end")
        });

    }
    const modificar = async (val) => {

        showSpinner();
        setEnviado(false);
        let datos = {
            id: objeto.id,
            estado: val.estado,
        };
        //console.log("valor", val)
        await UpdateWithToken(ListaEndpoints.PEDIDO, "PutEstadoPedido", "", datos).then((res) => {

            if (res.ok) {
                reset();
                SwalToast(2, res.msg, () => { }, 1000);
                setMensajeError({ error: false, mensaje: "" });
            } else {
                if (res.Message) {
                    setMensajeError({ error: true, mensaje: res.Message });
                } else {
                    setMensajeError({ error: true, mensaje: res.msg });
                }
                console.error("ocurrió un conflicto", res)
            }
            setModalEditEstado(false);
        }).catch((err) => {
            setMensajeError({ error: true, mensaje: err.message });
            console.error("ocurrió un conflicto", err)
        }).finally(() => {
            hideSpinner();
            recargarLista();
        });

    }
    const showModalEditEstado = (data) => {
        setModalEditEstado(true);
        setEnviado(false);
        let datos = {
            id: data.id,
            estado: data.estado,
        };
        setObjeto(datos);
    };
    const irPedido = (data) => {
        history.push("/pedido/" + data.id)
    }
    const calcularTotal = (data) => {
        let subTotalDescuento = data.precioTotal - (data.precioTotal * data.descuento);
        return subTotalDescuento + (subTotalDescuento * IMPUESTO_VENTA);
    }
    const showModalEstados = (data) => {
        // console.log("testData", data)
        setEnviado(false);
        setModalListEstados(true)
        setObjeto(data);
    };
    const confirmarConcretar = (data) => {

        SweetAlert2(1, "Desea concretar el pedido?",
            "Se realizarán todos los procesos de manera automática: " +
            "envio de correo, facturación, activación, reporte y limpieza del carrito del usuario", "Esto no se puede revertir!", "Cancelar",
            () => { }, "Entendido",
            () => { concretar(data) })
    }
    const concretar = (data) => {
        showSpinner();
        let gmail = getItem("usuario").cta_id;
        let obj = {
            idPedido: data.id,
            correo: gmail
        }

        //   console.log("concretar enviados", obj)
        PostWithToken(ListaEndpoints.ACCION, `ConcretarPedido?idPedido=${obj.idPedido}&correo=${obj.correo}`, "", null).then((res) => {

            //   console.log("concretar res", res)
            if (res.ok && res.data != null) {
                SwalToast(2, res.msg, () => { }, 1000);
                setMensajeError({ error: false, mensaje: "" });
            } else {
                if (res.msg) {
                    let err = place + ": " + res.msg;
                    SweetAlert(3, "Acción Interrumpida", err, "", "Entendido",
                        () => { })
                    console.error(res.error, res.errorDescription)
                } if (res.Message) {
                    let err = place + ": " + res.Message;
                    SweetAlert(3, "Acción Interrumpida", err, "", "Entendido",
                        () => { })
                    console.error(res.error, res.Message)
                }

            }

        }).catch((e) => {
            let err = place + ": " + e.message;
            SweetAlert(3, "Acción Interrumpida", err, "", "Entendido",
                () => { })
            console.error("ocurrió un conflicto", err)
        }).finally(() => {
            hideSpinner();
            recargarLista();
        });


    }
    const eliminar = async (val) => {
        showSpinner();
        setEnviado(false);
        let datos = {
            id: val.id,
        };
        //console.log("valor", val)
        await DeleteWithToken(ListaEndpoints.PEDIDO, "DeletePedidosById", "", datos).then((res) => {

            if (res.ok) {
                reset();
                SwalToast(2, res.msg, () => { }, 2000);
                setMensajeError({ error: false, mensaje: "" });
            } else {
                if (res.msg) {
                    let err = place + ": " + res.msg;
                    SweetAlert(3, "Acción Interrumpida", err, "", "Entendido",
                        () => { })
                    console.error(res.error, res.errorDescription)
                } if (res.Message) {
                    let err = place + ": " + res.Message;
                    SweetAlert(3, "Acción Interrumpida", err, "", "Entendido",
                        () => { })
                    console.error(res.error, res.Message)
                }

            }

        }).catch((e) => {
            let err = place + ": " + e.message;
            SweetAlert(3, "Acción Interrumpida", err, "", "Entendido",
                () => { })
            console.error("ocurrió un conflicto", err)
        }).finally(() => {
            hideSpinner();
            recargarLista();
        });
    }
    let style = MODAL_STYLES;
    style.content.maxWidth = "50%"
    return (
        <>
            {mensajeAlerta.error ? (
                <>
                    <AlertMessage
                        title={""}
                        message={mensajeAlerta.mensaje}
                        tipo={"alert-danger"}
                    />
                </>
            ) : null}
            <EncabezadoDescripcion titulo="Pedidos" id="AdmPedidos" descripcion="Se realizan los mantenimientos de los pedidos" />
            {spinner}

            <section className="cart-area mb-4">
                <div className="container">
                    <div className="row">
                        <BtnBack />
                        <div className="col-lg-12 col-md-12">

                            <div className=" carritoCompras mb-2">
                                <form onSubmit={handleSubmit(buscar)} className="row col-12">
                                    <div className="dataTables_length col-2">

                                        <label>Mostrar
                                            <select
                                                name="cantidad"
                                                className={
                                                    "custom-select custom-select-sm form-control form-control-sm " +
                                                    (errors.estado ? "input-error" : null)
                                                }
                                                defaultValue={10}
                                                ref={register({ required: "Campo requerido." })}
                                            >
                                                <option value="5">5</option>
                                                <option value="10">10</option>
                                                <option value="25">25</option>
                                                <option value="50">50</option>
                                                <option value="100">100</option>
                                            </select>
                                            registros</label>
                                        {errors.cantidad ? (
                                            <ErrorForm mensaje={errors.cantidad.message} />
                                        ) : null}
                                    </div>
                                    <div className="form-group col-1">
                                        <label>Ignorar Fechas:</label>

                                        <input
                                            type="checkbox"
                                            className={
                                                "form-control " +
                                                (errors.estado ? "input-error" : null)
                                            }
                                            name="ignorarF"
                                            checked={ignorarF}
                                            onChange={() => setIgnorarF(!ignorarF)}
                                            ref={register()}
                                            style={{ width: "20px", display: "inline-block", height: "20px", marginLeft: "10px", marginRight: "20px" }}
                                        />
                                    </div>
                                    {/**Fecha inicial */}
                                    <div className="form-group col-3">
                                        <label>Fecha Inicial: </label>
                                        <input
                                            type="date"
                                            className={
                                                "form-control " +
                                                (errors.fechaI ? "input-error" : null)
                                            }
                                            name="fechaI"
                                            placeholder="Fecha inicio"
                                            defaultValue={moment().subtract(1, 'months').format("YYYY-MM-DD")}
                                            onChange={(e) => onChangeFechaI(e.target.value)}
                                            ref={register({
                                                validate: {
                                                    value: (val) => {

                                                        // console.log(moment(val), moment(moment().format("YYYY-MM-DD")))
                                                        //console.log(moment(val) > moment(moment().format("YYYY-MM-DD")))
                                                        if (val) {
                                                            if (moment(val) > moment(moment().format("YYYY-MM-DD"))) {

                                                                return "La fecha inicial no puede ser posterior a la fecha actual"
                                                            } else {
                                                                if (moment(val) > moment(moment(fechaF))) {
                                                                    return "La fecha inicial no puede ser posterior a la fecha final"
                                                                } else {
                                                                    return true;
                                                                }
                                                            }

                                                        } else {
                                                            return "Fecha Inválida"
                                                        }

                                                        // return true // solo para pruebas
                                                    },
                                                },
                                            })}
                                        />
                                        {errors.fechaI ? (

                                            <p style={{ position: "absolute" }} className="text-danger">{errors.fechaI.message}</p>
                                        ) : null}
                                    </div>
                                    {/**Fecha final */}
                                    <div className="form-group col-3">
                                        <label>Fecha Final:</label>
                                        <input
                                            type="date"
                                            className={
                                                "form-control " +
                                                (errors.fechaF ? "input-error" : null)
                                            }
                                            name="fechaF"
                                            placeholder="Fecha Final"
                                            defaultValue={moment().format("YYYY-MM-DD")}
                                            onChange={(e) => onChangeFechaF(e.target.value)}
                                            ref={register({
                                                validate: {
                                                    value: (val) => {
                                                        // console.log(moment(val), moment(moment().format("YYYY-MM-DD")))
                                                        //   console.log(moment(val) < moment(moment().format("YYYY-MM-DD")))
                                                        if (val) {
                                                            if (moment(val) > moment(moment().format("YYYY-MM-DD"))) {

                                                                return "La fecha final no puede ser posterior a la fecha actual"
                                                            } else {
                                                                if (moment(val) < moment(moment(fechaI))) {
                                                                    return "La fecha final no puede ser anterior a la fecha inicial"
                                                                } else {
                                                                    return true;
                                                                }
                                                            }

                                                        } else {
                                                            return "Fecha Inválida"
                                                        }

                                                        // return true // solo para pruebas
                                                    },
                                                },
                                            })}
                                        />
                                        {errors.fechaF ? (
                                            <p style={{ position: "absolute" }} className="text-danger">{errors.fechaF.message}</p>

                                        ) : null}
                                    </div>
                                    {/**estado filtro */}
                                    <div className="form-group col-2">
                                        <label>Estado:</label>
                                        {listEstados.cargando == false ? listEstados.data != null ?

                                            <select name="estadoF"
                                                className={
                                                    "form-control " +
                                                    (errors.estadoF ? "input-error" : null)
                                                }
                                                defaultValue={0}
                                                ref={register({ required: "Campo requerido." })}
                                            >
                                                <option key={0} value={0}>{"Todos"}</option>
                                                {
                                                    listEstados.data.map((item) =>
                                                        <option key={item.id} value={item.id}>{item.nombre}</option>
                                                    )
                                                }
                                            </select>
                                            : "Sin Información" : "Cargando..."
                                        }
                                        {errors.estadoF ? (
                                            <ErrorForm mensaje={errors.estadoF.message} />
                                        ) : null}

                                    </div>

                                    <div className="form-group col-1">
                                        <label>Filtrar:</label>
                                        <button style={{ padding: "5px", margin: "5px" }} type="submit" className="btn btn-primary"
                                            onClick={() => { }}><abbr title="Detalle"><Search /></abbr></button>
                                    </div>
                                </form>
                            </div>
                            <div className="cart-table table-responsive carritoCompras">



                                <table width="100%" id={TblID} className="table table-bordered  ">
                                    <thead>
                                        <tr>
                                            <th scope="col">Id</th>
                                            <th scope="col">Factura</th>
                                            <th scope="col">Cliente</th>
                                            <th scope="col">Fecha</th>
                                            <th scope="col">Precio Total</th>
                                            <th scope="col">Estado</th>
                                            <th scope="col">Opciones</th>

                                        </tr>
                                    </thead>
                                    <tbody>

                                        {list == null ?
                                            (<tr> Cargando...</tr>) : (
                                                list.data.map(pdato =>
                                                    <tr key={pdato.id} >
                                                        <td className="product-thumbnail">
                                                            {pdato.id}
                                                        </td>
                                                        <td className="product-thumbnail">
                                                            {pdato.referenciaFacel}
                                                        </td>
                                                        <td className="product-name">
                                                            {pdato.nombre}
                                                        </td>
                                                        <td className="product-price text-center">
                                                            <span className="unit-amount">
                                                                {pdato.fecha ? moment(pdato.fecha).format('YYYY/MM/DD, hh:mm a') : null}
                                                            </span>
                                                        </td>
                                                        <td className="product-price text-right">
                                                            <span className="unit-amount">
                                                                {FormatMoney(calcularTotal(pdato))}
                                                            </span>
                                                        </td>
                                                        <td className="product-name text-center">
                                                            <abbr title="Cambiar Estado">
                                                                <a style={{ backgroundColor: GetColor(pdato.estado), color: "white", borderRadius: "20px", padding: "5px 20px", cursor: "pointer" }}
                                                                    onClick={() => {
                                                                        if (getItem('usuario').uTipo == "tp") {
                                                                            if (pdato.estado != 2 && pdato.estado != 11 && pdato.estado != 12) {
                                                                                showModalEditEstado(pdato)
                                                                            }
                                                                        }
                                                                    }}
                                                                >
                                                                    {pdato.estadoString}
                                                                </a>
                                                            </abbr>
                                                        </td>
                                                        <td className="product-name text-center">
                                                            <button style={{ padding: "5px", margin: "5px" }} type="button" className="btn btn-primary"
                                                                onClick={() => { irPedido(pdato) }}><abbr title="Detalle"><Eye /></abbr></button>
                                                            <button style={{ padding: "5px", margin: "5px" }} type="button" className="btn btn-primary"
                                                                onClick={() => { showModalEstados(pdato) }}><abbr title="Lista de estados"><List /></abbr></button>

                                                            {getItem('usuario').uTipo == "tp" && pdato.estado != 2 && pdato.estado != 11 && pdato.estado != 12 ?
                                                                <button style={{ padding: "5px", margin: "5px" }} type="button" className="btn btn-primary"
                                                                    onClick={() => { confirmarConcretar(pdato) }}><abbr title="Concretar Pedido"><Check /></abbr></button>
                                                                : null}
                                                            {getItem('usuario').uTipo == "tp" && pdato.estado == 11 || pdato.estado == 12 ?
                                                                <button style={{ padding: "5px", margin: "5px" }} type="button" className="btn btn-primary"
                                                                    onClick={() => { eliminar(pdato) }}><abbr title="Concretar Pedido"><Trash /></abbr></button>
                                                                : null}

                                                        </td>
                                                    </tr>

                                                )
                                            )
                                        }
                                    </tbody>
                                </table>
                                <div className="dataTables_paginate paging_simple_numbers" id="tblPedidosAdm_paginate">
                                    {/**aqui va el use pagination */}
                                    <Pagination />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Modal
                isOpen={modalListEstados}
                shouldCloseOnOverlayClick={true}
                onRequestClose={() => setModalListEstados(false)}
                style={style}
                className="modal-dialog"
            >
                <div className="modal-content">
                    <div className="modal-header">
                        <div className="modal-title">{"Historial de estados"}</div>
                        <button
                            type="button"
                            onClick={() => setModalListEstados(false)}
                            className="close"
                        >
                            &times;
                        </button>
                    </div>
                    <div className="modal-body">

                        <div className="cart-table table-responsive carritoCompras">

                            <table width="100%" className="table table-bordered  ">
                                <thead>
                                    <tr>
                                        <th scope="col">Id</th>
                                        <th scope="col">Usuario</th>
                                        <th scope="col">Fecha</th>
                                        <th scope="col">Método</th>
                                        <th scope="col">Estado</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        objeto.HistorialEstadoPedido == null || objeto.HistorialEstadoPedido.length == 0 ?
                                            (<tr><td className="product-thumbnail text-center">
                                                Sin Información
                                            </td></tr>) : (
                                                objeto.HistorialEstadoPedido.map(pdato =>

                                                    <tr key={pdato.id} >
                                                        <td className="product-thumbnail">
                                                            {pdato.id}
                                                        </td>
                                                        <td className="product-name">
                                                            {pdato.usuario}
                                                        </td>
                                                        <td className="product-price text-center">
                                                            <span className="unit-amount">
                                                                {pdato.fecha ? moment(pdato.fecha).format('YYYY/MM/DD, hh:mm a') : null}
                                                            </span>
                                                        </td>
                                                        <td className="product-name  text-center">
                                                            {pdato.metodo}
                                                        </td>
                                                        <td className="product-thumbnail  text-center">
                                                            <abbr title={pdato.EstadoPedido?.definicion}> {pdato.EstadoPedido?.nombre}</abbr>
                                                        </td>
                                                    </tr>

                                                )
                                            )

                                    }
                                </tbody>
                            </table>
                        </div>

                    </div>

                </div>
            </Modal>
            <Modal
                isOpen={modalEditEstado}
                shouldCloseOnOverlayClick={true}
                onRequestClose={() => setModalEditEstado(false)}
                style={style}
                className="modal-dialog"
            >
                <div className="modal-content">
                    <div className="modal-header">
                        <div className="modal-title">{"Modificar Estado del Pedido"}</div>
                        <button
                            type="button"
                            onClick={() => setModalEditEstado(false)}
                            className="close"
                        >
                            &times;
                        </button>
                    </div>
                    <div className="modal-body">
                        <form onSubmit={handleSubmit(modificar)} className="col-12">
                            <fieldset>
                                <div className="form-row">
                                    <div className="form-group col-12">


                                        {/**estado */}
                                        <div className="form-group col-12">


                                            <label>{`¿Está seguro que desea cambiar el estado del pedido #${objeto.id}?`}</label>
                                            {listEstados.cargando == false ? listEstados.data != null ?
                                                <select
                                                    className={
                                                        "form-control " +
                                                        (errors.cliente ? "input-error" : null)
                                                    }
                                                    defaultValue={objeto.estado}
                                                    ref={register({ required: "Campo requerido." })}
                                                    name="estado"
                                                >
                                                    {
                                                        listEstados.data.map((item) =>
                                                            <option key={item.id} value={item.id}>{item.nombre}</option>
                                                        )
                                                    }
                                                </select>
                                                : "Sin Información" : "Cargando..."
                                            }
                                            {errors.estado ? (
                                                <ErrorForm mensaje={errors.estado.message} />
                                            ) : null}
                                        </div>

                                    </div>
                                    {/**error */}
                                    <div className="col-md-12 text-center">
                                        {enviado ? (
                                            mensajeError.error ? (
                                                <div className="alert alert-danger  p-2 animated fadeInUp faster mb-3">
                                                    {mensajeError.mensaje}
                                                </div>
                                            ) : (
                                                <div className="alert alert-success p-2 animated fadeInUp faster mb-3">
                                                    Registrado con éxito
                                                </div>
                                            )
                                        ) : null}
                                    </div>
                                </div>
                            </fieldset>
                            <div className="modal-footer justify-content-between">
                                <button
                                    type="button"
                                    onClick={() => setModalEditEstado(false)}
                                    className="btn btn-primary"
                                >
                                    Cancelar
                                </button>

                                <>
                                    <button type="submit" className="btn btn-primary">
                                        Modificar
                                    </button>
                                </>

                            </div>
                        </form>

                    </div>

                </div>
            </Modal>



        </>
    )
}
export default withRouter(AdmPedidos);