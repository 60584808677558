import React from "react";
import EncabezadoFiguras from "../components/Visuales/EncabezadoFiguras";
import EncabezadoDescripcion from "../components/Visuales/EncabezadoDescripcion";
import {
  nosotros_logo_sigma,
  nosotros_mision_vision,
} from "../images/imagesURL";

import { ThumbsUp, Smile, Users, Briefcase } from "react-feather";
import { NavLink } from "react-router-dom";

const Nosotros = () => {
  return (
    <>
      <EncabezadoFiguras id="sigma" titulo="Nosotros" />

      <section className="about-area my-5 bloque-espacio-top">
        <div className="container">
          <div className="row bloque-espacio-top bloque-espacio-bottom">
            <div className="col-lg-6 col-md-12">
              <div className="mx-auto p-5">
                <img src={nosotros_logo_sigma} alt="imagen" />
              </div>
            </div>

            <div className="col-lg-6 col-md-12">
              <div className="about-content">
                <div className="section-title">
                  <h2>Sigma</h2>
                  <div className="bar"></div>
                </div>

                <div className="text-justify">
                  <p>
                    Sigma Asesorías Contables S.A, es una empresa de capital
                    100% costarricense, surge a finales del año 2017, con la
                    finalidad de brindar diferentes alternativas en soluciones
                    digitales.
                  </p>

                  <p>
                    Pertenecemos al grupo empresarial Corporación Millenium
                    conformado por Technosoft, Facel, y más recientemente OLM
                    Market.
                  </p>

                  <p>
                    Trabajamos de la mano con la pequeña y mediana empresa
                    brindándoles soluciones de acuerdo a sus necesidades.
                  </p>

                  <p>
                    Estamos en una constante búsqueda de alternativas como una
                    solución real a las necesidades de nuestros clientes y del
                    mercado en general.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row bloque-espacio-top bloque-espacio-bottom">
            <div className="col-lg-6 col-md-12">
              <div className="about-content" id="vision">
                <div className="section-title">
                  <h2>Misión & Visión</h2>
                  <div className="bar"></div>
                </div>
                <div className="text-justify">
                  <h6 className="font-weight-bold">Misión:</h6>
                  <p>
                    Facilitar a nuestros clientes la gestión administrativa, por
                    medio de diferentes herramientas tecnológicas actualizadas.
                  </p>

                  <h6 className="font-weight-bold">Visión:</h6>
                  <p>
                    Convertirnos en un referente como proveedores de soluciones
                    digitales.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-6 col-md-12">
              <div className="mx-auto p-5">
                <img src={nosotros_mision_vision} alt="imagen" />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End About Area --> */}

      {/* <!-- Start Features Area --> */}
      <section className="features-area ptb-80 bg-f9f6f6">
        <div className="container">
          <EncabezadoDescripcion
            id="valores"
            titulo="Nuestros valores"
            descripcion=""
          />

          <div className="row h-100 justify-content-center align-items-center">
            <div className="col-12 services-content">
              <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-6">
                  <div className="box">
                    <ThumbsUp /> Compromiso
                  </div>
                </div>

                <div className="col-lg-6 col-md-6 col-sm-6">
                  <div className="box">
                    <Smile /> Respeto
                  </div>
                </div>

                <div className="col-lg-6 col-md-6 col-sm-6">
                  <div className="box">
                    <Users /> Colaboración
                  </div>
                </div>

                <div className="col-lg-6 col-md-6 col-sm-6">
                  <div className="box">
                    <Briefcase /> Responsabilidad
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End Features Area --> */}

      <section className="funfacts-area ptb-80 mt-1">
        <div className="container">
          <div className="contact-cta-box bg-light">
            <h3>¿Consultas?</h3>
            <p>No dude en contactarnos</p>
            <NavLink to="/contacto" className="btn btn-primary">
              Contáctenos
            </NavLink>
          </div>
        </div>
      </section>
      {/* <!-- End Fun Facts Area --> */}
    </>
  );
};

export default Nosotros;
