import React, { useState } from "react";
import { useForm } from "react-hook-form";
import ErrorForm from "./ErrorForm";
import {
  ListaEndpoints,
  ERROR_REQUERIDO,
  REGEX_VALIDACION_EMAIL,
  ERROR_REGEX_EMAIL,
} from "../../config/Config";
import { Create, SweetAlert } from "../../services/ControlActions";

const FormGenerarContrasenna = ({ idUsuario, token, accion }) => {
  const { register, handleSubmit, errors, reset } = useForm();

  const [cargando, setCargando] = useState(false);
  const [enviado, setEnviado] = useState(false);
  const [mensajeRespuesta, setMensajeRespuesta] = useState({
    error: false,
    mensaje: "",
  });


  const onSubmit = (data) => {
  //  console.log(data)
    let obj = {
      login: data.login
    };

    consultarAPI(obj);
  };

  const consultarAPI = async (data) => {
    setEnviado(false);
    setCargando(true);

    Create(ListaEndpoints.USUARIO, "GenerarContrasenna", "", data).then(
      (res) => {
        setEnviado(true);
        if (res.data) {
          setMensajeRespuesta({ error: false, mensaje: res.msg });
          reset();
        } else {
          setMensajeRespuesta({ error: true, mensaje: res.error });
          console.error(res.error, res.errorDescription)
          setCargando(false);
        }
      }/*,
      (error) => {
        let err = "Usuario: No pudo conectar al servidor, inténtelo de nuevo más tarde. Error: " + error.message
        SweetAlert(3, "Acción Interrumpida", err, "", "Entendido",
          () => { })
      }*/
    ).catch((e) => {

      let err = "Usuario: No pudo conectar al servidor, inténtelo de nuevo más tarde." + e.message
      SweetAlert(3, "Acción Interrumpida", err, "", "Entendido",
        () => { })
    });
  };

  return (
    <>
      <section>
        <div className="container-fluid h-100 justify-content-center align-items-center mt-2 mb-5">
          <div className="row h-100 justify-content-center align-items-center">
            <form onSubmit={handleSubmit(onSubmit)} className="col-md-12">
              <fieldset>
                <div className="form-row">
                  <div className="form-group col-md-12">
                    <label>Correo Electrónico</label>

                    <input
                      type="text"
                      name="login"
                      placeholder="Ingrese el correo electrónico de la cuenta"
                      className={
                        "form-control " + (errors.login ? "input-error" : null)
                      }

                      ref={register({
                        required: ERROR_REQUERIDO,
                        pattern: {
                          value: REGEX_VALIDACION_EMAIL,
                          message: ERROR_REGEX_EMAIL,
                        },
                      })}
                    />

                    {errors.login ? (
                      <ErrorForm mensaje={errors.login.message} />
                    ) : null}
                  </div>
                  <div className="form-group col-md-12">
                    <label className="link-simple" onClick={accion}>Volver al inicio de sesión</label>
                  </div>
                  <div className="col-md-12 text-center">
                    {enviado ? (
                      mensajeRespuesta.error ? (
                        <div className="alert alert-danger text-center p-2 animated fadeInUp faster mb-3">
                          {mensajeRespuesta.mensaje}
                        </div>
                      ) : (
                          <div className="alert alert-success text-center p-2 animated fadeInUp faster mb-3">
                            {mensajeRespuesta.mensaje}
                          </div>
                        )
                    ) : null}
                    <button
                      type="submit"
                      className="d-inline btn btn-primary "
                      disabled={cargando}
                    >
                      Enviar
                    </button>
                  </div>
                </div>
              </fieldset>
            </form>
          </div>
        </div>
      </section>
    </>
  );
};

export default FormGenerarContrasenna;
