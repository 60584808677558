import React from 'react';
import { withRouter } from 'react-router-dom';

const CardInfo = (props) => {
    const { history } = props;

    return (
        <div className="card default">
            <h5>Importante:</h5>

            {/*<ul>*/}
            {/*<li>*/}
            <p>Si desea que su recarga y/o plan se active automáticamente debe asignar la cuenta en cada producto del carrito dandole click en la linea que dice <b>Cuenta: Sin cuenta a aplicar (Agregar)</b> e ingresar la cuenta de correo de la respectiva cuenta, de lo contrario deberá comunicarse con nuestro departamento de servicio al cliente para realizar la activación manual.</p>
{/*                </li>*/}
                {/*<li>*/}
                {/*    En el caso de productos de facel, Facturelo: Sistema activará los planes siempre y cuando la cuenta se encuentre registrada y permita la activación de ese plan.*/}
                {/*</li>*/}
                {/*<li>*/}
                {/*    En el caso de productos de facturelo: Adicionalmente de la activación, la compra de planes adicionales agregará documentos a un plan ya activo.*/}
                {/*</li>*/}
                {/*<li>*/}
                {/*    En el caso de productos de Milleniun: el sistema le enviará un correo del pedido y pronto un compañero de sigma lo contactará para realizar la instalación manual.*/}
                {/*</li>*/}
                {/*Agregar la info de los productos faltantes */}

            {/*             </ul>*/}

        </div>
    );
};
export default withRouter(CardInfo);