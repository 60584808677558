// Configuración de Conexión

export const PRODUCCION = "01";
export const LOCAL = "02";
export const TEST = "03";
export const ESTADOAPP = PRODUCCION;//CAMBIA ESTO y el link en el html :)
/* _▓▓▓___
  _▓▓▓▓▓▓_▓
  ▓▓______▓▓
  _▓_______▓__▓▓▓▓▓
  __________▓_▓▓▓▓▓▓
  _________ ▓▓______▓▓
  _________ ▓________▓
  _________▓         J
  __███████████████
  ____███████████
  _____ ███████
  _______█████        */
export const COD_APLICACION = "018";
export const PUERTO = "63764";

const url1 = "https://api.sigmacr.net/";
const url2 = `http://localhost:${PUERTO}/`;
const url3 = `http://apisigma.technosoft.co.cr/`;//test
const url =   ESTADOAPP==PRODUCCION ? url1 : (ESTADOAPP== LOCAL? url2 : url3); 
export const BASEURL = url+"api/"; 
//signalR
export const URL_SINGALR =  url+"signalr"; 

export class ListaHubsSignalR {
  static get HUB_CONCRETARPEDIDO() {
    return {
      name: "myHub",
      hubConcretarPedido : "hubConcretarPedido"
    };
  }
 
}


// Api keys

export const GOOGLE_MAP_API_KEY = "AIzaSyDTnrAn8KpwZnrc25IFmtp6zsyRh0FCps0";
//export const CLOUDINARY_NAME = "du4k7xyix";
//export const CLOUDINARY_KEY = "983928316169811";
//export const CLOUDINARY_SECRET_KEY = "ugb_wGRg30ZevGYXSr9Kbe_BwHE";
//export const CLOUDINARY_END_POINT = `https://api.cloudinary.com/v1_1/${CLOUDINARY_NAME}/image/upload`;
// Validaciones de formularios
//documentos
export const DOCUMENTO_DISTRIBUIDORES = "https://res.cloudinary.com/du4k7xyix/image/upload/v1648567765/Sigma/documentos/Sigma_preciosv3_crp6b2.pdf";
export const TEMPLATE_IMAGENES_INFORMATICA = "https://res.cloudinary.com/du4k7xyix/image/upload/v1613682678/Sigma/imagenes_informativas/template1800x700_d4krq6.png";
// eslint-disable-next-line no-useless-escape
export const REGEX_VALIDACION_EMAIL = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const REGEX_VALIDACION_TEL = /^([0-9])*$/;

export const MSG_FORM_CARGANDO = "Cargando...";
export const ERROR_REQUERIDO = "Campo requerido.";
export const ERROR_REGEX_EMAIL = "Debe ser un correo electrónico válido.";
export const ERROR_REGEX_TEL = "Debe ser un Teléfono válido.";
export const LONG_CEDULA_FISICA = 9;
export const LONG_CEDULA_JURIDICA = 10;
export const LONG_DIMEX = 12;
export const LONG_MIN_PASSWORD = 6;
export const LONG_MAX_PASSWORD = 20;
//Pago
export const IMPUESTO_VENTA = 0.13;
export const SIGNO_MONEDA = "₡";//₡ - $
export const MODENA = "CRC";//CRC - USD

// Parametros de eventos de CECAPSI

// Sirve como parametro para dictar a cuantas horas antes de que ocurra
// el evento, debe bloquearse la posibilidad de reservar espacios.
// Ej: si se pone 2, el evento se bloqueará a 2 horas antes de que
// suceda.
export const CANT_HORAS_PERMITIDAS_ANTES_DEL_EVENTO = 2;

// Solucion para enum en JavaScript
export class ListaTipoServicios {
  static get CARRITO() {
    return "carrito";
  }
  static get PROXIMAMENTE() {
    return "prox";
  }

  static get CALENDARIO() {
    return "calendario";
  }
}

//bac
export class ListaTipoPago {
  static get SELECCIONAR_TARJETA() {
    return "1";
  }
  static get NUEVO_METODO_PAGO() {
    return "2";
  }
}

export class ListaTipoCedulas {
  static get SELECCIONAR() {
    return 0;
  }

  static get FISICA() {
    return 1;
  }

  static get JURIDICA() {
    return 2;
  }

  static get DIMEX() {
    return 3;
  }
}

export class ListaEndpoints {
  static get SERVICIO() {
    return "Servicio";
  }
  static get LOGIN() {
    return "Login";
  }
  static get REGISTRO() {
    return "Registro";
  }
  static get CARRITO() {
    return "Carrito";
  }
  static get CORREO() {
    return "Correo";
  }
  static get EVENTO() {
    return "Evento";
  }
  static get USUARIO() {
    return "Usuario";
  }
  static get PEDIDO() {
    return "Pedido";
  }
  static get CLIENTE_FAV() {
    return "ClienteFavorito";
  }
  static get PAGO() {
    return "Pago";
  }
  static get IMAGEN() {
    return "Imagen";
  }
  static get PRODUCTO() {
    return "Producto";
  }
  static get ACCION() {
    return "Accion";
  }
  static get CODIGO_APLICABLE() {
    return "CodigoAplicable";
  }
  static get ESTADO_PEDIDO() {
    return "HistorialEstadoPedido";
  }
}

export class ListaMetodos {
  static get GET() {
    return "Get";
  }

  static get POST() {
    return "Post";
  }
  static get PUT() {
    return "Put";
  }

  static get DELETE() {
    return "Delete";
  }
}
/*
export const ListaEstadosPedido = [
  { val : 0, des : "Pendiente" },
  { val : 1, des : "Pagado" },
  { val : 2, des : "Facturado" },
  { val : 3, des : "Completado" },
  { val : 4, des : "Sin Factura" },
  { val : 5, des : "Incompleto" },
  { val : 6, des : "Sin correo" },
  { val : 7, des : "Sin activación" },
  { val : -1, des : "Procesando" },
]*/


export const MODAL_STYLES = {

  overlay: {
    backgroundColor: "#0000008c",
    zIndex: 9999,
    overflowY: 'scroll'
  },
  content: {
    backgroundColor: "#bc2424",
    zIndex: 9999,
    maxWidth: "90%",
    top: '0v',
    marginTop: "1%",
    transform: 'translate (-50%, -50%) ',
    minHeigh: "5vh",
    // maxHeight: '5vh ',  // <- Esto establece la altura 


  },

}
