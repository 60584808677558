import React from 'react';
import { Link } from 'react-router-dom';
import { UseCarritoState } from '../hooks/UseCarritoState';
import CardProductoCarrito from './Visuales/CardProductoCarrito';
import { withRouter } from 'react-router-dom';
import CardTotal from './Visuales/CardTotal';
import CardInfo from './Visuales/CardInfo';
import CardPromocion from './Visuales/CardPromocion';

const Carrito = () => {

    const { spinnerCarrito, recargarCarrito, carritoData, promocion, impuesto, userId, CambiarPromo } = UseCarritoState();
    const BtnSeguirComprando = () => {
        return (
            <div className="cart-buttons mb-3">
                <div className="row align-items-center">
                    <div className="col-lg-7 col-md-7 col-sm-7">
                        <div className="continue-shopping-box">
                            <Link to="/servicios" className="btn btn-primary">
                                Continuar Comprando
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        );
    };
    return (
        <form>
            {spinnerCarrito}
            <section className="shop-details-area">
                <div className="container">
                    <div className="row">
                        <CardInfo />

                    </div>
                    <div className="row">
                        {recargarCarrito === false && carritoData != null ? (
                            carritoData.ProductoCarrito.length === 0 ? (
                                <div>
                                    <h6>Actualmente no cuenta con productos</h6>
                                </div>
                            ) : (
                                <div className="col-md-8 ">

                                    {carritoData.ProductoCarrito?.map((pdato) => (
                                        <CardProductoCarrito key={pdato.id} data={pdato} descuento={promocion ? promocion.descuento : 0} impuesto={impuesto} />
                                    ))}
                                    {BtnSeguirComprando()}
                                </div>
                            )
                        ) : (
                            <div>
                                <h6>
                                    Cargando...
                                </h6>
                            </div>
                        )}
                        {recargarCarrito === false && carritoData != null ?
                            carritoData.ProductoCarrito.length === 0 ?
                                (null) : (<>
                                    <div className="col-md-4 mt-4" >
                                        <CardTotal data={carritoData} />
                                        <br></br>
                                        <CardPromocion userId={userId} idCodigo={carritoData.idCodigoAplicable} setPromo={CambiarPromo} />
                                    </div>
                                </>) : null}

                    </div>
                </div>
            </section>
        </form>
    );
};
export default withRouter(Carrito);
