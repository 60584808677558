import React from 'react';
import "../../css/IconPromo.css";
const IconPromo = ({word,alto,ancho }) => {
    return (
        <>
        <div className="container-promo" > 
        
            <div className="sk-fading-circulo"style={{width: ancho, height: alto}}>
                <div className="sk-circle1 sk-circulo"></div>
                <div className="sk-circle2 sk-circulo"></div>
                <div className="sk-circle3 sk-circulo"></div>
                <div className="sk-circle4 sk-circulo"></div>
                <div className="sk-circle5 sk-circulo"></div>
                <div className="sk-circle6 sk-circulo"></div>
                <div className="mensaje"> {word}</div>
              
            </div>
        </div >
        </>
    )
}

export default IconPromo;