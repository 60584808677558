import React, { useState } from "react";
import { useForm } from "react-hook-form";
import ErrorForm from "./ErrorForm";
import moment from "moment";
import {
    CODAPLICACION,
    ListaEndpoints,
    ERROR_REQUERIDO,
    LONG_MAX_PASSWORD,
    LONG_MIN_PASSWORD,
    REGEX_VALIDACION_EMAIL,
    ERROR_REGEX_EMAIL,
} from "../../config/Config";
import { PostWithToken, SweetAlert } from "../../services/ControlActions";
import { getItem } from "../../services/UseLocalStorage.js";

const FormConfirmacionUsuario = ({usuario,  accion }) => {
    const { register, handleSubmit, errors, reset,watch } = useForm();
    const nombre = getItem("usuario").cta_id;
    const [cargando, setCargando] = useState(false);
    const [enviado, setEnviado] = useState(false);
    const [mensajeRespuesta, setMensajeRespuesta] = useState({
        error: false,
        mensaje: "",
    });

    const onSubmit = (data) => {
        let obj = {
            usu_PasswordOld: data.usu_PasswordOld,
            usu_PasswordNew: data.usu_PasswordNew,
            usu_PasswordNewConfirmation: data.usu_PasswordNewConfirmation,
            nombre: nombre,
        };

        consultarAPI(obj);
    };

    const consultarAPI = async (data) => {
        setEnviado(false);
        setCargando(true);
        PostWithToken(ListaEndpoints.USUARIO, "CambiarContrasenna", "", data).then(
            (res) => {
                setEnviado(true);
                if (res.data) {
                    setMensajeRespuesta({ error: false, mensaje: res.msg });
                    reset();
                    //accion();                  
                } else {
                    setMensajeRespuesta({ error: true, mensaje: res.error });
                    console.error(res.error, res.errorDescription)                   
                }
                setEnviado(true);
            }
        ).catch((e) => {
            let err = "Usuario: No pudo conectar al servidor, inténtelo de nuevo más tarde.  " + e.message
            SweetAlert(3, "Acción Interrumpida", err, "", "Entendido",
                () => { })  
                  
        });
    
        setCargando(true);
    };

    return (
        <>
            <section>
                <div className="container-fluid h-100 justify-content-center align-items-center mt-1 mb-5">
                    <div className="row h-100 justify-content-center align-items-center">
                        <form
                            onSubmit={handleSubmit(onSubmit)}
                            className="col-md-12 formPerfil mb-5"
                        >
                            <fieldset>

                                <div className="form-row">
                                    <div className="form-group col-12 mt-4">
                                        <label>Correo Electrónico</label>
                                        <input
                                            type="text"
                                            className={
                                                "form-control " + (errors.nombre ? "input-error" : null)
                                            }
                                            name="nombre"
                                            placeholder="Correo electrónico"
                                            ref={register({
                                                required: ERROR_REQUERIDO,
                                            })}
                                            defaultValue={nombre}
                                            disabled={true}
                                        />

                                        {errors.nombre ? (
                                            <ErrorForm mensaje={errors.nombre.message} />
                                        ) : null}
                                    </div>
                                    <div className="form-group col-md-12">
                                        <label>Contraseña anterior</label>

                                        <input
                                            type="password"
                                            className={
                                                "form-control " +
                                                (errors.usu_PasswordOld ? "input-error" : null)
                                            }
                                            name="usu_PasswordOld"
                                            placeholder="Ingrese la contraseña anterior"
                                            ref={register({
                                                required: ERROR_REQUERIDO,
                                            })}
                                        />

                                        {errors.usu_PasswordOld ? (
                                            <ErrorForm mensaje={errors.usu_PasswordOld.message} />
                                        ) : null}
                                    </div>
                                    <div className="form-group col-md-12">
                                        <label>Contraseña</label>

                                        <input
                                            type="password"
                                            className={
                                                "form-control " +
                                                (errors.usu_PasswordNew ? "input-error" : null)
                                            }
                                            name="usu_PasswordNew"
                                            placeholder="Ingrese la nueva contraseña"
                                            ref={register({
                                                required: ERROR_REQUERIDO,
                                                maxLength: {
                                                    value: LONG_MAX_PASSWORD,
                                                    message: `Máximo ${LONG_MAX_PASSWORD} caracteres de longitud.`,
                                                },
                                                minLength: {
                                                    value: LONG_MIN_PASSWORD,
                                                    message: `Mínimo ${LONG_MIN_PASSWORD} caracteres de longitud.`,
                                                },
                                            })}
                                        />

                                        {errors.usu_PasswordNew ? (
                                            <ErrorForm mensaje={errors.usu_PasswordNew.message} />
                                        ) : null}
                                    </div>

                                    <div className="form-group col-md-12">
                                        <label>Confirmar Contraseña</label>

                                        <input
                                            type="password"
                                            className={
                                                "form-control " +
                                                (errors.usu_PasswordNewConfirmation ? "input-error" : null)
                                            }
                                            name="usu_PasswordNewConfirmation"
                                            placeholder="Confirmar contraseña"
                                            ref={register({
                                                required: "Campo requerido.",
                                                validate: (value) =>
                                                    value === watch("usu_PasswordNew") ||
                                                    "Contraseñas no coinciden.",
                                            })}
                                        />

                                        {errors.usu_PasswordNewConfirmation ? (
                                            <ErrorForm mensaje={errors.usu_PasswordNewConfirmation.message} />
                                        ) : null}
                                    </div>
                                </div>
                                {enviado ? (
                                    mensajeRespuesta.error ? (
                                        <div className="alert alert-danger text-center p-2 animated fadeInUp faster mb-3">
                                            {mensajeRespuesta.mensaje}
                                        </div>
                                    ) : (
                                            <div className="alert alert-success text-center p-2 animated fadeInUp faster mb-3">
                                                {mensajeRespuesta.mensaje}
                                            </div>
                                        )
                                ) : null}

                                <div className={`d-flex justify-content-between mt-2`}  >

                                    <button
                                        type="button"
                                        className="btn btn-primary "
                                        disabled={cargando}
                                        onClick={accion}
                                    > Volver </button>
                                    <button
                                        type="submit"
                                        className="btn btn-primary ml-5"
                                        disabled={cargando}
                                    >Enviar  </button>
                                </div>
                            </fieldset>
                        </form>
                    </div>
                </div>
            </section>

        </>
    );
};

export default FormConfirmacionUsuario;



