import React, { useState } from "react";
import FormConfirmacionUsuario from "../components/Formularios/FormConfirmacionUsuario";
import { useHistory } from "react-router-dom";
import { existeToken } from "../services/ControlActions";

const ConfirmacionUsuario = ({ match }) => {
  let { idUsuario, token } = match.params;
  let endpointRegistro = "https://pruebasigma.free.beeceptor.com/pruebaform";

  let history = useHistory();

  const [muestraPagina] = useState(existeToken());

  if (muestraPagina) {
    history.push("/");
    alert("Sesión iniciada, debe cerrar sesión para registrar otra cuenta");
  }

  return (
    <>
      {!muestraPagina ? (
        <>
          <section className="contact-area animated fadeIn fast">
            <div className="container">
              <div className="section-title"></div>

              <div className="row h-100 justify-content-center align-items-center">
                <div className="col-lg-7 col-md-12 formContainer mb-5">
                  <div className="container-fluid h-100 justify-content-center align-items-center my-3">
                    <div className="row">
                      <div className="col-md-12">
                        <h2>Activación de cuenta</h2>
                        <div className="bar"></div>
                        <p>
                          Puedes activar tu cuenta llenando el formulario que se
                          muestra a continuación.
                        </p>
                      </div>
                    </div>
                  </div>
                  <FormConfirmacionUsuario
                    endpoint={endpointRegistro}
                    idUsuario={idUsuario}
                    token={token}
                  />
                </div>
              </div>
            </div>
          </section>
        </>
      ) : null}
    </>
  );
};

export default ConfirmacionUsuario;
