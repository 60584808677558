import React from "react";
import EncabezadoDescripcion from "../components/Visuales/EncabezadoDescripcion";
import { Download } from "react-feather";
import { Link } from "react-router-dom"
//import ruta from "../../public/prueba.pdf";
const Distribuidores = () => {

    { {/*<a style={{ fontSize: "12px", padding: "8px"}} className="btn btn-primary" href="prueba.pdf" download="distribuidores.pdf" > a Descargar información<Download height={"15"}/></a>*/ } }
    return (
        <>
            <EncabezadoDescripcion titulo="Distribuidores" id="Distribuidores" descripcion="" />
            <section className="about-area mb-5">
                <div className="container">
                    <div className="row">

                        <div className="col-lg-8 col-md-12 ">
                            <div className="about-content text-justify">
                                <div className="section-title">
                                    <h2>¿Quiere ser distribuidor?</h2>
                                    <div className="bar"></div>
                                </div>
                                {/*
                                <h2 style={{ fontSize: "20px" }}>Requisitos</h2>

                                <ul >
                                    <li><p>Tener cartera de clientes</p></li>
                                    <li><p>Recibir capacitación a cerca de nuestros clientes</p></li>
                                    <li><p>Acuerdo comercial</p></li>

                                </ul>
*/}
                                <h2 style={{ fontSize: "20px" }}>Beneficios y Comisiones</h2>

                                <p>
                                    ¿Le gustaría tener ingresos extra? únase a la familia de Sigma distribuidores y disfrute de nuestros beneficios.
                                </p>
                                <p>Ponemos a su disposición nuestra plataforma de facturación electrónica amigable,
                                    fácil y segura donde agilizará y potencializará su negocio de una forma efectiva,
                                    nuestros reportes le ayudan a llevar un mayor control de sus ventas.
                                </p>
                                <ul style={{ fontSize: "16px" }} >
                                    <li><p>Distribuya nuestros sistemas y gane comisiones con base en el aumento de su cartera de clientes </p></li>
                                    <li><p> Descuentos especiales en nuestros sistemas
                                    </p></li>
                                    <li>  <p> Descuentos en comercios afiliados
                                    </p> </li>
                                    <li> <p > Atractivas comisiones de por vida, si cuenta con una cartera de clientes le brindamos las herramientas necesarias para que tenga éxito en su negocio.
                                    </p></li>
                                </ul>
                                <p>Integrese el club de socios comerciales y reciba una bonificación extra por mantener promedio de ventas, obtenga un rango: PLATA, ORO o DIAMANTE.   </p>

                                <p>Aproveche las herramientas que te ofrecemos y sea parte del exitoso equipo de Sigma distribuidores. </p>


                            </div>
                        </div>
                        <div className="col-lg-4 col-md-12 m-auto mt-2">
                            <div className="about-content ">
                                <div className="section-title">
                                    <h2 style={{ fontSize: "20px" }}>Portafolio de servicios</h2>
                                </div>
                                <ul >
                                    <li><p>Planes de facturación Facel</p></li>
                                    <li><p>Planes de facturación Facturelo</p></li>
                                    <li><p>Contador Millenium.</p></li>
                                    {/* <li><p>Contador Xpress.</p></li>
                                    <li><p>Inventarios Xpress.</p></li>    */}
                                    <li><p>Integraciones.</p></li>
                                </ul>

                                {/*      <div className="section-title mt-5">
                                    <h2 style={{ fontSize: "20px" }}>Beneficios</h2>
                                </div>
                                <ul >

                                    <li><p>Comisiones de por vida. </p></li>
                                    <li><p>Descuentos en el comercio.</p></li>
                                    <li><p>Descuentos en capacitaciones de CECAPSI.</p></li>
                                </ul>
*/}

                            </div>

                            <div className="row about-content m-1">


                                <div className="section-title ">
                                    <h2 style={{ fontSize: "20px" }}>Cuentas bancarias  </h2>
                                </div>
                                <p>

                                    Contamos con cuentas en los tres principales bancos del país.

                                </p>

                                <Link style={{ fontSize: "12px", padding: "8px" }} className="btn btn-primary" to="/pdfDistribuidores" target="_blank" >Información<Download height={"15"} /></Link>


                            </div>

                        </div>
                    </div >

                </div>

            </section >
        </>
    );
};

export default Distribuidores;
