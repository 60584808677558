import React from 'react';

const AlertMessage = ({ title, message, tipo }) => {


    return (
        <div className={`alert ${tipo} alert-dismissible fade show mt-3`} role="alert">
            <h4 className="alert-heading">{title}</h4>
            <p>{message}</p>
            <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
    )
}

export default AlertMessage;