import React, { useState, useEffect } from 'react';
import EncabezadoDescripcion from "../components/Visuales/EncabezadoDescripcion";
import { withRouter } from 'react-router-dom';
import { getItem } from '../services/UseLocalStorage';
import { ListaEndpoints, MODAL_STYLES } from '../config/Config';
import { GetWithToken, SweetAlert, SweetAlert2, PostWithToken, SwalToast, UpdateWithToken, DeleteWithToken, Retrieve } from '../services/ControlActions';
import Spinner from '../components/Visuales/Spinner';
import $ from "jquery";
import 'datatables.net';
import 'datatables.net-bs4';
import AlertMessage from "../components/Errors/AlertMessage";
import Modal from "react-modal";
/*formulario */
import { Trash2, Edit, Cpu, Mail, Eye } from "react-feather";
import { useForm } from "react-hook-form";
import ErrorForm from "../components/Formularios/ErrorForm.js";
import moment from 'moment';
import { img_default_info_evento } from "../images/imagesURL.js";
import BtnBack from '../components/Formularios/BtnBack';

const place = "Promociones";
const AdmPromociones = (props) => {

    const { history } = props;
    const [objeto, setObjeto] = useState({
        id: 0,
        codigo: "",
        fechaInicio: moment().format("YYYY-MM-DD"),
        fechaFin: moment().format("YYYY-MM-DD"),
        descripcion: "",
        descripcionInterna: "",
        fechaRegistro: moment().format("YYYY-MM-DD"),
        descuento: 0.0,
        cantidadTotal: 0,
        cantidadUsadas: 0,
        estado: 0,
    });
    const [modalAddEdit, setModalAddEdit] = useState(false);
    const [edit, setEdit] = useState(false);
    const [titulo, setTitulo] = useState("Acción");
    const [spinner, showSpinner, hideSpinner] = Spinner();
    const [mensajeAlerta, setMensajeAlerta] = useState({ mensaje: "", error: false });

    //formulario
    const { register, handleSubmit, errors, reset } = useForm();
    const [mensajeError, setMensajeError] = useState({
        error: false,
        mensaje: "",
    });
    const [enviado, setEnviado] = useState(false);
    //fue necesaior hacer esto par apoder validar las fechas
    const [fechaI, setFechaI] = useState(moment().format("YYYY-MM-DD"));
    const [fechaF, setFechaF] = useState(moment().add(1, "month").format("YYYY-MM-DD"));
    const [modalPromo, setModalPromo] = useState(false);
    const [imagen, setImagen] = useState({ url: img_default_info_evento, change: false });
    //#region datatables
    const TblID = "tblPromocionesAdm";
    const [list, setList] = useState(null);
    const [datatable, setDatatable] = useState(null)
    useEffect(() => {
        initData();
    }, []);
    const startDatatable = () => {
        //console.log("start")
        setDatatable($("#" + TblID).DataTable({
            processing: true,
            language: {
                url: "//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json"
            },
            scrollX: true,
            order: [[2, "desc"]]
        }));
    }
    const destroyDatatable = () => {

        if (datatable != null) {
            // console.log("destroy");
            datatable.destroy();
            setDatatable(null)
        }
    }
    const recargarLista = async () => {
        destroyDatatable();
        listar().then((res) => {
            //console.log("res", res)
            if (res) {
                startDatatable();
            }
        })
    }
    const initData = () => {//solo se deberia ejecutar una vez
        if (getItem('usuario')) {
            if (getItem('usuario').uTipo != "tp") {
                history.goBack() ? history.goBack() : history.push("/")
            } else {
                try {
                    recargarLista();
                } catch (error) {
                    console.error("Acción Interrumpida:", error.message)

                }
            }
        } else {
            history.goBack() ? history.goBack() : history.push("/")
        }
    }
    //#endregion

    const listar = async () => {
        showSpinner();
        let ok = false;
        await GetWithToken(ListaEndpoints.CODIGO_APLICABLE, "GetCodigoAplicable").then((res) => {
            //console.log("res", res.data)
            if (res.data) {
                setList(res.data);
                ok = true;
            } else {
                if (res.Message) {
                    let err = res.Message;
                    SweetAlert(3, "Acción Interrumpida", err, "", "Entendido",
                        () => { history.goBack() ? history.goBack() : history.push("/") })
                    setList(null);
                } else {
                    let err = place + ":" + res.error;
                    SweetAlert(3, "Acción Interrumpida", err, "", "Entendido",
                        () => { history.goBack() ? history.goBack() : history.push("/") })
                    setList(null);
                }
            }
        }).catch((e) => {
            let err = place + ": No pudo conectar al servidor, inténtelo de nuevo más tarde. " + e.message
            setMensajeAlerta({ mensaje: err, error: true })
            setTimeout(() => {
                localStorage.clear("usuario");
                localStorage.clear("carrito");
                history.push("/login");
            }, 3000);
            setList(null);

        }).finally(() => {
            hideSpinner();
            // console.log("end")
        });
        return ok;

    }
    const GetColor = (i) => {
        switch (i) {
            case 0:
                return "rgb(226, 114, 114)";

            case 1:
                return "rgb(114, 162, 226)";

            case 2:
                return "rgb(118, 226, 114)";

            default:
                return "ffffff";
        }
    }
    const agregar = async (data) => {
        let bool = true;

        var promise = new Promise(getBuffer(data.fileImg[0]));
        await promise.then(function (d) {
            data.typeImg = data.fileImg[0].type;
            data.fileImg = d.toString();

        }).catch(function (err) {
            console.error(err.message, err);
            bool = false;
            let e = place + ": " + err.message;
            SweetAlert(3, "Acción Interrumpida", e, "", "Entendido",
                () => { })
        });

        if (bool) {
            showSpinner();
            setEnviado(false);

            //setObjeto(data);
            data.estado = 0;
            data.descuento = data.descuento / 100;

            await PostWithToken(ListaEndpoints.CODIGO_APLICABLE, "PostCodigoAplicable", "", data).then((res) => {
                if (res.data) {

                    reset();
                    SwalToast(2, res.msg, () => { }, 1000);
                    setMensajeError({ error: false, mensaje: "" });
                    setModalAddEdit(false)
                } else {
                    if (res.error) {
                        setMensajeError({ error: true, mensaje: res.error });
                        console.error(res.error, res.errorDescription)
                    }
                    if (res.Message) {
                        setMensajeError({ error: true, mensaje: res.Message });
                        console.error(res.error, res.Message)
                    }
                }

            }).catch((e) => {
                setMensajeError({ error: true, mensaje: e.message });
                console.error("Acción Interrumpida:", e.message)
            }).finally(() => {
                hideSpinner();
                recargarLista();
                setEnviado(true);
            });;
        }
    }
    const modificar = async (data) => {

        let bool = true;
        if (data.fileImg != null) {
            if (data.fileImg && data.fileImg.length) {
                var promise = new Promise(getBuffer(data.fileImg[0]));
                await promise.then(function (d) {
                    data.typeImg = data.fileImg[0].type;
                    data.fileImg = d.toString();

                }).catch(function (err) {
                    console.error(err.message, err);
                    bool = false;
                    let e = place + ": " + err.message;
                    SweetAlert(3, "Acción Interrumpida", e, "", "Entendido",
                        () => { })
                });
            }
        }

        if (bool) {
            showSpinner();
            setEnviado(false);
            //setObjeto(data);
            let datos = {
                id: edit ? objeto.id : data.id,
                codigo: data.codigo,
                fechaInicio: data.fechaInicio,
                fechaFin: data.fechaFin,
                descripcion: data.descripcion,
                descripcionInterna: data.descripcionInterna,
                fechaRegistro: data.fechaRegistro,
                descuento: data.descuento / 100,
                cantidadTotal: data.cantidadTotal,
                cantidadUsadas: data.cantidadUsadas,
                estado: edit ? objeto.estado : data.estado,
                idImagenCorreo: data.idImagenCorreo,
                urlImagenCorreo: data.urlImagenCorreo,
                fileImg: data.fileImg,
                typeImg: data.typeImg,
            };
            // console.log(datos);
            await UpdateWithToken(ListaEndpoints.CODIGO_APLICABLE, "PutCodigoAplicable", "", datos).then((res) => {
                if (res.data) {

                    reset();
                    SwalToast(2, res.msg, () => { }, 1000);
                    setMensajeError({ error: false, mensaje: "" });
                    setModalAddEdit(false);
                    setEdit(false);
                } else {
                    if (res.error) {
                        setMensajeError({ error: true, mensaje: res.error });
                        console.error(res.error, res.errorDescription)
                    }
                    if (res.Message) {
                        setMensajeError({ error: true, mensaje: res.Message });
                        console.error(res.error, res.Message)
                    }
                }

            }).catch((e) => {
                setMensajeError({ error: true, mensaje: e.message });
                console.error("Acción Interrumpida:", e);

            }).finally(() => {
                hideSpinner();
                recargarLista();
                setEnviado(true);
            });
        }
    }
    const eliminar = async (data) => {
        showSpinner();
        await DeleteWithToken(ListaEndpoints.CODIGO_APLICABLE, "DeleteCodigoAplicable", "", data).then((res) => {
            if (res.data) {
                reset();
                SwalToast(2, res.msg, () => { }, 1000);
                setMensajeError({ error: false, mensaje: "" });
            } else {
                if (res.error) {
                    let err = place + ": " + res.error;
                    SweetAlert(3, "Acción Interrumpida", err, "", "Entendido",
                        () => { })
                    console.error(res.error, res.errorDescription)
                }
                if (res.Message) {
                    let err = place + ": " + res.Message;
                    SweetAlert(3, "Acción Interrumpida", err, "", "Entendido",
                        () => { })
                    console.error(res.Message)
                }
            }
        }).catch((e) => {
            let err = place + ": " + e.message;
            SweetAlert(3, "Acción Interrumpida", err, "", "Entendido",
                () => { })
            console.error("Acción Interrumpida:", e);

        }).finally(() => {
            hideSpinner();
            recargarLista();
            setEnviado(true);
        });
    }
    const showModalAdd = () => {
        setModalAddEdit(true)
        setEdit(false);
        setTitulo("Agregar Promoción");
        setEnviado(false);


        let datos = {
            id: 0,
            codigo: "",
            fechaInicio: moment().format("YYYY-MM-DD"),
            fechaFin: moment().add(1, "month").format("YYYY-MM-DD"),
            descripcion: "",
            descripcionInterna: "",
            fechaRegistro: moment().format("YYYY-MM-DD"),
            descuento: 0.0,
            cantidadTotal: 0,
            cantidadUsadas: 0,
            estado: 0,
            idImagenCorreo: "",
            urlImagenCorreo: "",
            fileImg: null,
            typeImg: ""
        };
        let img = { url: img_default_info_evento, change: false };
        setImagen(img);
        setObjeto(datos);
    };
    const showModalEdit = (data) => {
        setModalAddEdit(true)
        setEdit(true);
        setEnviado(false);
        setTitulo("Editar Promoción");
        let datos = {
            id: data.id,
            codigo: data.codigo,
            fechaInicio: moment(data.fechaInicio).format("YYYY-MM-DD"),
            fechaFin: moment(data.fechaFin).format("YYYY-MM-DD"),
            descripcion: data.descripcion,
            descripcionInterna: data.descripcionInterna,
            fechaRegistro: moment(data.fechaRegistro).format("YYYY-MM-DD"),
            descuento: data.descuento * 100,
            cantidadTotal: data.cantidadTotal,
            cantidadUsadas: data.cantidadUsadas,
            estado: data.estado,
            idImagenCorreo: data.idImagenCorreo,
            urlImagenCorreo: data.urlImagenCorreo,
            fileImg: null,
            typeImg: null
        };
        // console.log("datos",data);
        let img = { url: data.urlImagenCorreo, change: false }
        setImagen(img);
        setObjeto(datos);

    };
    const showModalDelete = (data) => {
        let msg = `¿Está seguro que desea eliminar esta promoción: ${data.codigo}?`;
        SweetAlert2(1, "Eliminar promoción", msg, "Este cambio no se puede revertir.",
            "Cancelar", () => { },
            "Aceptar", () => { eliminar(data) })

    };
    const showModalEditEstado = async (data) => {


        let datos = {
            id: data.id,
            codigo: data.codigo,
            fechaInicio: moment(data.fechaInicio).format("YYYY-MM-DD"),
            fechaFin: moment(data.fechaFin).format("YYYY-MM-DD"),
            descripcion: data.descripcion,
            descripcionInterna: data.descripcionInterna,
            fechaRegistro: moment(data.fechaRegistro).format("YYYY-MM-DD"),
            descuento: data.descuento * 100,// no se le hace conversión porque se supone que e
            cantidadTotal: data.cantidadTotal,
            cantidadUsadas: data.cantidadUsadas,
            estado: data.estado == 1 ? 0 : 1,//cambio de estado, idImagenCorreo: data.idImagenCorreo 
            idImagenCorreo: data.idImagenCorreo,
            urlImagenCorreo: data.urlImagenCorreo,
        };



        let msg = `¿Está seguro que desea ${data.estado == 1 ? "desactivar" : "activar"} la promoción: ${data.codigo}?`;
        SweetAlert2(1, "Cambiar estado", msg, `La promoción ${data.estado == 1 ? "NO" : ""} podrá ser aplicada por los clientes en la página previa al pago.`,
            "Cancelar", () => { },
            "Aceptar", () => { setObjeto(datos); modificar(datos); })
    };

    const onChangeFechaI = (val) => {
        //  console.log("Fecha i", val)
        setFechaI(val);
    }

    const onChangeFechaF = (val) => {
        setFechaF(val);
    }
    const generarCodigo = () => {
        let mes = [
            { val: 1, name: "ENE" }, { val: 2, name: "FEB" }, { val: 3, name: "MAR" }, { val: 4, name: "ABR" },
            { val: 8, name: "AGO" }, { val: 7, name: "JUL" }, { val: 6, name: "JUN" }, { val: 5, name: "MAY" },
            { val: 9, name: "SET" }, { val: 10, name: "OCT" }, { val: 11, name: "NOV" }, { val: 12, name: "DIC" },
            { val: 13, name: "MOM" }, { val: 14, name: "DAD" }, { val: 15, name: "KID" }, { val: 16, name: "LIFE" },
            { val: 17, name: "ECO" }, { val: 18, name: "MEGA" }, { val: 19, name: "GUANA" }, { val: 20, name: "DIC" },
        ]
        let promo = Math.floor(Math.random() * (5 - 41)) + 41;
        let cod = "";
        let numMes = Math.floor(Math.random() * (1 - 21)) + 21;
        let word = mes.find((e) => e.val == numMes).name;


        cod = `${promo}${word}${moment().format("YYYY")}`;
        // console.log("datos", cod)
        let input = document.getElementById("nptCodigoPromo");
        input.value = cod;
        let inputDesc = document.getElementById("nptDescuentoPromo");
        inputDesc.value = promo;
    }
    const showModalCorreoMasivo = (data) => {
        setModalPromo(true);

        setTitulo("Enviar correos masivos");
        let datos = {
            id: data.id,
            codigo: data.codigo,
            fechaInicio: moment(data.fechaInicio).format("YYYY-MM-DD"),
            fechaFin: moment(data.fechaFin).format("YYYY-MM-DD"),
            descripcion: data.descripcion,
            descripcionInterna: data.descripcionInterna,
            fechaRegistro: moment(data.fechaRegistro).format("YYYY-MM-DD"),
            descuento: data.descuento * 100,
            cantidadTotal: data.cantidadTotal,
            cantidadUsadas: data.cantidadUsadas,
            estado: data.estado,
        };
        //console.log(datos);
        setObjeto(datos);
    }
    const enviarCorreoMasivo = (data) => {
        showSpinner();
        // console.log("data promo", data)
        PostWithToken(ListaEndpoints.CODIGO_APLICABLE, `EnviarCorreoMasivo`, data.idPromo, data.correos)
            .then((res) => {
                if (res) {
                    //console.log(res);
                    if (res.ok) {//si está disponible
                        SwalToast(2, "Correos Enviados", () => { }, 3000);
                        setModalPromo(false);

                    } else {

                        let err = "Promociones: " + res.error;
                        SweetAlert(3, "Acción Interrumpida", err, "", "Entendido", () => { });
                    }

                } else {
                    let err = "Promociones: " + res.error;
                    SweetAlert(3, "Acción Interrumpida", err, "", "Entendido", () => { });
                    //     console.log(res);
                }

                hideSpinner();
            })
            .catch((e) => {
                hideSpinner();
                let err =
                    "Promociones: No se pudo conectar al servidor, inténtelo de nuevo más tarde. " +
                    e.message;
                SweetAlert(3, "Acción Interrumpida", err, "", "Entendido", () => { });
            });
    }
    const irPromocion = (data) => {
        history.push("/adm/promociones/" + data.id)
    }
    const getBuffer = (fileData) => {
        return function (resolve) {
            var reader = new FileReader();
            reader.readAsDataURL(fileData);
            reader.onload = function () {
                var arrayBuffer = reader.result
                //   console.log("array", fileData)
                arrayBuffer = arrayBuffer.replace("data:image/jpeg;base64,", "");
                arrayBuffer = arrayBuffer.replace("data:image/png;base64,", "");
                arrayBuffer = arrayBuffer.replace("data:image/jpg;base64,", "");
                //var bytes = new Uint8Array(arrayBuffer);
                resolve(arrayBuffer);
            }
        }
    }
    const onChangeInputImagen = () => {
        const input = document.getElementById("InputImg");
        let files = input.files;
        if (!files || !files.length) {
            let img = { url: edit ? objeto.urlImagen : img_default_info_evento, change: false }
            setImagen(img);
        } else {

            let img = { url: URL.createObjectURL(files[0]), change: false }
            setImagen(img);
        }

    }
    return (
        <>
            {mensajeAlerta.error ? (
                <>
                    <AlertMessage
                        title={""}
                        message={mensajeAlerta.mensaje}
                        tipo={"alert-danger"}
                    />
                </>
            ) : null}
            <EncabezadoDescripcion titulo="Promoción" id="AdmPromociones" descripcion="Se realiza el mantenimiento de las promociones" />
            {spinner}
            <section className="cart-area mb-4">
                <div className="container">
                    <div className="row">
                        <BtnBack />
                        <div className="col-lg-12 col-md-12">
                            <form>
                                <div className="cart-table table-responsive carritoCompras">
                                    <div style={{ display: "flow-root" }}>

                                        <button style={{ color: "#ffffff", float: "right", fontSize: "12px", padding: "10px" }} type="button" className="btn  right" onClick={() => showModalAdd()}>Agregar</button>
                                    </div>
                                    <table width="100%" id={TblID} className="table table-bordered  ">
                                        <thead>
                                            <tr>
                                                <th scope="col">Código</th>
                                                <th scope="col">Descripción</th>
                                                <th scope="col">Fecha Inicio</th>
                                                <th scope="col">Fecha Fin</th>
                                                <th scope="col">Descuento</th>
                                                <th scope="col">Cantidad <p>usadas/total</p></th>
                                                <th scope="col">Estado</th>
                                                <th scope="col">Opciones</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {list == null ?
                                                (<tr> Cargando...</tr>) : (
                                                    list.map(pdato =>

                                                        <tr key={pdato.id} >
                                                            <td className="product-name">
                                                                {pdato.codigo}
                                                            </td>
                                                            <td className="product-name">
                                                                {pdato.descripcionInterna}
                                                            </td>
                                                            <td className="product-name">
                                                                {pdato.fechaInicio ? moment(pdato.fechaInicio).format('YYYY/MM/DD') : null}
                                                            </td>
                                                            <td className={`product-name ` + (moment(pdato.fechaFin) < moment(moment().format("YYYY-MM-DD")) ? "rojoVivo" : "verdeVivo")}>
                                                                {pdato.fechaFin ? moment(pdato.fechaFin).format('YYYY/MM/DD') : null}
                                                            </td>
                                                            <td className="product-name">
                                                                {(pdato.descuento * 100) + "%"}
                                                            </td>
                                                            <td className="product-name">
                                                                {pdato.cantidadUsadas + " / " + pdato.cantidadTotal}
                                                            </td>
                                                            <td className="product-name text-center">
                                                                <abbr title="Cambiar Estado">
                                                                    <a style={{ backgroundColor: GetColor(pdato.estado), color: "white", borderRadius: "20px", padding: "5px 20px", cursor: "pointer" }}
                                                                        onClick={() => {
                                                                            showModalEditEstado(pdato)
                                                                        }}>
                                                                        {pdato.estado == 1 ? "Activo" : "inactivo"}
                                                                    </a>
                                                                </abbr>
                                                            </td>
                                                            <td className="product-name text-center">
                                                                <button style={{ padding: "5px", margin: "5px" }} type="button" className="btn btn-primary" onClick={() => {
                                                                    showModalEdit(pdato)
                                                                }}><abbr title="Editar"><Edit /></abbr></button>
                                                                <button style={{ padding: "5px", margin: "5px" }} type="button" className="btn btn-primary" onClick={() => {
                                                                    showModalDelete(pdato)
                                                                }}><abbr title="Eliminar"><Trash2 /></abbr></button>
                                                                <button style={{ padding: "5px", margin: "5px" }} type="button" className="btn btn-primary" onClick={() => {
                                                                    showModalCorreoMasivo(pdato)
                                                                }}><abbr title="Enviar esta promoción al correo de todos los clientes registrados."><Mail /></abbr></button>
                                                                <button style={{ padding: "5px", margin: "5px" }} type="button" className="btn btn-primary" onClick={() => {
                                                                    irPromocion(pdato)
                                                                }}><abbr title="Ver los registros de quien usó esta promoción"><Eye /></abbr></button>
                                                            </td>
                                                        </tr>

                                                    )
                                                )
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
            {
                <Modal
                    isOpen={modalAddEdit}
                    shouldCloseOnOverlayClick={true}
                    onRequestClose={() => setModalAddEdit(false)}
                    style={MODAL_STYLES}
                    className="modal-dialog"
                >
                    <div className="modal-content">
                        <div className="modal-header">
                            <div className="modal-title">{titulo}</div>
                            <button
                                type="button"
                                onClick={() => setModalAddEdit(false)}
                                className="close"
                            >
                                &times;
                            </button>
                        </div>
                        <div className="modal-body">
                            <form onSubmit={handleSubmit((edit ? modificar : agregar))} className="col-12">
                                <fieldset>
                                    <div className="form-row">
                                        <div className=" col-md-6 row">
                                            {/**Codigo */}
                                            <div className="form-group col-9 col-lg-11 ">
                                                <label>Código:</label>
                                                <input
                                                    id="nptCodigoPromo"
                                                    type="text"
                                                    className={
                                                        "form-control " +
                                                        (errors.codigo ? "input-error" : null)
                                                    }
                                                    name="codigo"
                                                    placeholder="Código"
                                                    defaultValue={objeto.codigo}
                                                    ref={register({ required: "Campo requerido.", pattern: { value: /[A-Z0-9]{4,30}$/, message: "Debe incluir almenos 4 mayúsculas y números" }, maxLength: { message: "El código es muy largo", value: 30 } })}
                                                />


                                                {errors.codigo ? (
                                                    <ErrorForm mensaje={errors.codigo.message} />
                                                ) : null}
                                            </div>
                                            <div className="form-group col-1 ">

                                                <button
                                                    type="button"
                                                    onClick={() => generarCodigo()}
                                                    className="btn btn-primary"
                                                    style={{ padding: "5px", marginLeft: "-25px", marginTop: "35px" }}
                                                >
                                                    <abbr title="Generar código aleatorio"><Cpu /></abbr>
                                                </button>


                                            </div>

                                            {/**Fecha inicial */}
                                            <div className="form-group col-12">
                                                <label>Fecha Inicial: </label>

                                                <input
                                                    type="date"
                                                    className={
                                                        "form-control " +
                                                        (errors.fechaInicio ? "input-error" : null)
                                                    }
                                                    name="fechaInicio"
                                                    placeholder="Fecha inicio"
                                                    defaultValue={objeto.fechaInicio}
                                                    onChange={(e) => onChangeFechaI(e.target.value)}
                                                    ref={register({

                                                        validate: {
                                                            value: (val) => {

                                                                /* console.log(moment(val), moment(moment().format("YYYY-MM-DD")))
                                                                 console.log(moment(val) < moment(moment().format("YYYY-MM-DD")))*/
                                                                if (val) {
                                                                    if (moment(val) < moment(moment().format("YYYY-MM-DD"))) {

                                                                        return "La fecha inicial no puede ser anterior a la fecha actual"
                                                                    } else {
                                                                        if (moment(val) > moment(moment(fechaF))) {
                                                                            return "La fecha inicial no puede ser posterior a la fecha final"
                                                                        } else {
                                                                            return true;
                                                                        }
                                                                    }
                                                                } else {
                                                                    return "Fecha Inválida"
                                                                }
                                                                // return true // solo para pruebas
                                                            },
                                                        },
                                                    })}
                                                />
                                                {errors.fechaInicio ? (
                                                    <ErrorForm mensaje={errors.fechaInicio.message} />
                                                ) : null}
                                            </div>
                                            {/**Fecha final */}
                                            <div className="form-group col-12">
                                                <label>Fecha Final:</label>
                                                <input
                                                    type="date"
                                                    className={
                                                        "form-control " +
                                                        (errors.fechaFin ? "input-error" : null)
                                                    }
                                                    name="fechaFin"
                                                    placeholder="Fecha Final"
                                                    defaultValue={objeto.fechaFin}
                                                    onChange={(e) => onChangeFechaF(e.target.value)}
                                                    ref={register({

                                                        validate: {
                                                            value: (val) => {

                                                                // console.log(moment(val), moment(moment().format("YYYY-MM-DD")))
                                                                //  console.log(moment(val) < moment(moment().format("YYYY-MM-DD")))*/
                                                                //console.log(val);
                                                                if (val) {
                                                                    if (moment(val) < moment(moment().format("YYYY-MM-DD"))) {

                                                                        return "La fecha final no puede ser anterior a la fecha actual"
                                                                    } else {
                                                                        if (moment(val) < moment(moment(fechaI))) {
                                                                            return "La fecha final no puede ser anterior a la fecha inicial"
                                                                        } else {
                                                                            return true;
                                                                        }
                                                                    }
                                                                } else {
                                                                    return "Fecha Inválida"
                                                                }

                                                                //return true // solo para pruebas
                                                            },


                                                        },

                                                    })}
                                                />
                                                {errors.fechaFin ? (
                                                    <ErrorForm mensaje={errors.fechaFin.message} />
                                                ) : null}
                                            </div>

                                            {/**cantidadTotal */}
                                            <div className="form-group col-12">
                                                <label>Cantidad disponible: </label>

                                                <input
                                                    type="number"
                                                    className={
                                                        "form-control " +
                                                        (errors.cantidadTotal ? "input-error" : null)
                                                    }
                                                    name="cantidadTotal"
                                                    placeholder="Cantidad"
                                                    defaultValue={objeto.cantidadTotal}
                                                    ref={register({
                                                        required: "Campo requerido.", min: objeto.cantidadTotal > objeto.cantidadUsadas ? {

                                                            message: "Debe almenos tener " + (objeto.cantidadUsadas + 1) + " a disposición",
                                                            value: (objeto.cantidadUsadas + 1)
                                                        } : {
                                                            message: "Debe almenos tener " + (objeto.cantidadTotal + 1) + "a disposición",
                                                            value: (objeto.cantidadTotal + 1)
                                                        }

                                                    })}
                                                />
                                                {errors.cantidadTotal ? (
                                                    <ErrorForm mensaje={errors.cantidadTotal.message} />
                                                ) : null}
                                            </div>
                                            {/**descuento */}
                                            <div className="form-group col-12">
                                                <label>Descuento (%):</label>
                                                <input
                                                    id="nptDescuentoPromo"
                                                    type="number"
                                                    className={
                                                        "form-control " +
                                                        (errors.descuento ? "input-error" : null)
                                                    }

                                                    name="descuento"

                                                    defaultValue={objeto.descuento}
                                                    ref={register({ required: "Campo requerido.", min: { message: "Monto inválido", value: 1 } })}
                                                />
                                                {errors.descuento ? (
                                                    <ErrorForm mensaje={errors.descuento.message} />
                                                ) : null}
                                            </div>

                                            {/**Decripcion interna*/}
                                            <div className="form-group col-12">
                                                <label>Descripción interna: </label>
                                                <textarea
                                                    className={
                                                        "form-control " +
                                                        (errors.descripcionInterna ? "input-error" : null)
                                                    }
                                                    name="descripcionInterna"
                                                    placeholder="Descripción"
                                                    defaultValue={objeto.descripcionInterna}
                                                    ref={register({ required: "Campo requerido." })}
                                                    rows={3}
                                                    maxLength={500}
                                                />
                                                {errors.descripcionInterna ? (
                                                    <ErrorForm mensaje={errors.descripcionInterna.message} />
                                                ) : null}
                                            </div>

                                        </div>
                                        <div className="form-group col-md-6">
                                            {/**Decripcion interna*/}
                                            <div className="form-group col-12">
                                                <label>Descripción (esto lo verá el cliente): </label>
                                                <textarea
                                                    className={
                                                        "form-control " +
                                                        (errors.descripcion ? "input-error" : null)
                                                    }
                                                    name="descripcion"
                                                    placeholder="Descripción"
                                                    defaultValue={objeto.descripcion}
                                                    ref={register({ required: "Campo requerido." })}
                                                    rows={3}
                                                    maxLength={500}
                                                />
                                                {errors.descripcion ? (
                                                    <ErrorForm mensaje={errors.descripcion.message} />
                                                ) : null}
                                            </div>
                                            {/**imaegen */}
                                            <div className="form-group col-12 text-center">
                                                <img src={imagen.url} style={{ maxHeight: "310px", minHeight: "300px" }} />
                                            </div>
                                            {/**imaegen file*/}
                                            <div className="form-group col-12">
                                                <label>Imagen </label>
                                                <input
                                                    id="InputImg"
                                                    type="file"
                                                    className={
                                                        "form-control " +
                                                        (errors.fileImg ? "input-error" : null)
                                                    }
                                                    name="fileImg"
                                                    accept="image/png, image/jpeg"
                                                    placeholder="fileImg"
                                                    ref={edit == false ? register({ required: "Campo requerido." }) : register()}
                                                    onChange={() => { onChangeInputImagen() }}
                                                />
                                                {errors.fileImg ? (
                                                    <ErrorForm mensaje={errors.fileImg.message} />
                                                ) : null}
                                            </div>
                                            {/**veces usado (solo visual) */}
                                            <div className="form-group col-12">
                                                <label>Veces usado (solo visual):</label>
                                                <input
                                                    id="nptDescuentoPromo"
                                                    type="number"
                                                    className={
                                                        "form-control "
                                                    }

                                                    name="descuento"
                                                    disabled
                                                    defaultValue={objeto.cantidadUsadas}

                                                />

                                            </div>
                                        </div>

                                        {/**error */}
                                        <div className="col-md-12 text-center">
                                            {enviado ? (
                                                mensajeError.error ? (
                                                    <div className="alert alert-danger  p-2 animated fadeInUp faster mb-3">
                                                        {mensajeError.mensaje}
                                                    </div>
                                                ) : (
                                                    <div className="alert alert-success p-2 animated fadeInUp faster mb-3">
                                                        Registrado con éxito
                                                    </div>
                                                )
                                            ) : null}
                                        </div>
                                    </div>
                                </fieldset>
                                <div className="modal-footer justify-content-between">
                                    <button
                                        type="button"
                                        onClick={() => setModalAddEdit(false)}
                                        className="btn btn-primary"
                                    >
                                        Cancelar
                                    </button>
                                    {edit ?
                                        <>
                                            <button type="submit" className="btn btn-primary">
                                                Modificar
                                            </button>
                                        </>
                                        :
                                        <>
                                            <button type="submit" className="btn btn-primary">
                                                Agregar
                                            </button>
                                        </>
                                    }
                                </div>
                            </form>

                        </div>

                    </div>
                </Modal>
            }
            {
                <Modal
                    isOpen={modalPromo}
                    shouldCloseOnOverlayClick={true}
                    onRequestClose={() => setModalPromo(false)}
                    style={MODAL_STYLES}
                    className="modal-dialog"
                >
                    <div className="modal-content">
                        <div className="modal-header">
                            <div className="modal-title">{titulo}</div>
                            <button
                                type="button"
                                onClick={() => setModalPromo(false)}
                                className="close"
                            >
                                &times;
                            </button>
                        </div>
                        <div className="modal-body">
                            <form onSubmit={handleSubmit(enviarCorreoMasivo)} className="col-12">
                                <fieldset>
                                    <div className="form-row">
                                        <div className=" col-md-12 row">
                                            {/**id Promo */}
                                            <div className="form-group col-6 ">
                                                <label>Id de la promoción:</label>
                                                <input
                                                    id="nptCodigoPromo"
                                                    type="text"
                                                    className={
                                                        "form-control " +
                                                        (errors.idPromo ? "input-error" : null)
                                                    }
                                                    name="idPromo"
                                                    placeholder="id"
                                                    defaultValue={objeto.id}
                                                    disabled={true}
                                                    ref={register({ required: "Campo requerido." })}
                                                />


                                                {errors.idPromo ? (
                                                    <ErrorForm mensaje={errors.idPromo.message} />
                                                ) : null}
                                            </div>
                                            {/**codigo*/}
                                            <div className="form-group col-6 ">
                                                <label>Código de la promoción:</label>
                                                <input
                                                    id="nptCodigoPromo"
                                                    type="text"
                                                    className={
                                                        "form-control " +
                                                        (errors.codigo ? "input-error" : null)
                                                    }
                                                    name="codigo"
                                                    placeholder="id"
                                                    defaultValue={objeto.codigo}
                                                    disabled={true}
                                                    ref={register({ required: "Campo requerido." })}
                                                />


                                                {errors.codigo ? (
                                                    <ErrorForm mensaje={errors.codigo.message} />
                                                ) : null}
                                            </div>

                                            {/**Correos*/}
                                            <div className="form-group col-12">
                                                <label>Lista de correos(separados por punto y coma): </label>
                                                <textarea
                                                    className={
                                                        "form-control " +
                                                        (errors.correos ? "input-error" : null)
                                                    }
                                                    name="correos"
                                                    placeholder="ejemplo@gmail.com;ejemplo1@gmail.com;"

                                                    ref={register({ required: "Campo requerido." })}
                                                    rows={3}

                                                />
                                                {errors.correos ? (
                                                    <ErrorForm mensaje={errors.correos.message} />
                                                ) : null}
                                            </div>
                                        </div>


                                    </div>
                                </fieldset>
                                <div className="modal-footer justify-content-between">
                                    <button
                                        type="button"
                                        onClick={() => setModalPromo(false)}
                                        className="btn btn-primary"
                                    >
                                        Cancelar
                                    </button>
                                    <button type="submit" className="btn btn-primary">
                                        Enviar
                                    </button>

                                </div>
                            </form>

                        </div>

                    </div>
                </Modal>
            }
        </>
    )
}
export default withRouter(AdmPromociones);