import React, { useState } from "react";
import { ListaEndpoints, SIGNO_MONEDA } from "../../config/Config";
import { getItem, setItem } from "../../services/UseLocalStorage";
import { withRouter } from "react-router-dom";
import Modal from "react-modal";
import { Create, SweetAlert, SwalToast } from "../../services/ControlActions";
import Spinner from "./Spinner";

Modal.setAppElement("#root");
const CardProducto = (props) => {
  let dato = props.dato;
  const { history } = props;
  const [modalOpen, setModalOpen] = useState(false);
  const [spinner, showSpinner, hideSpinner] = Spinner();

  if (Object.keys(dato).length === 0) return null;

  const irLogin = () => {
    history.push("/login");
  };
  const agregarProductoCarrito = (idProducto) => {
    showSpinner();
    if (getItem("carrito")?.id && getItem("usuario")?.userId) {
      let pCa = {};
      pCa.idCarrito = getItem("carrito").id;
      pCa.idProducto = idProducto;
      pCa.idCuenta =  getItem("usuario").userId;
      pCa.correoCuenta = "";// getItem("usuario").cta_id;
      pCa.cantidad = 1;//siempre será uno y el sistema interpreatá cuaquier numero como uno

      Create(ListaEndpoints.CARRITO, "CreateProductoCarrito", "servicio", pCa)
        .then((res) => {
          if (res.data) {
            //devuelve el carrito
            setModalOpen(false);
            setItem("carrito", res.data);
            hideSpinner();
            SwalToast(
              2,
              "Producto agregado",
              () => {
                history.push("/carritoPage");
              },
              3000
            );
          } else {
            let err = "Agregar producto:" + res.error;
            SweetAlert(3, "Acción Interrumpida", err, "", "Entendido", () => { });
          }
        })
        .catch((e) => {
          hideSpinner();
          let err =
            "-Pagar: No pudo conectar al servidor, inténtelo de nuevo más tarde. " +
            e.message;
          SweetAlert(3, "Acción Interrumpida", err, "", "Entendido", () => { });
        });
    }
  };

  const modalLogin = () => {
    let titulo = <h4>Inicie sesión</h4>;
    let contenido = (
      <p className="font-weight-bold">
        Para poder comprar alguno de nuestros productos, debes iniciar sesión
        con una cuenta
      </p>
    );
    let botones = (
      <>
        <button
          type="button"
          onClick={() => irLogin()}
          className="btn btn-primary "
        >
          Iniciar sesión
        </button>
        <button
          type="button"
          onClick={() => setModalOpen(false)}
          className="btn btn-primary "
        >
          {" "}
          Cancelar{" "}
        </button>
      </>
    );

    return (
      <Modal
        isOpen={modalOpen}
        shouldCloseOnOverlayClick={true}
        onRequestClose={() => setModalOpen(false)}
        style={{
          overlay: {
            backgroundColor: "#0000008c",
            zIndex: 99990,
          },
          content: {
            backgroundColor: "#bc2424",
            zIndex: 99990,
            color: "blue",
          },
        }}
        className="modal-dialog"
      >
        <div className="modal-content">
          <div className="modal-header">
            <div className="modal-title">{titulo}</div>
            <button
              type="button"
              onClick={() => setModalOpen(false)}
              className="close"
            >
              &times;
            </button>
          </div>
          <div className="modal-body">{contenido}</div>
          <div className="modal-footer">{botones}</div>
        </div>
      </Modal>
    );
  }; //fin modalLogin

  return (
    <>
      {spinner}
      <div
        id={"CardProducto" + dato.id + dato.idDuenno}
        className="col-lg-4 col-md-6 col-sm-6 mb-5 "
      >
        <div className="pricing-table">
          <div className="pricing-header">
            <h3>{dato.nombre}</h3>
          </div>

          <div className="price m-0">
            <span>
              {( <span>{SIGNO_MONEDA}</span>)}
              {`${dato.precio}`}
              <span>{`+I.V.A`}</span>
            </span>
          </div>

          <div className="pricing-features p-3">
             <pre style={{overflow: "auto", fontFamily: "Poppins"}}>{dato.descripcionCorta}</pre>
          </div>

          <div className="pricing-footer">
            {getItem("usuario") ? (
              <>
                <button
                  onClick={() => agregarProductoCarrito(dato.id)}
                  className="btn btn-primary"
                >
                  {" "}
                  Comprar{" "}
                </button>
              </>
            ) : (
                <>
                  <button
                    onClick={() => setModalOpen(true)}
                    className="btn btn-primary"
                  >
                    {" "}
                  Comprar{" "}
                  </button>
                  {modalLogin()}
                </>
              )}
          </div>
        </div>
      </div>
    </>
  );
};

export default withRouter(CardProducto);
